/* eslint-disable no-useless-return */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
// import useReactRouter from 'atv-use-react-router';
import { InputItem, Toast, Icon } from 'antd-mobile'
import { smsCode, loginPhonenum, signupPhonenum } from '@/utils/api'
import styles from './index.module.scss'

function Index ({ isShow, isOk, isCancel, handleShow }) {
  const [status, setStatus] = useState(true)
  const [phone, setPhone] = useState('')
  const [code, setCode] = useState('')
  const [inviteCode, setInviteCode] = useState('')
  // const router = useReactRouter();

  const useTimer = () => {
    const [running, setRunning] = useState(false)
    const [time, setTime] = useState(60)

    useEffect(() => {
      let interval
      if (running) {
        interval = setInterval(() => {
          setTime(prev => prev - 1)
        }, 1000)
      }
      return () => {
        clearInterval(interval)
      }
    }, [running])

    return {
      start: () => {
        setRunning(true)
      },
      stop: () => {
        setRunning(false)
      },
      reset: () => {
        setTime(60)
      },
      time,
      running
    }
  }

  const { start, stop, reset, time, running } = useTimer()
  const phoneValid = phone => /^1(3|4|5|6|7|8|9)\d{9}$/.test(phone)
  const isPhoneValid = () => {
    return phoneValid(phone.replace(/\s+/g, ''))
  }
  const getPhoneCode = () => {
    if (running) return
    if (!isPhoneValid()) {
      Toast.fail('请输入正确的手机号码')
      return
    }
    smsCode({
      body: {
        phonenum: phone.replace(/\s+/g, ''),
        kind: 0
      }
    })
      .then(res => {
        // console.log(res);
        start()
      })
      .catch(err => {
        console.log(err)
      })
  }

  const cancel = () => {
    setCode('')
    setPhone('')
    stop()
    reset()
    isCancel && isCancel()
  }

  const login = () => {
    if (!isPhoneValid()) return Toast.fail('请正确填写手机号码')
    if (!code) return Toast.fail('请正确填写验证码')
    const body = {
      phonenum: phone.replace(/\s+/g, ''),
      code
    }
    if (status) {
      loginPhonenum({ body })
        .then(res => {
          handleConfirm(res)
        })
        .catch(err => Toast.fail(err.message))
    } else {
      signupPhonenum({
        body: {
          ...body,
          inviteCode
        }
      })
        .then(res => {
          handleConfirm(res)
        })
        .catch(err => Toast.fail(err.message))
    }
  }

  const handleConfirm = res => {
    // const partnerId = window.location.search.split('=')[1];
    localStorage.setItem('QUBIBI-TOKEN', JSON.stringify(res))
    setCode('')
    setPhone('')
    stop()
    reset()
    // router.history.push(`/OffLine?id=${partnerId}`);
    isOk && isOk()
  }

  useEffect(() => {
    if (time === 0) {
      stop()
      reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time])

  useEffect(() => {
    if (isShow) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [isShow])

  return (
    <div
      className={styles.loginContainer}
      style={
        isShow
          ? {
            bottom: 0,
            paddingTop: '1rem'
          }
          : {
            bottom: '-150%'
          }
      }
    >
      {isShow && <p className={styles.count} onClick={handleShow} />}
      <div className={styles.login}>
        <h1>{status ? '登录' : '注册'}亚洲电视</h1>
        <div className={styles.text}>
          <InputItem
            type="phone"
            value={phone}
            placeholder="请输入手机号码"
            onChange={e => setPhone(e)}
          />
        </div>
        <div className={styles.phone}>
          <InputItem
            type="number"
            value={code}
            placeholder="请输入验证码"
            maxLength={6}
            onChange={e => setCode(e)}
          />
          <span onClick={getPhoneCode}>{!running ? '获取验证码' : time + 's后重发'}</span>
        </div>
        {!status && (
          <div className={styles.phone}>
            <InputItem
              value={inviteCode}
              placeholder="请输入邀请码（选填）"
              onChange={e => setInviteCode(e)}
            />
          </div>
        )}
        <div className={styles.footerBtn}>
          <button className={styles.loginBtn} onClick={login}>
            {status ? '登录' : '注册'}
          </button>
          <p
            className={styles.register}
            onClick={() => {
              stop()
              reset()
              setCode('')
              setPhone('')
              setInviteCode('')
              setStatus(!status)
            }}
          >
            {/* 立即{status ? '注册' : '登录'} */}
            {status ? '还不是' : '已经是'}亚洲电视用户，
            <span className={styles.registerBtn}>立即{status ? '注册' : '登录'}</span>
          </p>
          <Icon type="cross" color="#fff" size="lg" onClick={cancel} className={styles.cancelBtn} />
        </div>
      </div>
    </div>
  )
}

Index.propTypes = {
  isShow: PropTypes.bool,
  isOk: PropTypes.func,
  isCancel: PropTypes.func,
  handleShow: PropTypes.func
}

Index.defaultProps = {
  isShow: false,
  isOk: () => {},
  isCancel: () => {},
  handleShow: () => {}
}

export default Index
