import React from 'react';
import styles from './style.module.scss';

function Index({ label, style, children }) {
  return (
    <div className={styles.wrapper} style={style}>
      <span className={styles.label}>{label}</span>
      {children}
    </div>
  );
}

export default Index;
