import React from 'react';
import styles from './index.module.scss';
import back3 from './assets/back_3.png';
import back4 from './assets/back_4.png';
import head1 from './assets/head_1.png';
import head2 from './assets/head_2.png';
import head3 from './assets/head_3.png';
import head4 from './assets/head_4.png';
import foot1 from './assets/foot_1.png';
import foot2 from './assets/foot_2.png';
import foot3 from './assets/foot_3.png';
import foot4 from './assets/foot_4.png';
import cont1 from './assets/content_1.png';
import cont2 from './assets/content_2.png';

function Contents({ index }) {
  const list = [
    {
      title: '全网实时大数据推荐',
      desc: '比你更懂你自己的购物管家',
      titleBack: '#F83903',
      fontSize: '0.24rem',
      back: '#F66238',
      headUrl: head1,
      footUrl: foot1,
      color: '#F83903',
    },
    {
      title: '全网最热销商品',
      desc: '畅销好货实时推荐',
      titleBack: '#4706DF',
      fontSize: '0.28rem',
      back: '#7943F9',
      headUrl: head2,
      footUrl: foot2,
      color: '#7943F9',
    },
    {
      title: '全网最划算商品',
      desc: '一键对比超低价',
      titleBack: '#FF1E29',
      fontSize: '0.32rem',
      back: '#F66238',
      backUrl: back3,
      headUrl: head3,
      footUrl: foot3,
      color: '#FF313B',
    },
    {
      title: '心仪商品秒杀降价',
      desc: '即时提醒',
      titleBack: '#2C29FF',
      fontSize: '0.32rem',
      back: '#4F4CFF',
      backUrl: back4,
      headUrl: head4,
      footUrl: foot4,
      color: '#2F2CFB',
    },
  ];

  const renderFoot = () => {
    return (
      <div
        style={{ backgroundImage: `url(${list[index].footUrl})` }}
        className={styles.footedBody}
      >
        <p className={styles.title}>如何获得亚洲电视</p>
        <div className={styles.titleBox}>
          <span
            className={styles.left}
            style={{ color: list[index].color }}
          >
            方法一
          </span>
          <span className={styles.right}>
            微信搜索
            <span style={{ color: list[index].color }}>“亚洲电视”</span>
            ，即可找到趣 比比公众号
          </span>
        </div>
        <img className={styles.img} src={cont1} alt="" />
        <div className={styles.titleBox}>
          <span
            className={styles.left}
            style={{ color: list[index].color }}
          >
            方法二
          </span>
          <span className={styles.right}>长按保存图片，用微信扫一扫进 入小程序</span>
        </div>
        <img className={styles.code} src={cont2} alt="" />
      </div>
    );
  };

  return (
    <div
      style={{ background: list[index].backUrl ? `url(${list[index].backUrl})` : list[index].back }}
      className={styles.contents}
    >
      <div className={styles.titles}>
        <div style={{ background: list[index].titleBack }}>
          <p>{list[index].title}</p>
        </div>
        <div
          style={{
            background: list[index].titleBack,
            fontSize: list[index].fontSize,
          }}
        >
          <p>{list[index].desc}</p>
        </div>
      </div>
      <img
        className={styles.headImg}
        src={list[index].headUrl}
        style={{ marginTop: index === 0 && '-0.66rem' }}
        alt=""
      />
      {renderFoot()}
    </div>
  );
}

export default Contents;
