import React from 'react';
import { Modal } from 'antd-mobile';
import styles from './index.module.scss';

function ShareModal({
  visible,
  handleVisible,
}) {
  return (
    <div>
      <Modal
        visible={visible}
        onClose={() => handleVisible(false)}
        transparent
        wrapClassName={styles.modalWrapper}
      >
        <div className={styles.modalContainer}>
          <img src={require('../../assets/shareTip.png')} className={styles.shareTip} alt="" />
        </div>
      </Modal>
    </div>
  );
}

export default ShareModal;
