import React from 'react';
import useTitle from '@/hooks/useTitle';
import Divider from '@/components/Divider';
import styles from './index.module.scss';

function GongLueB() {
  useTitle('如何邀请好友');
  return (
    <div className={styles.container}>
      <div className={styles.title}>方法一：商品分享</div>
      <div className={styles.subTitle}>路径一：</div>
      <div>1、进入亚洲电视小程序</div>
      <div>2、点击商品</div>
      <div>3、进入点击左下角“好物推荐”</div>
      <div>4、选择需要分享的朋友</div>
      <div>5、点“发送”</div>
      <Divider height={'0.16rem'} />
      <div className={styles.imgContainer}>
        <img className={styles.img} src={require('./assets/1.png')} alt="" />
        <div className={styles.imgDivider}></div>
        <img className={styles.img} src={require('./assets/2.png')} alt="" />
      </div>
      <Divider height={'0.21rem'} />
      <div className={styles.subTitle}>路径二：</div>
      <div>1、进入小程序首页</div>
      <div>2、进入商品页面</div>
      <div>3、直接点“好物推荐”</div>
      <div>4、选择需要分享的好友或群</div>
      <div>5、点“发送”</div>
      <Divider height={'0.16rem'} />
      <div className={styles.imgContainer}>
        <img className={styles.img} src={require('./assets/3.png')} alt="" />
        <div className={styles.imgDivider}></div>
        <img className={styles.img} src={require('./assets/4.png')} alt="" />
        <div className={styles.imgDivider}></div>
      </div>
      <Divider height={'0.4rem'} />
      <div className={styles.subTitle}>方法二：小程序分享</div>
      <div>1、进入小程序</div>
      <div>2、点击首页右上角</div>
      <div>3、点击“发送给朋友”</div>
      <div>4、选择需要分享的群或好友</div>
      <div>5、点“发送”</div>
      <Divider height={'0.23rem'} />
      <div className={styles.imgContainer}>
        <img className={styles.img} src={require('./assets/5.png')} alt="" />
        <div className={styles.imgDivider}></div>
        <img className={styles.img} src={require('./assets/6.png')} alt="" />
        <div className={styles.imgDivider}></div>
        <img className={styles.img} src={require('./assets/7.png')} alt="" />
      </div>
      <Divider height={'0.4rem'} />
      <div className={styles.subTitle}>方法三：海报分享</div>
      <div>1、进入小程序右下角“我的”</div>
      <div>2、点击上面“邀请有礼”</div>
      <div>3、生成海报</div>
      <div>4、“分享好友”可分享给微信好友；</div>
      <div>5、“保存图片”可保存在手机相册发朋友圈。</div>
      <Divider height={'0.19rem'} />
      <div className={styles.imgContainer}>
        <img className={styles.img} src={require('./assets/8.png')} alt="" />
        <div className={styles.imgDivider}></div>
        <img className={styles.img} src={require('./assets/9.png')} alt="" />
      </div>
      <Divider height={'0.28rem'} />
    </div>
  );
}

export default GongLueB;
