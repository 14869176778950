import React from 'react';
import styles from './style.module.scss';
import useReactRouter from 'atv-use-react-router';
import { getRegionNameById } from '@/utils/helper';
import { FiEdit } from 'react-icons/fi';

function Index({ item, getAddressListCallback }) {
  const router = useReactRouter();

  const { name, phonenum, regionId, address, isDefault } = item;
  const { shouldSelect, selectCallback } = router.match.params;

  const defaultAddressMark = () => {
    return !!isDefault && <div className={styles.defaultAddress}>默认</div>;
  };

  const fullAddress = () => {
    const region = getRegionNameById(regionId, '');
    return `${region}${address}`;
  };

  // 点击卡片
  const handleClickCard = () => {
    return shouldSelect ? handleSelect() : handleEdit();
  };

  // 编辑卡片
  const handleEdit = () => {
    router.history.push('selfProductAddressEdit', {
      ...item,
      isEdit: true,
      getAddressListCallback,
    });
  };

  // 返回上一页信息
  const handleSelect = () => {
    selectCallback(item);
    router.history.goBack();
  };

  return (
    <div className={styles.cardWrapper} onClick={handleClickCard}>
      <div className={styles.infoWrapper}>
        <div className={styles.baseInfo}>
          <span className={styles.name}>{name}</span>
          {defaultAddressMark()}
          <span className={styles.phonenum}>{phonenum}</span>
        </div>
        <div className={styles.address}>{fullAddress()}</div>
      </div>
      <FiEdit className={styles.editIcon} />
    </div>
  );
}

export default Index;
