import React, { memo } from 'react';
import Swiper from 'react-id-swiper';
import styles from './index.module.scss';

function BannerSwiper({ productDetail }) {
  const params = {
    pagination: {
      el: '.swiper-pagination',
    },
    loop: true,
  };
  return (
    <div className={styles.swiperWrapper}>
      {productDetail.productImages && (
        <Swiper {...params}>
          {productDetail.productImages.map((img, i) => {
            return (
              <img
                src={img}
                key={i}
                alt={i}
                style={{ height: '100vw', backgroundSize: 'cover' }}
              />
            );
          })}
        </Swiper>
      )}
    </div>
  );
}

export default memo(BannerSwiper);
