import React, { useEffect } from 'react';
import loadjs from 'loadjs';
import classNames from 'classnames';
import { getUrlParams } from '@/utils/helper';

import styles from './style.module.scss';
import LogoImg from '@/assets/logo.png';

function Index() {
  const { clientId, currency, paypalId } = getUrlParams();

  useEffect(() => {
    loadjs(`https://www.paypal.com/sdk/js?client-id=${clientId}&currency=${currency}`, () => {
      paypal
        .Buttons({
          createOrder: async function(data, actions) {
            return paypalId;
          },
        })
        .render('.paypal');
    });
  }, []);

  return (
    <div className={styles.wrapper}>
      <img src={LogoImg} className={styles.logo} />
      <div className={classNames('paypal', styles.buttons)}></div>
    </div>
  );
}

export default Index;
