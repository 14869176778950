/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import styles from './index.module.scss';
import download from './assets/download.png';

function Index() {
  return (
    <div className={styles.container}>
      <img className={styles.logo} src={download} />
    </div>
  );
}

export default Index;
