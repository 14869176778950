import React, { useState } from 'react';
import Modal from '@/components/Modal';
import { getSkuResult } from '@/utils/skuHelper';
import { clsFlatten } from '@/utils/helper';
import styles from './index.module.scss';
import { Toast } from 'antd-mobile';

function ModalSku({
  isDefaultSku = false,
  attrs,
  attrPair,
  skuMap,
  productDetail,
  currentSku,
  SKUResult,
  attrText,
  visible,
  handleVisible,
  handleTagChange,
}) {
  const [buyNum, setBuyNum] = useState(1);
  const handleTagSelect = (index, cindex, clickable) => {
    const params = {
      attrs,
      SKUResult,
    };
    if (clickable) {
      const { attrText, itemInfo, attrPair } = getSkuResult(
        index,
        cindex,
        params
      );
      const newParams = {
        attrText,
        itemInfo,
        attrPair,
      };
      handleTagChange(newParams);
    }
  };
  const image = currentSku.image || currentSku.thumbnail || '';

  const handleMinus = () => {
    if (buyNum > 1) {
      setBuyNum(n => n - 1);
    }
  };

  const handlePlus = () => {
    setBuyNum(n => n + 1);
  };

  const handleOrder = () => {
    const pairLength = attrPair ? attrPair.split(',').length : 0;
    const canOrder = (attrs.length === pairLength) || isDefaultSku;
    if (canOrder) {
      handleVisible(false);
      // TODO: 等待路由完善
    } else {
      Toast.info(attrText);
    }
  };

  const renderSku = () => {
    return attrs.map((attr, index) => {
      return (
        <div className={styles.skuItemContainer} key={attr.id}>
          <div className={styles.skuTitle}>{attr.name}</div>
          <div className={styles.skuContainer}>
            {Boolean(attr.value) &&
              attr.value.map((item, cindex) => {
                return (
                  <div
                    className={clsFlatten([
                      styles.skuDefault,
                      item.isActive && styles.skuActive,
                      item.notClick && styles.skuDisable,
                    ])}
                    onClick={() =>
                      handleTagSelect(index, cindex, !item.notClick)
                    }
                    key={item.id}
                  >
                    <div>{item.value}</div>
                  </div>
                );
              })}
          </div>
        </div>
      );
    });
  };

  return (
    <Modal
      wrapClassName={styles.modalWrapper}
      className={styles.modalWrapper}
      visible={visible}
      maskClosable
      animationType="slide-up"
      onClose={() => handleVisible(false)}
      popup
    >
      <div className={styles.modalContainer}>
        <img
          onClick={() => handleVisible(false)}
          src={require('./assets/close.png')}
          className={styles.closeIcon}
          alt=""
        />
        {/* 顶部信息 */}
        <div className={styles.modalTopContainer}>
          <div className={styles.skuImgContainer}>
            <img src={image} className={styles.skuImg} alt="" />
          </div>
          <div className={styles.skuPollenInfoContainer}>
            <div className={styles.skuPriceContainer}>
              <div className={styles.originPrice}>
                ¥{currentSku.price && currentSku.price / 100}
              </div>
              <div className={styles.vipPrice}>
                ¥{currentSku.vipPrice && currentSku.vipPrice / 100}
              </div>
              <img
                className={styles.beeKingIcon}
                src={require('@/assets/beeKingPrice.png')}
                alt=""
              />
            </div>
            <div className={styles.pollenWrapper}>
              <div className={styles.pollenContainer}>
                <img
                  className={styles.pollenImg}
                  src={require('@/assets/pollen.png')}
                  alt=""
                />
                <span>花粉2000</span>
              </div>
            </div>
            <div className={styles.attrText}>{attrText}</div>
          </div>
        </div>
        {/* sku内容部分 */}
        <div className={styles.skuContentContainer}>{renderSku()}</div>
        {/* 底部 */}
        <div className={styles.footerContainer}>
          <div className={styles.skuBuyContainer}>
            <div className={styles.buyNumText}>购买数量</div>
            <div className={styles.opContainer}>
              <div className={clsFlatten([styles.opBtn, buyNum === 1 && styles.numDisable])} onClick={() => handleMinus()}>
                -
              </div>
              <div className={styles.numBtn}>{buyNum}</div>
              <div className={styles.opBtn} onClick={() => handlePlus()}>
                +
              </div>
            </div>
          </div>
          <div onClick={() => handleOrder()} className={styles.buyBtnContainer}>
            <div className={styles.buyBtn}>确定</div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ModalSku;
