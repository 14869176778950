import React from 'react';
import useTitle from '@/hooks/useTitle';

function TaskB() {
  useTitle('成长任务2：学习分享赚钱技能');
  // 生成1-3的数组
  const imgList = Array.from({ length: 3 }).map((_, index) => index + 1);
  return (
    <div>
      {imgList.map(v => (
        <img style={{ width: '100vw', display: 'block' }} key={v} src={require(`./assets/${v}.png`)} alt={v} />
      ))}
    </div>
  );
}

export default TaskB;
