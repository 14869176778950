import React, { useEffect, useState, useRef } from 'react'
import classNames from 'classnames'
import Sticky from 'react-sticky-el'
import useReactRouter from 'atv-use-react-router'
import ListView from '@/components/ListView'
import Login from '@/pages/Login'
import useRequest from '@/hooks/useRequest'
import useTitle from '@/hooks/useTitle'
import useInfinityList from '@/hooks/useInfinityList'
import { getActivityGather, getActivitySitems, getMyInfo } from '@/utils/api'
import { jumpTo, isRN, isMP, shareRN } from '@/utils/helper'
import { getAuth } from '@/utils/app'
import { H5_PREFIX, WXShareObj, RNShareObj, shareDefaultImg } from '@/utils/constants'
import useReducerContext from '@/reducer/userReducer'

import styles from './style.module.scss'
import Sitem from './components/Sitem'
import NewUserModal from './components/NewUserModal'

import locationIcon from './assets/location.png'
import latticeImg from './assets/lattice.png'

const INIT_PAGE = 0
const MODAL_NEW_USER_ATTR_INIT = {
  visible: false,
  content: { activityName: '', promotionObject: '', notPromotionObject: '' }
}

function Activity () {
  const router = useReactRouter()
  const { id } = router.match.params
  const auth = getAuth()
  const innerRN = isRN()
  const innerMP = isMP()
  const { updateState, userData } = useReducerContext()
  const { inviteCode } = userData || {}

  const [activeTabInfo, setActiveTabInfo] = useState({})
  const [isActivityBegin, setIsActivityBegin] = useState(true)
  const [modalNewUserAttr, setModalNewUserAttr] = useState(MODAL_NEW_USER_ATTR_INIT)
  const [isLoginVisible, setIsLoginVisible] = useState(false)
  const [isError, setIsError] = useState(false)

  const tabBarRef = useRef()

  const [fetchActivityInfo, activityInfo] = useRequest(getActivityGather)

  const { name, SitemGathers, extra, promotion } = activityInfo || {}
  const { backColor, insideImage } = (extra && JSON.parse(extra)) || {}
  const { id: gatherId, name: gatherName } = activeTabInfo

  useTitle(name || '')

  const fetchSitems = data => {
    if (!gatherId) {
      return []
    }
    return getActivitySitems({
      query: Object.assign({}, data.query, { activityId: id, gatherId })
    })
  }

  const [onRefresh, onEndReached, array, isEnd, isRefresh, isLoadMore] = useInfinityList(
    fetchSitems
  )

  useEffect(() => {
    fetchActivityInfo({ query: { activityId: id } })
      .then(res => {
        const { SitemGathers } = res || {}
        if (Array.isArray(SitemGathers) && SitemGathers.length) {
          setActiveTabInfo(SitemGathers[INIT_PAGE])
        }
        isError && setIsError(false)
      })
      .catch(_ => {
        setIsError(true)
        setIsActivityBegin(false)
      })
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    onRefresh()
    // eslint-disable-next-line
  }, [activeTabInfo]);

  useEffect(() => {
    if (auth) {
      getMyInfo().then(userData => {
        updateState('userData', userData)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const title = name
    const sharePath = `/pages/launch/index?pg=webview&invite=${inviteCode}&src=${H5_PREFIX}/activity/${id}`
    const shareImgUrl = insideImage || shareDefaultImg
    const webpageUrl = `${H5_PREFIX}/download?code=${inviteCode}`
    const WXParams = isError
      ? WXShareObj
      : Object.assign({}, WXShareObj, {
        title,
        path: sharePath,
        imageUrl: shareImgUrl
      })
    window.wx.miniProgram.postMessage({
      data: WXParams
    })
    if (isRN()) {
      const params = isError
        ? RNShareObj
        : Object.assign({}, RNShareObj, {
          title,
          path: sharePath,
          thumbImageUrl: shareImgUrl,
          webpageUrl
        })
      shareRN(params)
    }
    // eslint-disable-next-line
  }, [inviteCode, activityInfo]);

  const onTabChange = tab => {
    setActiveTabInfo(tab)
    fixedCenter(tab.id)
  }

  // 选中的 tab 居中显示
  const fixedCenter = id => {
    const deviceWidth = window.innerWidth
    const el = document.querySelector(`#tab_${id}`)
    const { clientWidth: elWidth, offsetLeft } = el
    tabBarRef.current.scrollLeft = offsetLeft + (elWidth - deviceWidth) / 2
  }

  const handleItemDetail = (itemId, gatherId) => {
    const { isOldUser } = userData || {}
    if (!auth) {
      if (innerRN || innerMP) {
        jumpTo('login')
      } else {
        setIsLoginVisible(true)
      }
      return
    }
    if (promotion === 2 && isOldUser === 1) {
      // 活动对象为新用户且登录用户为老用户
      const _attr = {
        visible: true,
        content: {
          activityName: name,
          promotionObject: '新用户',
          notPromotionObject: '老用户'
        }
      }
      handleModalNewUser(_attr)
      return
    }
    if (promotion === 3 && isOldUser === 0) {
      // 活动对象为老用户切登录用户为新用户
      const _attr = {
        visible: true,
        content: {
          activityName: name,
          promotionObject: '老用户',
          notPromotionObject: '新用户'
        }
      }
      handleModalNewUser(_attr)
      return
    }
    jumpTo('productDetailSelf', { itemId, gatherId })
  }

  const handleLoginShow = bool => {
    setIsLoginVisible(bool)
  }

  const isLoginOk = () => {
    handleLoginShow(false)
  }

  const isLoginCancel = () => {
    handleLoginShow(false)
  }

  const handleModalNewUser = attr => {
    setModalNewUserAttr(o => Object.assign({}, o, attr))
  }

  const renderHeader = () => {
    return <img src={insideImage || ''} className={styles.header} alt="" />
  }

  const renderTab = tab => {
    const { id, name } = tab
    const isActive = activeTabInfo.id === id
    return (
      <div id={`tab_${id}`} key={id} className={styles.tab} onClick={() => onTabChange(tab)}>
        {!!isActive && <img src={locationIcon} className={styles.tabActiveIcon} alt="" />}
        <span className={classNames(styles.tabText, isActive && styles.activeTabText)}>{name}</span>
      </div>
    )
  }

  const renderTabs = () => {
    if (!Array.isArray(SitemGathers) || SitemGathers.length <= 1) {
      return null
    }
    return (
      <Sticky>
        <div ref={tabBarRef} className={styles.tabBar}>
          {SitemGathers.map(item => renderTab(item))}
        </div>
      </Sticky>
    )
  }

  const renderSitemsHeader = () => {
    return (
      <div className={styles.sitemsHeader} style={{ backgroundColor: backColor }}>
        <img src={latticeImg} className={styles.lattice} />
        <span className={styles.title}>{gatherName}</span>
        <img src={latticeImg} className={styles.lattice} style={{ transform: 'rotate(180deg)' }} />
      </div>
    )
  }

  const renderItem = (rowData = {}) => {
    return (
      <Sitem
        key={rowData.id}
        data={rowData}
        gatherId={gatherId}
        handleItemDetail={handleItemDetail}
      />
    )
  }

  const renderItems = () => {
    return (
      <ListView
        data={array}
        isEnd={isEnd}
        onEndReached={onEndReached}
        renderRow={renderItem}
        renderSeparator={() => null}
      />
    )
  }

  if (!isActivityBegin) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100vw',
          height: '100vh',
          fontSize: '0.32rem'
        }}
      >
        活动暂未开始
      </div>
    )
  }

  return (
    <div className={styles.activityWrapper} style={{ backgroundColor: backColor }}>
      {renderHeader()}
      {renderTabs()}
      {renderSitemsHeader()}
      {renderItems()}

      <NewUserModal
        handleVisible={() => handleModalNewUser({ visible: false })}
        {...modalNewUserAttr}
      />
      <Login
        isShow={isLoginVisible}
        handleShow={() => handleLoginShow(false)}
        isOk={isLoginOk}
        isCancel={isLoginCancel}
      />
    </div>
  )
}

function Index () {
  return (
    <useReducerContext.Provider>
      <Activity />
    </useReducerContext.Provider>
  )
}

export default Index
