import React, { Fragment } from 'react'
import { Icon } from 'antd-mobile'
import styles from './index.module.scss'

function Empty({ type = 'EMPTY', describe }) {
	const status = {
		'NETWORK': '网络开小差咯',
		'EMPTY': '没有任何数据哟',
		'COLLECT': '您的收藏还是空的噢',
		'INFO': '暂无消息',
		'ORDER': '订单为空',
		'SEARCH': '没有搜索到任何内容',
		'LOGIN': '您暂未登录'
	}
	return (
		<div className={styles.emptyBox}>
			{type ?
				<img src={require(`./assets/${type}.png`)} /> :
				<Icon size='lg' type='loading' style={{ width: '44px', height: '44px' }} />
			}
			<div>
				<span>{describe || status[type] || '正在查询中···'}</span>
			</div>
		</div>
	)
}

export default Empty
