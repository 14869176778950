import React, { useEffect, useState } from 'react';
import { Toast } from 'antd-mobile';
import { getPriceTrend } from '@/utils/api';
import { jumpTo } from '@/utils/helper';
import useReactRouter from 'atv-use-react-router';
import Goods from './components/Goods';
import TrendMap from './components/TrendMap';
import styles from './index.module.scss';

function Parity ({ match }) {
  const router = useReactRouter().history
  const { params = {} } = match
  const [data, setData] = useState(null)
  useEffect(() => {
    Toast.loading('查询中···', 20)
    getPriceTrend({
      body: {
        goodsUrl: decodeURIComponent(params.url)
      }
    }).then(res => {
      if (!res.goodsName) {
        Toast.info('没有此商品数据')
        router.goBack(-1)
        return
      }
      Toast.hide()
      setData(res)
    }).catch(() => {
      Toast.info('数据查询失败')
      router.goBack(-1)
    })
  }, [])
  return data && (
    <div className={styles.parity}>
      <Goods datas={data} />
      <TrendMap datas={data} />
      <div className={styles.introduce}>
        <div>
          <p className={styles.title}>哪里买优惠？全网比一比价</p>
          <p className={styles.desc}>去看看商品在其他商场售价</p>
        </div>
        <div
          className={styles.btns}
          onClick={() => jumpTo('bijia')}
        >
          搜全网同款
        </div>
      </div>
    </div>
  )
}

export default Parity;
