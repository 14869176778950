/* eslint-disable jsx-a11y/accessible-emoji */
import React from 'react';
import useTitle from '@/hooks/useTitle';
import Divider from '@/components/Divider';
import styles from './index.module.scss';

function GongLueA() {
  useTitle('建群技巧');

  return (
    <div className={styles.container}>
      <Divider height={'0.13rem'} />
      <div className={styles.title}>
        第一步：群发信息打招呼
      </div>
      <Divider height={'0.2rem'} />
      <div>
        我们先群发信息给微信好友，邀请他们进群~
      </div>
      <Divider height={'0.27rem'} />
      <div>
        <span className={styles.subTitle}>参考话术：</span>
      </div>
      <Divider height={'0.19rem'} />
      <div>
        在干嘛，我平时很喜欢网上购物，所以今天我建了群，给你们分享很多商城好货，一年可以节省上万块！洗衣液，纸巾，零食，比超市划算很多，一会我拉你进群哈！
      </div>
      <Divider height={'0.19rem'} />
      <div>
        我们这个群是日常交流和购物分享群，合作了上万家商家，有几万款产品，基本涵盖了：服装、母婴、化妆品、家居、鞋包服饰、美食、文体车品、数码小家电等！都是商家冲销量促销，物美价廉，一年帮亲节省5000块的开支哦！~
      </div>
      <Divider height={'0.19rem'} />
      <div className={styles.subTitle}>
        以上两条务必收藏，可以群发哦！
      </div>
      <Divider height={'0.41rem'} />
      <div className={styles.title}>
        第二步：拉人建群
      </div>
      <Divider height={'0.19rem'} />
      <div className={styles.subTitle}>
        操作路径：微信右上角“+”，点发起群聊，选择联系人
      </div>
      <Divider height={'0.19rem'} />
      <div>
        1、微信每次最多拉40人，第一次除了自己以外的39人，不用同意就可以直接拉进群，尽量先拉一些宝妈，不用太熟就可以。
      </div>
      <Divider height={'0.16rem'} />
      <div>
        2、适当的发个红包，说明群的作用；大家在留下来后，再继续邀请自己的家人好友。
      </div>
      <Divider height={'0.16rem'} />
      <div>
        3、红包要比群人数少，这样抢起来才有氛围（例如群里40个人，红包发1元或2元=15个）
      </div>
      <Divider height={'0.16rem'} />
      <div>
        4、40人后再拉的，需要通过验证才能进来，就要找一些购买意向比较高的朋友进来！
      </div>
      <Divider height={'0.16rem'} />
      <div>
        当然了，贴心的亚洲电视还为各位准备了建群的介绍语以及群规话术，你们可以运用到建群中👇
      </div>
      <Divider height={'0.25rem'} />
      <div className={styles.subTitle}>
        （注意不要一次性发太多，隔小段时间发一段，配合红包使用效果更好哦！）
      </div>
      <Divider height={'0.25rem'} />
      <div>
        🎉欢迎新进的朋友！我是群主😊，本群所推荐全部为优质划算的商⚡品
      </div>
      <Divider height={'0.25rem'} />
      <div>
        ⚡价格同比其他平台都要优惠，请放心选购🌹🎁如果没有看到你所需要的，那就私聊我，让我来帮你找吧！😘😘
      </div>
      <Divider height={'0.19rem'} />
      <div className={styles.subTitle}>欢迎加入亚洲电视购物优惠X群🎉🎉🎉</div>
      <Divider height={'0.15rem'} />
      <div>此群专为没有时间逛淘宝的你而准备！😎</div>
      <Divider height={'0.15rem'} />
      <div>群内每天会分享一些验货爆款超级大漏洞大单。喜欢的可以打开小程序下单哦！花少量的钱买到优质好货~~避免踩雷😁</div>
      <Divider height={'0.15rem'} />
      <div>🌞如果你想要佣金，兼职赚钱，可以私聊我，带你一起省又赚✌️</div>
      <Divider height={'0.28rem'} />
      <div>
        想买不会买的欢迎骚扰我哦！！有事记得@群主哦🧨
      </div>
      <Divider height={'0.21rem'} />
      <div>==============================</div>
      <div>
        ⚠️&nbsp;群规：
      </div>
      <div>
        &nbsp;🌹&nbsp;&nbsp;群内禁止任何形式的广告！
      </div>
      <div>
        &nbsp;🌹&nbsp;&nbsp;群内禁止任何形式的链接！
      </div>
      <div>
        &nbsp;🌹&nbsp;&nbsp;群内禁止私加群员为好友！
      </div>
      <div>
        &nbsp;🌹&nbsp;&nbsp;群内禁止散发政治的东西！
      </div>
      <div>==============================</div>
      <Divider height={'0.19rem'} />
      <div>
        请大家不要着急退群，群里的商品都是性价比很高的，每天早晚还有红包，不定时有活动，感谢一直在群里支持陪伴我的人哦！么么哒！！❤️新进群的朋友们别忘了设置消息免打扰哦！💕
      </div>
      <Divider height={'0.29rem'} />
      <div className={styles.subTitle}>
        👆以上的小伙伴们先收藏起来哦😄
      </div>
      <Divider height={'0.21rem'} />
      <div className={styles.subTitle}>
        👉邀请好友进群一定要有好的方式方法
      </div>
      <Divider height={'0.14rem'} />
      <div>
        不然就会浪费了你的资源！学会对群友备注，这样方便查找！
      </div>
      <div>
        点微信好友头像，选择“设置备注和标签”
      </div>
      <div>
        举例：A001小明妈妈.日用品（可以灵活添加，也可以利用描述备注上孩子几岁，男女，购物需求、兴趣）
      </div>
      <Divider height={'0.38rem'} />
      <div className={styles.title}>第三步：私聊话述邀请微信好友进群</div>
      <Divider height={'0.17rem'} />
      <div>
        👉1、邀请一下微信内的女性朋友（你觉得有可能会网购的人）
      </div>
      <Divider height={'0.17rem'} />
      <div>
        👉2、群满40人后邀请进群的，需要她们同意群聊邀请才会进群
      </div>
      <Divider height={'0.17rem'} />
      <div>
        👉3、邀请完以后，需要发一个话述给她们，让她们知道这个群的作用
      </div>
      <Divider height={'0.17rem'} />
      <div className={styles.subTitle}>
        亲🌹这是我自己的群，我现在在做一份优惠商城的兼职，需要建群满150人才能开始工作,麻烦亲爱的，帮我邀请一些好友进群支持一下❤️
      </div>
      <Divider height={'0.26rem'} />
      <div>
        👉记住了，私聊之后，群里开始进人了，那么欢迎语，红包继续走起。
      </div>
      <Divider height={'0.19rem'} />
      <div>
        👉发下小红包，不用多，1块钱15个也行，前期投入就是几十来块钱的红包，想让支持你，你自己也要表现热情点
      </div>
      <Divider height={'0.31rem'} />
      <div className={styles.subTitle}>
        以上就是建群的方法了，学以致用，一起开始赚钱了!
      </div>
      <Divider height={'0.41rem'} />
    </div>
  );
}

export default GongLueA;
