import React from 'react';
import styles from './index.module.scss';
import { calcPrice } from '@/utils/helper';
import { calcDeduction, calcPollen } from '@/utils/profitHelper';
import BigNumber from 'bignumber.js';
import ProfitLabel from '@/components/ProfitLabel';

function ProductItem({ item, gatherId, handleGrab }) {
  const { id, thumbnail, name, showNumSales, award, price } = item;
  const deductions = calcDeduction(award);
  const pollen = calcPollen(award);
  // 最终价格
  const finalPrice = BigNumber(price).div(BigNumber(100)).minus(deductions).toFixed(2, 3);
  return (
    <>
      <div style={{ height: '0.13rem' }}></div>
      <div onClick={() => handleGrab(id)} className={styles.productItemContainer}>
        <div>
          <img src={thumbnail} className={styles.productImg} alt="" />
        </div>
        <div className={styles.productItemRightContainer}>
          <div className={styles.productTitle}>
            <span className={styles.lingYuanGouBg}>首单返利</span>
            {name}
          </div>
          <div className={styles.awardTipContainer}>
            <img src={require('../../assets/hot.png')} className={styles.awardTipIcon} alt="" />
            <span className={styles.awardTip}>{showNumSales}人已全额返</span>
          </div>
          <div className={styles.profitContainer}>
            {
              Boolean(Number(deductions)) && parseFloat(deductions) > 0 &&
                <ProfitLabel type="deduction" value={deductions} style={{ height: '0.19rem' }} />
            }
            {
              Boolean(Number(pollen)) && parseFloat(pollen) > 0 &&
                <ProfitLabel type="pollen" style={{ height: '0.19rem', marginLeft: '0.07rem' }} value={pollen} />
            }
          </div>
          <div className={styles.priceContainer}>
            <span className={styles.awardText}>补贴后&ensp;</span>
            <span className={styles.awardPrice}>¥{(!!Number(price) && !!Number(deductions)) ? parseFloat(finalPrice) : parseFloat(calcPrice(price))}</span>
            <span className={styles.discountPrice}>¥{parseFloat(calcPrice(price))}</span>
          </div>
        </div>
        <div className={styles.grabBtn}>
          抢
        </div>
      </div>
    </>
  );
}

export default ProductItem;
