import React from 'react';
import styles from './index.module.scss';
import { calcMoney } from '@/utils/profitHelper';
import { jumpTo, calcPrice } from '@/utils/helper';
import icon from './assets/icon.png';

function GoodsItem({ item, gatherId, level }) {
  const { award, price, name, thumbnail, id } = item;
  const productPrice = calcPrice(price);
  let limitLevel = 22;
  if (level > 21) limitLevel = level;
  const returnMoney = calcMoney(award, { limitLevel });

  return (
    <div
      className={styles.goodsBox}
      onClick={() => jumpTo('productDetailSelf', { itemId: id, gatherId })}
    >
      <img src={icon} className={styles.icon} alt="" />
      <img src={thumbnail} className={styles.goodsimg} alt="" />
      <div className={styles.contents}>
        <p className={styles.title}>{name}</p>
        <p className={styles.price}>¥{productPrice}</p>
        <div className={styles.foots}>分享赚¥{returnMoney}</div>
      </div>
    </div>
  );
}

export default GoodsItem;
