import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { Button } from 'antd-mobile';

function Component({ children, isGradient, style, ...restProps }) {
  return (
    <Button
      className={[styles.button, isGradient && styles.gradient]}
      style={style}
      {...restProps}
    >
      {children}
    </Button>
  );
}

Component.prototype = {
  isGradient: PropTypes.bool,
};

Component.defaultProps = {
  isGradient: false,
};

export default Component;
