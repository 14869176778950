import React, { memo } from 'react';
import styles from './index.module.scss';

function ProductInfo({ productDetail }) {
  return (
    <div className={styles.topWrapper}>
      <div className={styles.topContainer}>
        {/* 价格部分 */}
        <div className={styles.priceWrapper}>
          <div className={styles.priceContainer}>
            <span className={styles.originPrice}>
              <span className={styles.yuanText}>¥</span>
              {productDetail.price && productDetail.price / 100}
            </span>
            <span className={styles.vipPrice}>
              ¥{productDetail.vipPrice && productDetail.vipPrice / 100}
            </span>
            <img
              src={require('@/assets/beeKingPrice.png')}
              className={styles.beeKing}
              alt="蜂王价"
            />
          </div>
          <div className={styles.vipPrice}>销量 {productDetail.numSales}</div>
        </div>
        {/* 商品标题 */}
        <div className={styles.titleContainer}>
          <img
            className={styles.selfBtn}
            src={require('./assets/self_logo.png')}
            alt="自营"
          />
          <span> {productDetail.name}</span>
        </div>
        {/* 花粉 */}
        <div className={styles.pollenWrapper}>
          <div className={styles.pollenContainer}>
            <img
              className={styles.pollenImg}
              src={require('@/assets/pollen.png')}
              alt="花粉"
            />
            <span>19花粉</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(ProductInfo);
