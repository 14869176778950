/* eslint-disable no-trailing-spaces */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useLayoutEffect } from 'react';
import { ImagePicker, Toast, InputItem, ActivityIndicator } from 'antd-mobile';
import useReactRouter from 'atv-use-react-router';
import useUpload from 'atv-use-alioss-upload';
import { getUploadToken, getApplication, applyUpgrade, getMyInfo, getQunNum } from '@/utils/api';
import { getApplicationCode, getUrlParams } from '@/utils/helper';
import { useUpdateEffect } from '@/utils/hooks';
import useTitle from '@/hooks/useTitle';

import Login from '../Login';
import styles from './index.module.scss';
import SuccessBg from './assets/success.png';
import { getAuth } from '@/utils/app';

function Index() {
  useTitle('提交资料 升级店长赚钱')
  const [userName, setUserName] = useState('');
  const [wechatName, setWechatName] = useState('');
  const [userInfo, setUserInfo] = useState({});
  const [qunNum, setQunNum] = useState('');
  const [res, setRes] = useState({});
  const [example, setExample] = useState('');
  const [preview, setPreview] = useState('');
  const [success, setSuccess] = useState('');
  const [files, setFiles] = useState([]);
  const [file, setFile] = useState(null);
  const [data, setDate] = useState({});
  const [imgId, setImgId] = useState('');
  const [srcTest, setSrcTest] = useState([]);
  const [isLogin, setIsLogin] = useState(false);
  const [load, setLoad] = useState(false)
  const { imgUrl, loading, error } = useUpload(file, data, `AF-H5/${imgId}`);
  const router = useReactRouter();

  const autoFlexBody = {
    fixedBody: () => {
      const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
      document.body.style.cssText += 'position:fixed;width:100%;top:-' + scrollTop + 'px;';
    },
    looseBody: () => {
      const body = document.body;
      body.style.position = '';
      const top = body.style.top;
      document.body.scrollTop = document.documentElement.scrollTop = -parseInt(top);
      body.style.top = '';
    },
  };

  const openExample = () => {
    setExample(require('./assets/4.png'));
    autoFlexBody.fixedBody();
  };

  const onChange = (f, i) => {
    setFiles(f);
    if (typeof i === 'number') {
      srcTest.splice(i, 1);
      return;
    }
    f.length > 0 && setUpload(f);
  };

  const setUpload = f => {
    const url = dataURLtoFile(f[f.length - 1].url);
    setImgId(Date.now() + Math.round(Math.random() * 10000) + url.name);
    setFile(url);
  };

  const dataURLtoFile = (dataurl, filename = 'file') => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const suffix = mime.split('/')[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], `${filename}.${suffix}`, {
      type: mime,
    });
  };

  const onImageClick = (index, fs) => {
    const { url } = fs[index];
    setPreview(url);
    autoFlexBody.fixedBody();
  };

  const upLoad = () => {
    if (!getAuth()) {
      setIsLogin(true)
      return
    }
    if (res.isHide === 4) {
      Toast.show(getApplicationCode(res.isHide));
      return;
    }
    if (!userInfo.phonenum) {
      Toast.show('请获取手机号');
      return;
    }
    const partnerId = getUrlParams('id');
    if (!partnerId || isNaN(Number(partnerId))) {
      Toast.show('抱歉！无邀请人，请重新接受邀请');
      return;
    }
    if (!userName || !wechatName) {
      Toast.show('请完善所有信息');
      return;
    }
    if (srcTest.length === 0) {
      Toast.show('请至少上传一张截图');
      return;
    }
    applyUpgrade({
      body: {
        userName,
        qunNum,
        partnerId: Number(partnerId),
        phonenum: userInfo.phonenum,
        wechatName,
        upLevel: 23,
        images: srcTest
      }
    })
      .then(res => {
        setSuccess(true);
        setRes({});
      })
      .catch(err => {
        if (err.message === 'validation failed') {
          Toast.show('参数传输错误');
        } else {
          Toast.show('您已存在升级申请');
        }
      });
  };

  const arr = imgUrl => {
    setSrcTest(historySrc => [...historySrc, imgUrl]);
  };

  const getToken = () => {
    getUploadToken().then(res => setDate(res));
  };

  const isOk = () => {
    setIsLogin(false);
    window.location.reload();
  };

  const isCancel = () => {
    setIsLogin(false);
  };

  useUpdateEffect(() => {
    !loading && arr(imgUrl);
    // eslint-disable-next-line
  }, [loading]);

  useUpdateEffect(() => {
    if (!loading && error && files.length) {
      Toast.show('图片上传失败，请重试！');
      setFiles(his => {
        const newHis = Object.assign([], his)
        newHis.splice(his.length - 1, 1)
        return newHis
      })
    }
  }, [error])

  useLayoutEffect(() => {
    getApplication().then(res => {
      res && setRes(res);
      const underReviewCode = [1, 2, 4];
      if (res && underReviewCode.includes(res.status)) {
        setSuccess(true);
      }
    });
  }, []);

  useEffect(() => {
    getToken();
  }, []);

  useEffect(() => {
    setLoad(true)
    getMyInfo()
      .then(res => {
        setUserInfo(res);
        setLoad(false)
      })
      .catch(() => {
        setLoad(false)
      });
    getQunNum()
      .then(res => {
        setQunNum(res.qunNum);
      })
      .catch(() => {});
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.topTips}>认真填写及按要求上传图片，确保信息无误快速通过审核</div>
      <div className={styles.headerContainer}>
        <div className={styles.headerItem}>
          <p>姓&nbsp;&nbsp;&nbsp;名</p>
          <div className={styles.input}>
            <InputItem placeholder="请 输 入 姓 名" onChange={e => setUserName(e)} />
          </div>
        </div>
        <div className={styles.headerItem}>
          <p>微信号</p>
          <div className={styles.input}>
            <InputItem placeholder="请 输 入 微 信 号" onChange={e => setWechatName(e)} />
          </div>
        </div>
        <div className={styles.headerItem}>
          <p>手机号</p>
          <p className={styles.phone}>
            {userInfo.phonenum
              ? userInfo.phonenum
              : (
                <span
                  style={{ color: 'red' }}
                  onClick={() => {
                    if (load) return
                    setLoad(true)
                    getMyInfo()
                      .then(res => {
                        setUserInfo(res);
                        setLoad(false)
                      })
                      .catch(() => {
                        setLoad(false)
                      });
                  }}
                >
                  {!load && '获取失败（点击重新获取）'}
                </span>
              )
            }
          </p>
        </div>
      </div>
      <div className={styles.counterContainer}>
        <div className={styles.uploadTitle}>上传审核截图</div>
        <div className={styles.uploadTips}>
          新建或已有一个50人微信推广群，群名称修改为“亚洲电视内购福利群{qunNum}
          ”，上传两张截图(截图一：显示出群主人数 截图二：显示出群名称){' '}
          <span className={styles.uploadTipsBtn} onClick={openExample}>
            查看示例
          </span>
        </div>
        <ImagePicker
          length="3"
          files={files}
          selectable={files.length < 2}
          onChange={(files, type, index) => onChange(files, index)}
          onImageClick={(index, fs) => onImageClick(index, fs)}
          onFail={(e) => {
            Toast.show(JSON.stringify(e));
          }}
          // accept="image/gif,image/jpeg,image/jpg,image/png"
        />
      </div>
      <button className={styles.btn} onClick={upLoad}>
        提交资料
      </button>
      {/* 预览图 */}
      <div
        className={!example ? styles.exampleHide : styles.exampleShow}
        onClick={() => {
          setExample('');
          autoFlexBody.looseBody();
        }}
      >
        {!!example && <img src={example} alt="示例图" />}
      </div>
      <div className={!preview ? styles.previewHide : styles.previewShow}>
        <img
          alt="预览图"
          src={preview}
          onClick={() => {
            setPreview('');
            autoFlexBody.looseBody();
          }}
        />
      </div>
      <div className={!success ? styles.successContainerHide : styles.successContainerShow}>
        <img className={styles.successImg} alt="审核中" src={SuccessBg} />
        <h2>审核中</h2>
        <p className={styles.text}>预计审核时间1-3个工作日</p>
        {/* <p className={styles.text}>审核结果会以当前页面通知，请耐心等待</p> */}
      </div>
      {(loading || load) && (
        <div className={styles.loading}>
          <ActivityIndicator size="large" />
        </div>
      )}
      <Login isShow={isLogin} isOk={isOk} isCancel={isCancel} />
    </div>
  );
}

export default Index;
