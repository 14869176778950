import Home from '@/pages/Home';
import My from '@/pages/My';
import PlayerApplyReview from '@/pages/PlayerApplyReview';
import Download from '@/pages/Download';
// import GameInfo from '@/pages/Shop';
import User from '@/pages/User';
import Rule from '@/pages/Rule';
import ProductDetailSelf from '@/pages/ProductDetailSelf';
import SelfProductAddress from '@/pages/SelfProductAddress';
import SelfProductAddressEdit from '@/pages/SelfProductAddressEdit';
import Online from '@/pages/Online';
import UpDownload from '@/pages/UpDownload';
import HaiGuan from '@/pages/HaiGuan';
import LingYuanGou from '@/pages/LingYuanGou';
import lingYuanLing from '@/pages/lingYuanLing';
import GongLue from '@/pages/GongLue';
import GongLueA from '@/pages/GongLueA';
import GongLueB from '@/pages/GongLueB';
import GongLueC from '@/pages/GongLueC';
import GongLueD from '@/pages/GongLueD';
import GongLueE from '@/pages/GongLueE';
import TaskB from '@/pages/TaskB';
import TaskD from '@/pages/TaskD';
import GongLueDetail from '@/pages/GongLueDetail';
import Article from '@/pages/Article';
import Shopowner from '@/pages/Shopowner';
import Equitys from '@/pages/Equitys';
import upGradeUp from '@/pages/upGradeUp';
import Login from '@/pages/Login';
import Offline from '@/pages/Offline';
import InvitationLink from '@/pages/InvitationLink';
import Exhibition from '@/pages/Exhibition';
import Prices from '@/pages/Prices';
import Parity from '@/pages/Parity';
import Activity from '@/pages/Activity';
import PayPal from '@/pages/PayPal';

export default [
  {
    path: '/',
    exact: true,
    component: Home,
  },
  {
    path: '/home',
    component: Home,
  },
  {
    path: '/download',
    component: Download,
  },
  {
    path: '/my',
    component: My,
  },
  {
    path: '/user',
    component: User,
  },
  {
    path: '/rule',
    component: Rule,
  },
  // {
  //   path: '/gameInfo',
  //   component: GameInfo,
  // },
  {
    path: '/playerReview',
    component: PlayerApplyReview,
  },
  {
    path: '/productDetailSelf/:itemId',
    component: ProductDetailSelf,
  },
  {
    path: '/selfProductAddress',
    component: SelfProductAddress,
  },
  {
    path: '/selfProductAddressEdit',
    component: SelfProductAddressEdit,
  },
  {
    path: '/Online',
    component: Online,
  },
  {
    path: '/upDownload',
    component: UpDownload,
  },
  {
    path: '/haiGuan',
    component: HaiGuan,
  },
  {
    path: '/lingYuanGou',
    component: LingYuanGou,
  },
  {
    path: '/gongLue',
    component: GongLue,
  },
  {
    path: '/gongLueA',
    component: GongLueA,
  },
  {
    path: '/gongLueB',
    component: GongLueB,
  },
  {
    path: '/gongLueC',
    component: GongLueC,
  },
  {
    path: '/gongLueD',
    component: GongLueD,
  },
  {
    path: '/gongLueE',
    component: GongLueE,
  },
  {
    path: '/taskB',
    component: TaskB,
  },
  {
    path: '/taskD',
    component: TaskD,
  },
  {
    path: '/gongLueDetail',
    component: GongLueDetail,
  },
  {
    path: '/article/:id',
    component: Article,
  },
  {
    path: '/shopowner',
    component: Shopowner,
  },
  {
    path: '/equitys',
    component: Equitys,
  },
  {
    path: '/upGradeUp',
    component: upGradeUp,
  },
  {
    path: '/Login',
    component: Login,
  },
  {
    path: '/Offline',
    component: Offline,
  },
  {
    path: '/invitationLink',
    component: InvitationLink,
  },
  {
    path: '/exhibition/:id',
    component: Exhibition,
  },
  {
    path: '/prices',
    component: Prices,
  },
  {
    path: '/parity/:url',
    component: Parity,
  },
  {
    path: '/lingYuanLing',
    component: lingYuanLing,
  },
  {
    path: '/activity/:id',
    component: Activity,
  },
  {
    path: '/paypal',
    component: PayPal,
  },
];
