import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { merge } from 'lodash';
import styles from './index.module.scss';

function ProfitLabel({ type, value, style, size }) {
  const [status, setStatus] = useState({});

  useEffect(() => {
    setStatus(getAttr(type));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const getAttr = types => {
    const choose = (profile, types, def) => profile[types] || def;
    const profiles = {
      pollen: {
        label: '花粉',
        // color: themeMap.$Secondary,
        color: 'rgba(255, 44, 44, 0.5)',
        size: size || '0.13rem',
      },
      deduction: {
        label: '赠',
        color: '#FF2C2C',
        valuePrefix: '¥',
        size: size || '0.13rem',
      },
    };

    const defaultValue = {
      label: '默认',
      color: 'red',
    };

    return choose(profiles, types, defaultValue);
  };

  return (
    <div className={styles.wrapper} style={merge({ borderColor: status.color }, style)}>
      <div className={styles.type} style={{ backgroundColor: status.color, fontSize: status.size }}>
        {status.label}
      </div>
      <div className={styles.container} style={{ color: status.color, fontSize: status.size }}>
        {status.valuePrefix}
        {value}
      </div>
    </div>
  );
}

ProfitLabel.propTypes = {
  type: PropTypes.string,
};

ProfitLabel.defaultProps = {
  type: 'pollen',
  value: 0,
};

export default ProfitLabel;
