/* eslint-disable indent */
/* eslint-disable no-tabs */
import React from 'react';
import styles from './index.module.scss';
import titleright from './assets/title_right.png';
import titleleft from './assets/title_left.png';
import titleback from './assets/title_back.png';
import titlerights from './assets/title_rights.png';
import titlelefts from './assets/title_lefts.png';
function Titles({ title, desc, type, color = '#7d4b2d' }) {
	return (
		<div className={styles.titleBody}>
			{!type ? (
				<div className={styles.titleBox}>
					<img src={titlelefts} alt="" />
					<div style={{ color }}>{title}</div>
					<img src={titlerights} alt="" />
				</div>
			) : (
				<div className={styles.titleType}>
					<img src={titleleft} alt="" />
					<div style={{ backgroundImage: `url(${titleback})` }} >
						<span style={{ color }}>{title}</span>
					</div>
					<img src={titleright} alt="" />
				</div>
			)}
			{!!desc && <div className={styles.descBox} style={{ color }}>{desc}</div>}
		</div>
	);
};

export default Titles;
