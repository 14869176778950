import React, { Component, Fragment } from 'react';
import { ActivityIndicator } from 'antd-mobile';
import PropTypes from 'prop-types';
// import Loading from '@/components/Loading';
import Button from '@/components/Button';
import Divider from '@/components/Divider';
import styles from './index.module.scss';

export const StateError = 'ERROR';
export const StateLoad = 'LOADING';
export const StateEmpty = 'EMPTY';

class StatusView extends Component {
  renderButton = () => {
    const { button, onClick } = this.props;
    if (typeof button === 'string') {
      return (
        <Button onClick={onClick} style={{ minWidth: '300rpx' }}>
          {button}
        </Button>
      );
    }
    if (React.isValidElement(button)) {
      return button;
    }
  };

  renderLoading = () => {
    return <ActivityIndicator />;
  };

  renderError = () => {
    const { tips, button } = this.props;
    return (
      <Fragment>
        <img src={require('./assets/error.png')} style={{ width: '2.5rem', height: '1.84rem' }} alt="错误" />
        <Divider height={'0.16rem'} />
        <span className={styles.color}>{tips}</span>
        {button && <Divider height={'0.33rem'} />}
        {button && this.renderButton()}
      </Fragment>
    );
  };

  renderEmpty = () => {
    const { tips, button } = this.props;
    return (
      <Fragment>
        <img src={require('./assets/empty.png')} style={{ width: '2.5rem', height: '1.84rem' }} alt="空" />
        <Divider height={'0.16rem'} />
        <div className={styles.color}>{tips}</div>
        {button && <Divider height={'0.33rem'} />}
        {button && this.renderButton()}
      </Fragment>
    );
  };

  renderChild = () => {
    const { children } = this.props;
    return <Fragment>{children}</Fragment>;
  };

  render() {
    const { status, style } = this.props;
    let view = null;
    if (status === StateLoad) {
      view = this.renderLoading();
    } else if (status === StateError) {
      view = this.renderError();
    } else if (status === StateEmpty) {
      view = this.renderEmpty();
    } else {
      view = this.renderChild();
    }
    return (
      <div style={style} className={styles.content}>
        {view}
      </div>
    );
  }
}
StatusView.propTypes = {
  status: PropTypes.oneOf([StateError, StateLoad, StateEmpty]),
  tips: PropTypes.string,
  button: PropTypes.string,
  onClick: PropTypes.func,
};

StatusView.defaultProps = {
  onClick: () => {},
};
export default StatusView;
