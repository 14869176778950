import React, { memo, useState } from 'react';
import Modal from '@/components/Modal';
import styles from './index.module.scss';

function ModalShare({
  visible,
  handleVisible,
}) {
  const [type, setType] = useState(null);
  const handleHide = () => {
    const wxType = ['session', 'timeline'];
    if (type && wxType.indexOf(type) > -1) {
      // TODO: 分享逻辑
    }
  };

  const handleShare = (type) => {
    handleVisible(false);
    setType(type);
  };

  const shareOptions = [
    {
      icon: require('./assets/wechat.png'),
      type: 'wechat',
      text: '微信',
    },
    {
      icon: require('./assets/timeline.png'),
      type: 'session',
      text: '朋友圈',
    },
  ];

  const renderShareItemContainer = () => {
    return (
      <div className={styles.shareItemContainer}>
        {
          shareOptions.map(({ icon, type, text }) => {
            return (
              <div className={styles.shareItem} onClick={() => handleShare(type)} key={type}>
                <img src={icon} className={styles.shareIcon} alt="" />
                <div className={styles.shareType}>{text}</div>
              </div>

            );
          })
        }
      </div>
    );
  };

  return (
    <Modal
      popup
      animationType="slide-up"
      onClose={() => handleVisible(false)}
      visible={visible}
      afterClose={() => handleHide()}
      wrapClassName={styles.modalWrapper}
    >
      {renderShareItemContainer()}
      <div onClick={() => handleVisible(false)} className={styles.cancelContainer}>
        取消
      </div>
    </Modal>
  );
}

export default memo(ModalShare);
