import React from 'react';
import PropTypes from 'prop-types';
import Header from '@/components/Header';

import styles from './index.module.scss';

class Component extends React.Component {
  render() {
    const { style, children, ...restProps } = this.props;
    return (
      <div style={style} className={styles.content}>
        <Header {...restProps} />
        <div className={styles.container}>{children}</div>
      </div>
    );
  }
}

Component.propTypes = {
  headerHide: PropTypes.bool,
};
Component.defaultProps = {
  headerHide: false,
};

export default Component;
