/* eslint-disable semi */
/* eslint-disable no-unused-vars */
import React from 'react';
import useClipboard from 'react-use-clipboard';
import { Toast } from 'antd-mobile';
// import Commodity from '../Shopowner/components/Commodity';
import EliteList from '@/pages/Shopowner/components/EliteList';
import StarList from '@/pages/Shopowner/components/StarList';
import { jumpTo } from '@/utils/helper';
import { getMyInfo } from '@/utils/api';
import useTitle from '@/hooks/useTitle';
import Equity from './components/Equity';
import styles from './index.module.scss';
// import mod1 from './assets/mod_1.png';
import mod2 from './assets/mod_2.png';
// import backbtn from './assets/back_btn.png';
// import Titles from '../Shopowner/components/Titles';

function Equitys () {
  useTitle('店长')
  // 复制方法
  const [isCopied, setCopied] = useClipboard('qubibi2019', {
    successDuration: 1000
  });
  return (
    <div className={styles.equitys}>
      <Equity />
      {/* <Commodity info={{ level: 11 }} /> */}
      <img className={styles.footImgs} src={mod2} alt="" />
      <div className={styles.copyBox}>
        <div
          onClick={() => {
            setCopied()
            Toast.success('复制成功')
          }}
        />
      </div>
      {/* <Titles title="关于亚洲电视" color="#31302F" />
      <img className={styles.footImgs} src={mod1} alt="" /> */}
      {/* <div
        className={styles.suggest}
      >
        <div className={styles.imgBack} style={{ backgroundImage: `url(${mod1})` }}></div>
        <div className={styles.texts}>
          <p></p>
        </div>
      </div> */}
      <StarList info={{ level: 11 }} />
      <EliteList info={{ level: 11 }} />
      <div className={styles.footBtn}>
        <div
          onClick={() => {
            getMyInfo()
              .then(() => jumpTo('webview', { path: `${window.location.origin}/Online` }))
              .catch(() => jumpTo('login'));
          }}
        >
          升级实习店长
        </div>
      </div>
    </div>
  );
}

export default Equitys;
