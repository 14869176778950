import React from 'react';
import BottomNavBar from '@/components/FootTabBar';
import styles from './index.module.scss';
import useReactRouter from 'atv-use-react-router';
function My(props) {
  const router = useReactRouter().history;
  return (
    <div className={styles.fullScreen}>
      <div className={styles.container}>
        <div className={styles.span}>asdasd</div>
        <div className={styles.span}>asdasd</div>
        <div className={styles.span}>asdasd</div>
        <div className={styles.span}>asdasd</div>
        <div className={styles.span}>asdasd</div>
        <div className={styles.span}>asdasd</div>
        <div className={styles.span}>asdasd</div>
        <div className={styles.span}>asdasd</div>
        <div className={styles.span}>asdasd</div>
        <div className={styles.span}>asdasd</div>
        <div className={styles.span}>asdasd</div>
        <div className={styles.span}>asdasd</div>
        <div className={styles.span}>asdasd</div>
        <div className={styles.span}>asdasd</div>
        <div className={styles.span}>asdasd</div>
        <div className={styles.span}>asdasd</div>
        <div className={styles.span}>asdasd</div>
        <div className={styles.span}>asdasd</div>
      </div>
      <div
        onClick={() => {
          router.push('selfProductAddress', { params: {} });
        }}
      >
        Go to Address List
      </div>
      <BottomNavBar keyName="my" />
    </div>
  );
}
export default My;
