import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Divider from '@/components/Divider'
import ProfitLabel from '@/components/ProfitLabel'
import { calcPrice } from '@/utils/helper'
import { calcDeduction } from '@/utils/profitHelper'

import productDefaultImg from '@/assets/product_default.png'
import styles from './style.module.scss'

import labelTb from '../../assets/label_tb.png'
import labelJd from '../../assets/label_jd.png'
import labelTianmao from '../../assets/label_tianmao.png'

function Sitem ({ data, gatherId, handleItemDetail }) {
  const { id, thumbnail, name, price, extraPrice, award } = data
  const { bazaar, jd, tb, tianmao } = (extraPrice && JSON.parse(extraPrice)) || {}
  const thirdPartyPriceProfile = [
    { price: tb, icon: labelTb },
    { price: tianmao, icon: labelTianmao },
    { price: jd, icon: labelJd }
  ]
  const priceArr = calcPrice(price).split('.')
  const deductions = calcDeduction(award)

  const [productThumbnailSrc, setProductThumbnailSrc] = useState(thumbnail)

  const onImgError = () => {
    setProductThumbnailSrc(productDefaultImg)
  }

  //  第三方价格展示
  const renderThirdPartyPrice = ({ price, icon }, index) => {
    if (!price) {
      return null
    }
    const showPrice = calcPrice(price)
    return (
      <div key={index} className={styles.labelPrice}>
        <img src={icon} className={styles.icon} />
        <span className={styles.priceText}>¥{showPrice}</span>
      </div>
    )
  }

  return (
    <div className={styles.sitemWrapper} onClick={() => handleItemDetail(id, gatherId)}>
      <img
        src={productThumbnailSrc || productDefaultImg}
        className={styles.productPic}
        alt="pic"
        onError={onImgError}
      />
      <div className={styles.container}>
        <div className={styles.name}>{name}</div>
        <Divider height="0.14rem" />
        <div className={styles.thirdPartyPrice}>
          {thirdPartyPriceProfile.map((item, index) => renderThirdPartyPrice(item, index))}
        </div>
        <Divider height="0.14rem" />
        <div className={styles.selfPrice}>
          <div className={styles.selfPriceRow}>
            <span className={styles.selfPriceText}>HK$</span>
            <span className={styles.selfPriceTextLarge}>{priceArr[0]}</span>
            <span className={styles.selfPriceText}>.{priceArr[1]}</span>
          </div>
          <span className={styles.lineThroughPrice}>¥{calcPrice(bazaar)}</span>
          {Boolean(Number(deductions)) && parseFloat(deductions) > 0 && (
            <ProfitLabel
              type="deduction"
              value={deductions}
              style={{ marginLeft: '0.06rem', height: '0.19rem' }}
            />
          )}
        </div>
      </div>
    </div>
  )
}

Sitem.propTypes = {
  data: PropTypes.object
}

Sitem.defaultProps = {
  data: {}
}

export default Sitem
