/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import SuccessImg from './assets/success.png';
import ErrorImg from './assets/err.png';

function GameInfo() {
  const [success, setSuccess] = useState('授权成功');
  const [img, setImg] = useState(true);
  const closeUrl = () => {
    window.Baichuan.getDeviceInfo(result => {
      if (result.code !== 6) {
        setImg(false);
        setSuccess('授权失败');
      }
    });
  };
  useEffect(() => {
    closeUrl();
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.count}>
        <img className={styles.success} src={img ? SuccessImg : ErrorImg} />
        <span className={styles.text}>{success}</span>
      </div>
    </div>
  );
}

export default GameInfo;
