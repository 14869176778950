import React from 'react';
import Charts from '../Charts';
import styles from './index.module.scss';

function TrendMap ({ datas }) {
  let lowest = 0
  const lists = datas.historyPrice.map(i => {
    if (lowest === 0) lowest = i.goodsPrice
    else if (i.goodsPrice < lowest) lowest = i.goodsPrice
    return i.goodsPrice
  })
  const countList = lists.slice(0, 30)
  const count = countList.reduce((prev, next) => {
    return prev + next
  })
  return (
    <div className={styles.trendmap}>
      <p className={styles.title}>价格趋势</p>
      <div className={styles.desc}>
        近一年最低到手价：
        <span>¥{(lowest / 100).toFixed(2)}</span>
        ，当前价{lowest * 30 === count ? '等' : lowest * 30 > count ? '高' : '低'}于30天平均值
      </div>
      <Charts dataSouce={datas.historyPrice} />
    </div>
  )
}

export default TrendMap;
