import React from 'react'
import { Modal } from 'antd-mobile'
import Button from '@/components/Button'
import styles from './index.module.scss'

function LingYuanGouModal ({ visible, handleJump, handleVisible, content }) {
  const { activityName, promotionObject, notPromotionObject } = content
  const btnStyle = {
    backgroundColor: '#fed32f',
    width: '2rem',
    height: '0.45rem',
    borderRadius: '0.07rem',
    color: '#141115',
    fontSize: '0.19rem'
  }

  return (
    <div>
      <Modal
        visible={visible}
        onClose={() => handleVisible(false)}
        transparent
        wrapClassName={styles.modalWrapper}
      >
        <div className={styles.modalContainer}>
          <span className={styles.modalTitle}>对不起！</span>
          <span className={styles.modalContent}>
            【{activityName}】是{promotionObject}专属特权！您已经是{notPromotionObject}
            ，暂时无法参与！
          </span>
          <Button onClick={() => handleVisible(false)} style={btnStyle}>
            好&emsp;的
          </Button>
        </div>
      </Modal>
    </div>
  )
}

export default LingYuanGouModal
