import React from 'react';
import PropTypes from 'prop-types';
import { ListView, Icon } from 'antd-mobile';
import styles from './style.module.scss';

function Component({ isEnd, data, ...restProps }) {
  const dataSource = new ListView.DataSource({
    rowHasChanged: (row1, row2) => row1 !== row2,
  });

  const renderFooter = () => {
    if (!data || data.length === 0) {
      return null;
    } else if (isEnd) {
      return (
        <div style={{ padding: 5, textAlign: 'center' }}>没有更多数据了</div>
      );
    } else {
      return (
        <div style={{ padding: 5, textAlign: 'center' }}>
          {<Icon type="loading" />}
        </div>
      );
    }
  };

  const renderSeparator = () => {
    return <div style={{ height: 1, backgroundColor: '#f5f5f5' }}></div>;
  };
  const renderEmpty = () => {
    return <div className={styles.empty}>暂无数据</div>;
  };

  const renderContent = () => {
    const view =
      data.length === 0 ? (
        renderEmpty()
      ) : (
        <ListView
          dataSource={dataSource.cloneWithRows(data)}
          renderFooter={renderFooter}
          renderSeparator={renderSeparator}
          {...restProps}
        />
      );
    return view;
  };
  return <div>{renderContent()}</div>;
}

Component.propTypes = {
  isEnd: PropTypes.bool,
};

Component.defaultProps = {
  initialListSize: 20,
  useBodyScroll: true,
  isEnd: true,
};

export default Component;
