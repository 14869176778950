export default function () {
  window.debugTimeout = 0;
  window.debugCount = 0;
  window.isDebug = false;

  function initDebugBtn() {
    var debugEl = document.createElement('div');
    debugEl.id = 'debug';
    debugEl.style.position = 'absolute';
    debugEl.style.width = '110px';
    debugEl.style.height = '50px';
    debugEl.style.top = '0';
    debugEl.style.left = '0';
    debugEl.style.zIndex = '998';
    debugEl.onclick = debug;
    document.body.appendChild(debugEl);
  }
  function debug() {
    if (window.isDebug) return;
    window.debugCount++;
    if (window.debugCount === 10) openDebug();
    resetDebugTimeout();
  }

  function resetDebugTimeout() {
    clearTimeout(window.debugTimeout);
    window.debugTimeout = setTimeout(function () {
      window.debugTimeout = 0;
      window.debugCount = 0;
    }, 400);
  }

  function openDebug() {
    window.isDebug = true;
    var scriptEl = document.createElement('script');
    scriptEl.src = '//cdn.jsdelivr.net/npm/eruda';
    scriptEl.onload = function () {
      window.eruda.init();
      // eslint-disable-next-line no-console
      if (window.VERSION) console.log('build-date=' + window.VERSION);
    };
    document.body.appendChild(scriptEl);
  }

  initDebugBtn();
}
