import { useReducer } from 'react';
import produce from 'immer';
import createUseContext from 'constate';

const initialState = {
  sysconfigs: null,
  userData: null,
  gathers: null,
};

const reducer = (state, { type, payload }) => produce(state, draft => {
  switch (type) {
    case 'UPDATE_STATE':
      draft[payload.key] = payload.value;
      break;
    default:
  }
});

const useHook = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    sysconfigs,
    userData,
    gathers,
  } = state;

  // 更新state
  const updateState = (key, val) => {
    dispatch({
      type: 'UPDATE_STATE',
      payload: {
        key,
        value: val,
      },
    });
  };

  return {
    sysconfigs,
    userData,
    gathers,
    updateState,
  };
};

const useReducerContext = createUseContext(useHook);

export default useReducerContext;
