import makeRequest from '@/utils/makeRequest';

export const mockToken = makeRequest('post', '/c/mockToken');
export const keyToToken = makeRequest('get', '/c/keyToToken');
export const wxPubAuthRedirect = makeRequest('get', '/c/wxPubAuthRedirect');
export const getWechatJSConfig = makeRequest('post', '/c/wechatJSConfig');
export const getScode = makeRequest('post', '/c/scode');
export const bindPhone = makeRequest('post', '/c/bindPhone');
export const phoneLogin = makeRequest('post', '/c/phoneLogin');
export const getGameAreaAll = makeRequest('get', '/c/gameAreas');
export const getGameEvent = makeRequest('get', '/c/events/:id');
export const getSysconfig = makeRequest('get', '/c/sysconfigs/all');
export const listPlayers = makeRequest('get', '/c/players');
export const listRankPlayers = makeRequest('get', '/c/players/rank');
export const listRankAudiences = makeRequest('get', '/c/audiences/rank');
export const listMyFans = makeRequest('get', '/c/audiences/:playerId');
export const getFanGifts = makeRequest('get', '/c/audiences/:playerId/gifts');
export const getPlayer = makeRequest('get', '/c/players/:id');
export const listGameEvents = makeRequest('get', '/c/events');
export const listSponsors = makeRequest('get', '/c/sponors');
export const getSponsor = makeRequest('get', '/c/sponors/:id');
export const applyPlayer = makeRequest('post', '/c/players/apply');
export const updateApplyInfo = makeRequest('post', '/c/players/updateApply');
export const getPlayerApply = makeRequest('get', '/c/playersApply');
export const listAllRegions = makeRequest('get', '/c/regions');
export const listReceivedGifts = makeRequest('get', '/c/gifts/receive');
export const listSendGifts = makeRequest('get', '/c/gifts/send');
export const giftPlayer = makeRequest('post', '/c/gifts');
export const listGiftKinds = makeRequest('get', '/c/giftKinds', { useCache: true });
export const listGameSchedules = makeRequest('get', '/c/gameSchedules');
export const listJudges = makeRequest('get', '/c/judges');
export const getJudge = makeRequest('get', '/c/judges/:id');
export const getVideoInfo = makeRequest('get', '/c/videoInfo');
export const getMentor = makeRequest('get', '/c/mentors/:id');
export const getMentorTeams = makeRequest('get', '/c/mentorTeams/:id');
export const getUser = makeRequest('get', '/c/user');
export const updateUser = makeRequest('put', '/c/user');
export const gameAreaVisitNumber = makeRequest('post', '/c/visitNumber');
export const uploadVoice = makeRequest('post', '/c/players/voice');
export const addNumVisitors = makeRequest('post', '/c/addNumVisitors');
export const getPPInfo = makeRequest('get', '/c/gifts/ppinfo');
export const listMyGiftedPlayers = makeRequest('get', '/c/listPlayers/myGifted');
export const createReservation = makeRequest('post', '/c/reservation');
export const getStatePhoneNum = makeRequest('get', '/c/state/phoneNum');
// 获取自营商品详情
export const getSelfProductDetail = makeRequest('get', '/c/items/self/one/:id');
// 获取区域列表
export const getRegions = makeRequest('get', '/c/regions/all');
// 获取用户收货地址列表
export const getUserAddressList = makeRequest('get', '/c/receivers');
// 添加收货地址
export const newAddress = makeRequest('post', '/c/receivers');
// 修改收货地址
export const updateAddress = makeRequest('put', '/c/receivers');
// 删除收货地址
export const deleteAddress = makeRequest('delete', '/c/receivers');
// 获取hash
export const getHash = makeRequest('get', '/c/cachedata');
// 获取我的信息
export const getMyInfo = makeRequest('get', '/c/my/info');
// 获取用户抵扣金信息
export const getDeductionInfo = makeRequest('get', '/c/deduction');
// 获取商品集
export const getProductGather = makeRequest('get', '/c/items/gather');
// 获取集合
export const getGathers = makeRequest('get', '/c/gathers');
// 获取攻略详情
export const getGongLueDetail = makeRequest('get', '/c/guide/:id');
// 获取我的粉丝
export const getHomeFan = makeRequest('get', '/c/home/fan');
// 获取收益明细
export const getIncomeData = makeRequest('get', '/c/stats/my/incomeData');
export const getMyData = makeRequest('get', '/c/stats/my/data');
export const getGuides = makeRequest('get', '/c/guides');
export const getUploadToken = makeRequest('get', '/c/uploadToken');
export const getApplication = makeRequest('get', '/c/users/application');
export const applyUpgrade = makeRequest('post', '/c/users/application');
// 获取验证码
export const smsCode = makeRequest('post', '/c/code/sms');
// 验证码登录
export const loginPhonenum = makeRequest('post', '/c/login/phonenum');
// 验证码注册
export const signupPhonenum = makeRequest('post', '/c/signup/phonenum');
// 获取任务列表
export const shopMaster = makeRequest('get', '/c/home/task/practiceShopMaster');
// 提交任务列表
export const postReceive = makeRequest('post', '/c/task/receive');
// 花粉升级店长
export const upgrade = makeRequest('post', '/c/user/upgrade');
// 群号
export const getQunNum = makeRequest('get', '/c/users/application/qunnum');
// 获取任务花粉数量
export const getTaskNum = makeRequest('get', '/c/user/upgrade/condition');
// 店长升级连锁店长
export const upgradeSuperShopMaster = makeRequest('post', '/c/user/upgradeSuperShopMaster');
// 连锁店长升级合伙人
export const upgradeCommonPartner = makeRequest('post', '/c/user/upgradeCommonPartner');
// 反hash
export const dehashData = makeRequest('get', '/p/cachedata');
// 获取邀请人信息(无需登录)
export const getInviterInfo = makeRequest('get', '/p/partner/info/:id');
// 查询历史价格
export const getPriceTrend = makeRequest('post', '/c/items/price/externalTrend');
// 获取0元领的商品可购买数量
export const getCanpurchasecount = makeRequest('post', '/c/items/canpurchasecount');
// 获取活动集合
export const getActivityGather = makeRequest('get', '/c/activity/gather');
// 获取活动集合商品列表
export const getActivitySitems = makeRequest('get', '/c/items/activity');
