import { useLayoutEffect } from 'react';

export const TOP_LEFT = 'top-left';
export const TOP_RIGHT = 'top-right';
export const BOTTOM_LEFT = 'bottom-left';
export const BOTTOM_RIGHT = 'bottom-right';

// 把debug彩蛋移到左下角, 避免点击失败
export default function useDebug(position = TOP_LEFT) {
  useLayoutEffect(() => {
    const debugElm = document.getElementById('debug');
    switch (position) {
      case TOP_LEFT:
        debugElm.style.top = '0';
        debugElm.style.left = '0';
        break;
      case TOP_RIGHT:
        debugElm.style.top = '0';
        debugElm.style.left = null;
        debugElm.style.right = '0';
        break;
      case BOTTOM_LEFT:
        debugElm.style.top = null;
        debugElm.style.bottom = '0';
        break;
      case BOTTOM_RIGHT:
        debugElm.style.top = null;
        debugElm.style.left = null;
        debugElm.style.bottom = '0';
        debugElm.style.right = '0';
        break;
      default:
        break;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
