import { AUTH_KEY, GAMEAREA_INFO_KEY } from '@/utils/constants'
// import { isWeixinBrowser } from './helper';
// import { Toast } from 'antd-mobile';

// 登录信息
export function getAuth () {
  const rawAuth = localStorage.getItem(AUTH_KEY)
  if (!rawAuth) {
    return false
  }
  try {
    const parsedAuth = JSON.parse(rawAuth)
    if (parsedAuth.expiredAt < Date.now()) {
      return false
    }
    return parsedAuth
  } catch (err) {
    return false
  }
}

export function beforeFetch (options) {
  const auth = getAuth()
  if (!auth) {
    return options
  }
  if (!options.headers) options.headers = {}
  options.headers.Authorization = 'Bearer ' + auth.token
  return options
}

export function saveAuth (authValue) {
  localStorage.setItem(AUTH_KEY, JSON.stringify(authValue))
}

export function clearAuth () {
  localStorage.removeItem(AUTH_KEY)
}

export function errorFetch (res) {
  const { status, data } = res.response || {}
  if (status === 401) clearAuth()
  return Promise.reject({...data, status})
}

export function afterFetch (res) {
  return res.data
}

export function toLogin () {
  // Toast.fail('执行该操作需要登录！即将跳转登录', 2, () => {
  //   if (isWeixinBrowser()) {
  //     window.location.href = '/api/c/wxPubAuthRedirect?redirect=' + encodeURIComponent(window.location.pathname);
  //   } else {
  //     window.location.href = '/login';
  //   }
  // });
}

export function toServerError () {
  window.location.href = '/500'
}
export function getGameAreaId () {
  const info = getGameArea()
  if (info) {
    return info.id
  }
}

export function getGameArea () {
  if (gameArea) {
    return gameArea
  }
  gameArea = JSON.parse(localStorage.getItem(GAMEAREA_INFO_KEY))
  return gameArea
}

export function saveGameArea (info) {
  gameArea = info
  localStorage.setItem(GAMEAREA_INFO_KEY, JSON.stringify(info))
}
