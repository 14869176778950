import { useEffect } from 'react';
import { Toast } from 'antd-mobile';
import useReducerContext from '@/reducer/userReducer';
import { H5_PREFIX, shareDefaultImg, sharePath } from './constants'
import BigNumber from 'bignumber.js';
import { isEmpty } from 'lodash';
import { getMyInfo } from './api';
import url from 'url';

export function timeInXeixinFormat(timeStr) {
  function toWeek(day) {
    switch (day) {
      case 1:
        return '周一';
      case 2:
        return '周二';
      case 3:
        return '周三';
      case 4:
        return '周四';
      case 5:
        return '周五';
      case 6:
        return '周六';
      default:
        return '周日';
    }
  }
  const currentTime = new Date();
  const time = new Date(timeStr);
  const day = (currentTime - time) / 1000 / 3600 / 24;
  if (day >= 7) {
    return `${time.getMonth() + 1}月${time.getDate()}日`;
  }
  if (day >= 2) {
    return toWeek(time.getDay());
  }
  if (day >= 1) {
    return '昨天';
  }
  const hours = time.getHours();
  const minutes = time.getMinutes();
  if (hours > 12) {
    return `下午${hours - 12}:${+minutes >= 10 ? minutes : '0' + minutes}`;
  }
  return `上午${hours > 10 ? hours : `0${hours}`}:${
    minutes > 10 ? minutes : `0${minutes}`
  }`;
}

// 把ISO或者其他格式的转成YYYY-mm-dd HH:MM:SS
export function convertTime(time) {
  const newTime = new Date(time);
  const year = newTime.getFullYear();
  const getMonth = newTime.getMonth();
  const month = getMonth >= 9 ? getMonth + 1 : `0${getMonth + 1}`;
  const getDate = newTime.getDate();
  const date = getDate >= 10 ? getDate : `0${getDate}`;
  const getHour = newTime.getHours();
  const hour = getHour >= 10 ? getHour : `0${getHour}`;
  const getMin = newTime.getMinutes();
  const min = getMin >= 10 ? getMin : `0${getMin}`;
  const getSec = newTime.getSeconds();
  const sec = getSec >= 10 ? getSec : `0${getSec}`;
  return `${year}-${month}-${date} ${hour}:${min}:${sec}`;
}

export function setupWxShare(
  wx,
  title = '',
  desc = '',
  imgUrl = '',
  link = window.location.href
) {
  const shareConfig = {
    title,
    desc,
    link,
    imgUrl,
    type: 'link',
    data_url: '',
    success: () => {},
    cancel: () => {},
  };
  wx.onMenuShareTimeline(shareConfig);
  wx.onMenuShareAppMessage(shareConfig);
}

// 微信支付设置
export function setupWxPay(wx, config) {
  const { timestamp, nonceStr, package: pkg, signType, paySign } = config;
  const payConfig = {
    timestamp,
    nonceStr,
    package: pkg,
    signType,
    paySign,
    success: () => {},
  };
  if (config) {
    wx.chooseWXPay(payConfig);
  }
}

// 修复在ios下键盘弹起后，用于解决收起键盘不下来的bug，在input onBlur时使用
// antd-mobile的input相关组件默认修复这个bug
export function IOSKeyboardFix() {
  if (!/iphone|ipod|ipad/i.test(navigator.appVersion)) {
    return;
  }
  // eslint-disable-next-line
  document.body.scrollTop = document.body.scrollTop;
}

// 判断当前浏览器是否为微信浏览器
export function isWeixinBrowser() {
  return /micromessenger/.test(navigator.userAgent.toLowerCase());
}

function onBridgeReady(res, payInfo, actionList) {
  const {
    appId,
    timeStamp,
    nonceStr,
    package: pkg,
    signType,
    paySign,
  } = res.credentials;
  const { playerId, amount, giftVote } = payInfo;
  const { LivePaySuccess, onChangeVisible, onChangePayVisible } = actionList;
  window.WeixinJSBridge.invoke(
    'getBrandWCPayRequest',
    {
      appId: appId, // 公众号名称，由商户传入
      timeStamp: timeStamp, // 时间戳，自1970年以来的秒数
      nonceStr: nonceStr, // 随机串
      package: pkg,
      signType: signType, // 微信签名方式：
      paySign: paySign, // 微信签名
    },
    function (res) {
      if (res.err_msg === 'get_brand_wcpay_request:ok') {
        // 使用以上方式判断前端返回,微信团队郑重提示：
        // res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
        LivePaySuccess(playerId, amount, giftVote);
        onChangeVisible(false);
        onChangePayVisible(false);
        Toast.success('投票成功', 1);
      }
      if (res.err_msg === 'get_brand_wcpay_request:cancel') {
        onChangePayVisible(false);
      }
      if (res.err_msg === 'get_brand_wcpay_request:fail') {
        Toast.fail('支付失败', 1);
        onChangeVisible(false);
        onChangePayVisible(false);
      }
    }
  );
}

// 微信支付逻辑
export async function wechatPay(res, payInfo, actionList) {
  try {
    if (typeof window.WeixinJSBridge === 'undefined') {
      if (document.addEventListener) {
        document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
      } else if (document.attachEvent) {
        document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
        document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
      }
    } else {
      onBridgeReady(res, payInfo, actionList);
    }
  } catch (e) {
    const { onChangePayLoading } = actionList;
    onChangePayLoading(false);
    Toast.fail(e.body.message, 1);
  }
}

export function isHans() {
  return navigator.language === 'zh-CN';
}

export function useWxJSSdk(config, cb) {
  useEffect(() => {
    if (!window.wx) {
      return;
    }
    if (config) {
      const { wx } = window;
      wx.ready(() => cb(wx));
      wx.config(config);
    }
    // eslint-disable-next-line
  }, [config]);
}

export function isSafariBrowser() {
  const ua = navigator.userAgent;
  // IOS系统
  if (/ OS \d/.test(ua)) {
    // 不是Chrome
    if (!~ua.indexOf('CriOS')) {
      // 开头必须为Mozilla
      if (!ua.indexOf('Mozilla')) {
        // 结尾需为：Safari/xxx.xx
        // eslint-disable-next-line
        if (/Safari\/[\d\.]+$/.test(ua)) {
          return true;
        }
      }
    }
  }
  return false;
}

export const reloadPage = () => {
  const pageUrl = new url.Url();
  const { pathname, origin, query } = pageUrl.parse(window.location.href, true);
  const href = url.format({
    pathname,
    origin,
    query: Object.assign({}, query, { time: new Date().getTime() }),
  });
  // 华为手机replace页面不刷新,所以需要强制拼上time替换原链接
  window.location.replace(href);
};

// 获取n个字节的字符串
export function cutString(str, len, suffix) {
  if (!str) return '';
  if (len <= 0) return '';
  if (!suffix) suffix = '';
  let templen = 0;
  for (let i = 0; i < str.length; i++) {
    if (str.charCodeAt(i) > 255) {
      templen += 2;
    } else {
      templen++;
    }
    if (templen === len) {
      return str.substring(0, i + 1) + suffix;
    } else if (templen > len) {
      return str.substring(0, i) + suffix;
    }
  }
  return str;
}

// 获取字符串字节数
export function strLen(str) {
  var len = 0;
  for (let i = 0; i < str.length; i++) {
    var c = str.charCodeAt(i);
    // 单字节加1
    if ((c >= 0x0001 && c <= 0x007e) || (c >= 0xff60 && c <= 0xff9f)) {
      len++;
    } else {
      len += 2;
    }
  }
  return len;
}

const mapRegions = (regions, id) => {
  for (var province of regions) {
    if (province.id === id) {
      return [province];
    }
    for (var city of province.Children) {
      if (city.id === id) {
        return [province, city];
      }
      for (var dist of city.Children) {
        if (dist.id === id) {
          return [province, city, dist];
        }
      }
    }
  }
  return [];
};

export const getRegionArrayById = id => {
  const regions = JSON.parse(localStorage.getItem('REGIONS'));
  const country = regions;
  const value = mapRegions(country.Children, id);
  return value;
};

export const getRegionNameById = (id, prefix = ' ') => {
  const value = getRegionArrayById(id);
  if (value) {
    return value.map(item => item.name).join(prefix);
  }
};

/**
 * 用于处理一个className多个css模块的问题
 * 由于部分需要做条件判断，可能会导致把false或者null等falsy值转成字符串，此方法可以过滤这些值
 * @example clsFlatten([styles.xxx, false && styles.yyy])
 * @param {Array} clsList cssmodule列表
 */
export const clsFlatten = (clsList) => clsList.filter(cls => Boolean(cls)).join(' ');

// RN分享
export const shareRN = (params) => {
  window.RN.setShare({
    data: params
  });
};

// 小程序分享
export const shareMP = (params) => {
  window.wx.miniProgram.postMessage({
    data: params
  })
}

// webview页面分享
export const webviewShare = (url, title, icons) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { updateState, userData } = useReducerContext()
  const { inviteCode = '', loading, code } = userData || {}
  if (!inviteCode && !loading && !code) {
    Promise.all([]).then(([]) => updateState('userData', { loading: true }))
    Promise.all([getMyInfo()])
      .then(([userData]) => {
        updateState('userData', userData)
      })
      .catch(e => {
        updateState('userData', { code: 'fail' })
      })
  }
  if (inviteCode || code) {
    const path = url ? `/pages/launch/index?pg=webview&invite=${inviteCode}&src=${H5_PREFIX}${url}` : sharePath
    const thumbnail = `${H5_PREFIX}${icons}` || shareDefaultImg
    if (isRN()) {
      const webpageUrl = `${H5_PREFIX}/download?code=${inviteCode}`
      const params = {
        title,
        path,
        thumbImageUrl: thumbnail,
        webpageUrl
      }
      shareRN(params)
    } else {
      const WXParams = {
        title,
        path,
        imageUrl: thumbnail
      }
      shareMP(WXParams)
    }
  }
}

// 判断webview是否在RN内
export const isRN = () => {
  let flag;
  window.RN.getEnv((res) => {
    // 小程序跳转
    if (res.rn) {
      flag = true;
    }
  });
  return flag;
};

// 判断webview是否在小程序内
export const isMP = () => {
  const ua = navigator.userAgent.toLowerCase();
  if (ua.indexOf('micromessenger') > -1) {
    let flag;
    window.wx.miniProgram.getEnv((res) => {
      // 小程序跳转
      if (res.miniprogram) {
        flag = true;
      }
    });
    return flag;
  }
  return false;
};

/**
 * webView跳小程序/app逻辑
 * @param {String} path 路径
 * @param {Object} params 路由参数
 */
export const jumpTo = (path, params = {}) => {
  const switchTab = {
    'home': ['/pages/home/home/index', 'HomeIndex'],
    'bijia': ['/pages/home/bijia/index', 'HomeBijia']
  }
  const diffNav = {
    'login': ['authUserInfo', 'authLogin'],
    'sharePoster': ['sharePoster', 'inviteFriendsPoster']
  }
  const query = Object.keys(params).map(i => {
    if (path === 'webview' && i === 'path') {
      const field = isMP() ? 'src' : isRN() ? 'source[uri]' : i
      return `${field}=${encodeURIComponent(params[i])}`
    }
    return `${i}=${params[i]}`
  }).join('&')
  switch (path) {
    case 'home':
    case 'bijia':
      if (isMP()) {
        window.wx.miniProgram.switchTab({
          url: switchTab[path][0],
        });
      }
      if(isRN()) {
        window.RN.switchTab({
          url: switchTab[path][1],
        });
      }
      break;
    default:
      if (isMP()) {
        const pathMP = diffNav[path] ? diffNav[path][0] : path
        window.wx.miniProgram.navigateTo({
          url: `/pages/${pathMP}/index${query && `?${query}`}`
        });
      }
      if(isRN()) {
        const pathRN = diffNav[path] ? diffNav[path][1] : path
        window.RN.navigateTo({
          url: `${pathRN}${query && `?${query}`}`
        });
      }
      break;
  }
};

const getProfitAttr = type => {
  const choose = (profile, type, def) => profile[type] || def;
  const typeProfiles = {
    pollen: {
      profitType: 'pollen',
      fixed: 0,
      isFixed: true,
      generation: 1, // 代、辈分
    },
    award: {
      profitType: 'award',
      fixed: 2,
      isFixed: true,
      generation: 2,
    },
  };
  const def = {
    profitType: 'pollen',
    fixed: 0,
    isFixed: true,
    generation: 1,
  };

  return choose(typeProfiles, type, def);
};

/**
 * 金额处理
 * @param {number} price - 需要处理的金额
 * @param {number} fixed - 保留位数（当 isFixed 为 true 时生效）
 * @param {boolean} isFixed - 是否保留小数位数
 */
export const calcPrice = (price, fixed = 2, isFixed = true) => {
  if (!price) {
    return BigNumber(0).toFixed(fixed);
  }
  const temp = BigNumber(price).div(BigNumber(100));
  const res = isFixed ? temp.toFixed(fixed, 3) : temp.dp(fixed, 3);
  return res;
};

/**
 * 计算收益
 * 用户等级收益 * 利率 * 商品返现佣金 / 100 * 该商品所属的商品集合分润因子
 * @param {string} type - 收益类型 花粉: pollen 返现: award
 * @param {number} returnProfit - 利润空间
 * @param {number} gatherId - 商品集合id
 * @param {string} distribution - 配置类型（可选）
 * @param {number} limitLv - 指定用户等级（可选） e.g. 1: 普通用户 2:店长 3:导师
 */
export const calcProfit = ({ type = 'pollen', distribution, returnProfit, limitLv, gatherId }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { sysconfigs, userData, gathers } = useReducerContext();
  if (sysconfigs && gathers) {
    const { defaultDistribution } = sysconfigs;

    const { profitType, fixed, isFixed, generation } = getProfitAttr(type);

    const checkValid = () => {
      const checkResult = [type, returnProfit, sysconfigs, gathers].every(
        item => item
      );
      return checkResult;
    };
    if (!checkValid()) {
      return isFixed
        ? BigNumber(0).toFixed(fixed, 3)
        : BigNumber(0)
          .dp(fixed, 3)
          .toNumber();
    }

    let profits = {};
    const distr = distribution || defaultDistribution;
    profits = sysconfigs[distr][profitType];

    const { level } = isEmpty(userData) ? { level: 1 } : userData;
    const { profit } = profits || {};
    // 用户等级
    const lv = limitLv || level;
    let temp = BigNumber(profits[generation][lv - 1])
      .times(BigNumber(profit))
      .times(BigNumber(returnProfit))
      .div(100);

    type === 'award' && (temp = temp.div(100));

    if (gatherId) {
      // 商品集合属性
      const { returnProfitDivisor } = gathers.filter(v => v.id === Number(gatherId))[0] || {};
      temp = temp.times(BigNumber(returnProfitDivisor));
    }
    const res = isFixed ? temp.toFixed(fixed, 3) : temp.dp(fixed, 3).toNumber();
    return res;
  } else {
    return 0;
  }
};

/**
 * 计算花粉
 * 用户等级收益 * 利率 * 商品返现佣金 / 100 * 该商品所属的商品集合分润因子
 * @param {number} pollenProfit - 利润空间
 * @param {number} gatherId - 商品集合id
 * @param {string} distribution - 配置类型（可选）
 * @param {number} limitLv - 指定用户等级（可选） e.g. 1: 普通用户 2:店长 3:导师
 */
export const calcPollen = ({ distribution, pollenProfit, limitLv, gatherId }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { sysconfigs, userData, gathers } = useReducerContext();
  if (sysconfigs && gathers) {
    const { defaultDistribution } = sysconfigs || {};
    const fixed = 0;
    const generation = 1; // 代、辈分

    const checkValid = () => {
      const checkResult = [pollenProfit, sysconfigs, gathers].every(item => item);
      return checkResult;
    };
    if (!checkValid()) {
      return BigNumber(0).toFixed(fixed, 3);
    }

    let profits = {};
    const distr = distribution || defaultDistribution;

    profits = sysconfigs[distr]['pollen'];

    const { level } = isEmpty(userData) ? { level: 1 } : userData;
    const { profit } = profits || {};
    // 用户等级
    const lv = limitLv || level;
    let temp = BigNumber(profits[generation][lv - 1])
      .times(BigNumber(profit))
      .times(BigNumber(pollenProfit))
      .div(100);

    if (gatherId) {
      // 商品集合属性
      const { pollenProfitDivisor } = gathers.filter(v => v.id === Number(gatherId))[0] || {};
      temp = temp.times(BigNumber(pollenProfitDivisor));
    }
    const res = temp.toFixed(fixed, 3);

    return res;
  } else {
    return 0;
  }
};

/**
 * 计算获得抵扣金
 * @param {string | number} deduction - 抵扣空间
 * @param {number} gatherId - 商品集合id
 */
export const calcDeduction = ({ deduction, gatherId }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { gathers } = useReducerContext();
  if (gathers) {
    const checkValid = () => {
      return [deduction, gathers].every(item => item);
    };
    if (!checkValid()) {
      return 0;
    }

    let temp = BigNumber(deduction).div(100);
    if (gatherId) {
      // 商品集合属性
      const { deductionDivisor } = gathers.filter(v => v.id === Number(gatherId))[0] || {};
      temp = temp.times(BigNumber(deductionDivisor));
    }
    const res = temp.dp(2, 3).toNumber();
    return res;
  } else {
    return 0;
  }
};

export const getApplicationCode = (status) => {
  switch (status) {
    case 1:
      return '合伙人正在审核, 请耐心等待';
    case 2:
      return '合伙人审核成功';
    case 3:
      return '合伙人审核失败';
    case 4:
      return '管理后台正在审核, 请耐心等待';
    case 5:
      return '管理后台审核成功';
    case 6:
      return '管理后台审核失败';
    default:
      return null;
  }
};

/**
 * parse对象
 * @param {string} str 需解析字符串
 * @param {string} res 返回字段|默认数据
 */
export const parseObj = (str, res) => {
  let data = {}
  if (Boolean(str) && str.startsWith('{') && str.endsWith('}')) {
    try {
      data = JSON.parse(str)
    } catch (error) {
      data = {}
    }
  }
  if (res) {
    const field = res.split('|')[0]
    const types = res.split('|')[1]
    return data[field] || types
  }
  return data
}

/**
 * parse数组
 * @param {string} str 
 */
export const parseArray = (str) => {
  let data = []
  if (Boolean(str) && str.startsWith('[') && str.endsWith(']')) {
    try {
      data = JSON.parse(str)
    } catch (error) {
      data = []
    }
  }
  return data
}

/**
 * 解析URL参数
 * @param {string} field 字段
 */
export const getUrlParams = (field) => {
  let params = {}, upper = '';
  const { search = '' } = window.location || {}
  const list = search.replace('?', '').split('&')
  list.forEach(i => {
    if (!i) return
    if (i.match('=')) {
      const [type, value] = i.replace('=', ':').split(':')
      params[type] = decodeURIComponent(value)
      upper = type
    } else if (upper) {
      const value = `${params[upper]}&${i}`
      params[upper] = decodeURIComponent(value)
    }
  })
  if (field) {
    switch (params[field]) {
      case 'undefined':
        return undefined
      case 'null':
        return null
      default:
        return params[field]
    }
  }
  return params || {}
}
