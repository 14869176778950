import React, { useState, useEffect } from 'react';
import styles from './style.module.scss';
import PageView from '@/components/PageView';
import { getUserAddressList } from '@/utils/api';
import Card from './components/Card';
import ListView from '@/components/ListView';
import { Toast } from 'antd-mobile';

function Index() {
  const [list, setList] = useState(null);

  useEffect(() => {
    getUserAddressList()
      .then(res => {
        setList(res);
        Toast.hide();
      })
      .catch(_ => {});
  }, []);

  const renderListItem = (rowData, sectionId, rowId) => {
    return <Card key={rowId} item={rowData} />;
  };

  if (!list) {
    Toast.loading('正在加载中...');
    return <div></div>;
  }

  return (
    <PageView centerComponent="我的地址">
      <ListView className={styles.listWrapper} data={list} renderRow={renderListItem} />
    </PageView>
  );
}

export default Index;
