// 验证大陆手机号
export const phoneValid = phone => /^1(3|4|5|6|7|8|9)\d{9}$/.test(phone);
// 验证URL
export const urlValid = url =>
  // eslint-disable-next-line
  /(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?/.test(
    url
  );
// 格式化订单商品描述 attrText
export const formatAttrText = attrText =>
  attrText.replace(/^(.*)_|默认-默认+$/, '');
