import React, { useState, useRef, useEffect } from 'react';
import useReactRouter from 'atv-use-react-router';
import { getInviterInfo, getMyInfo, getQunNum } from '@/utils/api';
import { Toast } from 'antd-mobile';
import { getRegionNameById } from '@/utils/helper';
import { getAuth } from '@/utils/app';
import useTitle from '@/hooks/useTitle';
import classNames from 'classnames';

import Login from '../Login';
import styles from './index.module.scss';

import locationIcon from './assets/location.png';
import open from './assets/open.png';
import icons1 from './assets/icons_1.png';
import icons2 from './assets/icons_2.png';
import icons3 from './assets/icons_3.png';
import icons4 from './assets/icons_4.png';
import icons5 from './assets/icons_5.png';
import icons6 from './assets/icons_6.png';
import icons7 from './assets/icons_7.png';
import icons8 from './assets/icons_8.png';
import icons9 from './assets/icons_9.png';
import icons10 from './assets/icons_10.png';
import icons11 from './assets/icons_11.png';
import icons12 from './assets/icons_12.png';
import headshow from './assets/head_shows.png';
import foot3 from './assets/foot_3.png';
import btn from './assets/back_btn.png';
import avatarDefault from './assets/avatar_defult.png';

// 如何升级切图
import upgradeTitle from './assets/upgrade_title.png';

// 店长故事切图
import storyTitle from './assets/story_title.png';
import story1 from './assets/story_1.png';
import story2 from './assets/story_2.png';
import story3 from './assets/story_3.png';

// 经营店长切图
import shopOwnerTitle from './assets/shop_owner_title.png';

function Index() {
  useTitle('诚邀您成为亚洲电视店长');
  const router = useReactRouter();

  const opens = useRef();
  const openes = useRef();

  const [code, setCode] = useState('');
  const [dis, setDis] = useState(false);
  const [show, setShow] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [inviterInfo, setInviterInfo] = useState({ name: '' });
  const [region, setRegion] = useState('');
  const [qunNum, setQunNum] = useState('');

  const autoFlexBody = {
    fixedBody: () => {
      const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
      document.body.style.cssText += 'position:fixed;width:100%;top:-' + scrollTop + 'px;';
    },
    looseBody: () => {
      const body = document.body;
      body.style.position = '';
      const top = body.style.top;
      document.body.scrollTop = document.documentElement.scrollTop = -parseInt(top);
      body.style.top = '';
    },
  };

  // 店长权益
  const content = [
    {
      title: '省钱',
      list: [
        { icon: icons1, name: '购物省钱', desc: '全场立减' },
        { icon: icons2, name: '专享低价', desc: '大牌优惠' },
        { icon: icons3, name: '大牌优惠', desc: '海量好货' },
        { icon: icons4, name: '大牌优惠', desc: '海量好货' },
      ],
    },
    {
      title: '赚钱',
      list: [
        { icon: icons5, name: '专属分享赚钱', desc: '最高60%推广奖' },
        { icon: icons6, name: '花粉合成', desc: '收益无限' },
        { icon: icons7, name: '拉新奖励', desc: '立即到账' },
      ],
    },
    {
      title: '专享',
      list: [{ icon: icons8, name: '专享活动', desc: '独享优惠' }],
    },
    {
      title: '服务',
      list: [
        { icon: icons9, name: '0成本创业', desc: '轻松开店不囤货' },
        { icon: icons10, name: '培训物料', desc: '推广更轻松' },
        { icon: icons11, name: '爆款群好货', desc: '每日更新' },
        { icon: icons12, name: '专属高级客服', desc: '专业贴心服务' },
      ],
    },
  ];

  // 如何升级
  const upgradeSteps = [
    '',
    '上传两张截图（截图一：显示出群主和群人数；截 图二：显示出群名称；）。',
    '上传提交后，工作人员会在24小时内为您审核，审核结果请留意当前页。',
  ];

  // 店长故事
  const count = [{ img: story1 }, { img: story2 }, { img: story3 }];

  // 精英店长
  const footer = [
    { img: require('./assets/head_1.png'), label: '滴滴司机', name: '庄刚' },
    { img: require('./assets/head_2.png'), label: '五金店老板', name: '康景轩' },
    { img: require('./assets/head_3.png'), label: '电商从业者', name: '莫小莉' },
    { img: require('./assets/head_4.png'), label: '餐厅服务员', name: '余明飞' },
    { img: require('./assets/head_5.png'), label: '收银员', name: '翟维嘉' },
    { img: require('./assets/head_6.png'), label: '电商从业者', name: '奕玉良' },
    { img: require('./assets/head_7.png'), label: '超市老板', name: '徐淑玲' },
    { img: require('./assets/head_8.png'), label: '销售', name: '葛远强' },
  ];

  useEffect(() => {
    const id = window.location.search.split('=')[1];
    setCode(id);
    if (id) {
      getInviterInfo({ params: { id } })
        .then(res => {
          const {
            name,
            User: { avatarUrl },
          } = res || {};
          setInviterInfo({ name, avatar: avatarUrl });
        })
        .catch(_ => {});
    }
  }, []);

  useEffect(() => {
    const partner = localStorage.getItem('QUBIBI-TOKEN');
    if (partner) {
      getUserInfo();
      getQunNum().then(res => {
        setQunNum(res.qunNum);
      });
    }
  }, []);

  const renderList = list => {
    return list.map((i, k) => {
      return (
        <div key={k} className={styles.listTD}>
          <div>
            <img src={i.icon} alt="" />
          </div>
          <p>{i.name}</p>
          <p>{i.desc}</p>
        </div>
      );
    });
  };
  const renderShow = () => {
    return (
      <div ref={opens} className={`${show ? styles.openshow : styles.openhide} ${styles.listBody}`}>
        {!show && (
          <div className={styles.listTR} style={show ? {} : { justifyContent: 'center' }}>
            {renderList(content[0].list)}
          </div>
        )}
        <p
          className={styles.show}
          onClick={() => {
            if (dis) return;
            setDis(true);
            setTimeout(() => {
              opens.current.style.height = !show ? '5.3rem' : '1.7rem';
              opens.current.className = styles.listBody;
              openes.current.style.height = !show ? '0.88rem' : '1.4rem';
              openes.current.className = styles.headshow;
              setDis(false);
            }, 285);
            setShow(!show);
          }}
          style={show ? { marginBottom: '0.28rem' } : { marginTop: '0.28rem' }}
        >
          {show ? '收起' : '查看'}全部店长权益
        </p>
        {show &&
          content.map((i, k) => {
            return (
              <div key={k} className={styles.listTR}>
                <div className={styles.title}>{i.title}</div>
                {renderList(i.list)}
              </div>
            );
          })}
      </div>
    );
  };

  const userLevelLabel = level => {
    switch (level) {
      case 23:
        return '店长';
      case 31:
        return '连锁店长';
      case 32:
        return '合伙人';
      default:
    }
  };

  // 判断是否是店长以上的身份
  const isHigherLevel = async () => {
    let userLevel = false;
    await getMyInfo()
      .then(res => {
        const { level } = res || {};
        if (level >= 30 || level === 23) {
          userLevel = level;
        }
      })
      .catch(_ => {});
    return userLevel;
  };

  const isOk = async () => {
    setIsLogin(false);
    const level = await isHigherLevel();
    if (level) {
      Toast.info(`您已经是${userLevelLabel(level)}身份, 不需要升级！`);
      localStorage.removeItem('QUBIBI-TOKEN');
      setTimeout(() => {
        window.location.reload();
      }, 3 * 1000);
    } else {
      window.location.reload();
    }
  };

  const isCancel = () => {
    setIsLogin(false);
    autoFlexBody.looseBody();
  };

  const handleSwitch = async () => {
    if (!getAuth()) {
      setIsLogin(true);
      autoFlexBody.fixedBody();
    } else {
      const level = await isHigherLevel();
      if (level) {
        Toast.info(`您已经是${userLevelLabel(level)}身份, 不需要升级！`);
      } else {
        router.history.push(`/offline?id=${code}`);
      }
    }
  };

  // 获取被邀请人的用户信息
  const getUserInfo = () => {
    getMyInfo()
      .then(res => {
        const { regionId } = res || {};
        const region = getRegionNameById(regionId);
        setRegion(region);
      })
      .catch(_ => {});
  };

  return (
    <div className={styles.equityBody}>
      <div className={styles.headerCounter}>
        {region && (
          <div className={styles.regionRow}>
            <img src={locationIcon} className={styles.regionIcon} alt="location" />
            <span className={styles.text}>所属区域 “{region}”</span>
          </div>
        )}
        <div className={styles.headerOpen}>
          <img className={styles.open} alt="通知" src={open} />
          &nbsp;&nbsp;&nbsp;&nbsp;
          <img className={styles.avatar} alt="头像" src={inviterInfo.avatar || avatarDefault} />
          <span className={styles.openText}>
            <span className={styles.name}>{inviterInfo.name}</span>邀请你成为尊贵的店长
          </span>
        </div>
        <div className={styles.titleText}>升级店长 轻松躺赢</div>
      </div>
      <div
        className={`${show ? styles.openshows : styles.openhides} ${styles.headshow}`}
        style={{ backgroundImage: `url(${headshow})` }}
        ref={openes}
      >
        店长超级权益
        <p>购物预计可省16666/年 分享最高可赚40%的奖金</p>
      </div>
      {renderShow()}
      <div className={styles.counterContainer}>
        {/* 如何升级 */}
        <div className={classNames(styles.counter, styles.upgrade)}>
          <img className={styles.titleImg} alt="如何升级" src={upgradeTitle} />
          <div className={styles.main}>
            {upgradeSteps.map((item, index) => (
              <div key={index} className={styles.stepRow}>
                <div className={styles.stepNo}>{index + 1}</div>
                <div className={styles.stepContent}>
                  {index === 0 ? (
                    <span>
                      新建或已有一个超50人的微信推广群，群名称修改为{' '}
                      <span className={qunNum && styles.qunName}>
                        “亚洲电视内购福利群{qunNum || '+编号'}”
                      </span>
                      {!qunNum && (
                        <span className={styles.loginBtn} onClick={() => setIsLogin(true)}>
                          {' '}
                          点击登录查看群编号
                        </span>
                      )}
                    </span>
                  ) : (
                    item
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* 关于亚洲电视 */}
        <div className={styles.counter}>
          <img className={styles.counterImg} alt="关于亚洲电视" src={foot3} />
        </div>
        {/* 店长故事 */}
        <div className={styles.counter}>
          <img src={storyTitle} className={styles.titleImg} alt="店长故事" />
        </div>
        <div className={styles.count}>
          {count.map((item, index) => (
            <div key={index} className={styles.countItem}>
              <img alt={item.name} src={item.img} />
            </div>
          ))}
        </div>
        {/* 精英店长 */}
        <div className={styles.counter}>
          <img src={shopOwnerTitle} className={styles.titleImg} alt="精英店长" />
          <div className={styles.footer}>
            {footer.map((item, index) => (
              <div key={index} className={styles.footerItem}>
                <div>
                  <img alt={item.name} src={item.img} />
                </div>
                <p className={styles.footerLabel}>{item.label}</p>
                <p className={styles.footerName}>{item.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.btn} onClick={handleSwitch}>
        <img className={styles.img} alt="提交" src={btn} />
        <div className={styles.handle}>
          <span className={styles.handleText}>提交资料升级店长赚钱</span>
        </div>
      </div>
      <Login isShow={isLogin} isOk={isOk} isCancel={isCancel} />
    </div>
  );
}

export default Index;
