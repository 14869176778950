import React from 'react';
import useTitle from '@/hooks/useTitle';
import Divider from '@/components/Divider';
import styles from './index.module.scss';

function GongLueC() {
  useTitle('如何分享商品&朋友圈发品技巧');

  return (
    <div className={styles.container}>
      <div className={styles.title}>一、如何分享商品</div>
      <div className={styles.subTitle}>路径一：</div>
      <div>1、进入亚洲电视小程序</div>
      <div>2、点击商品详情</div>
      <div>3、进入点击左下角“好物推荐”</div>
      <div>4、选择需要分享的群或好友</div>
      <div>5、点“发送”</div>
      <Divider height={'0.26rem'} />
      <div className={styles.imgContainer}>
        <img className={styles.img} src={require('./assets/1.png')} alt="" />
        <div className={styles.imgDivider}></div>
        <img className={styles.img} src={require('./assets/2.png')} alt="" />
      </div>
      <Divider height={'0.19rem'} />
      <div className={styles.subTitle}>路径二：</div>
      <div>1、首页商品页面</div>
      <div>2、直接点“好物推荐” </div>
      <div>3、选择需要分享的群或好友</div>
      <div>4、点“发送”</div>
      <Divider height={'0.26rem'} />
      <div className={styles.imgContainer}>
        <img className={styles.img} src={require('./assets/3.png')} alt="" />
        <div className={styles.imgDivider}></div>
        <img className={styles.img} src={require('./assets/4.png')} alt="" />
      </div>
      <Divider height={'0.19rem'} />
      <div className={styles.title}>二、如何选品</div>
      <div>1、<span className={styles.subTitle}>质量大于数量</span>，选好产品推荐给好友比无节制大量发的效果更好。</div>
      <div>2、<span className={styles.subTitle}>分享渠道：</span>除了微信群，还可以在朋友圈、QQ群、微博等其它地方推广商品，只要用户点击链接下单购买您就会获得佣金。</div>
      <div>3、<span className={styles.subTitle}>注意发送时间段：</span></div>
      <div>比如上班族用户多的群，购物高峰期分别为：早上7:30-9:30，中午11:30-14:00，下午5:00-7:00，晚上9:00-11:00，也就是上下班时间段、午休时间段、睡前时间段。</div>
      <div>4、<span className={styles.subTitle}>用好@全员的群公告功能，</span>发布一些爆品或促销活动信息，让大家觉得被@时一定是有特别好的优惠，这样会不定时激活那些屏蔽消息的用户。</div>
      <Divider height={'0.4rem'} />
      <div className={styles.title}>三、如何发朋友圈</div>
      <div>1、运用好小程序【今日热榜】的产品</div>
      <div>2、点击“复制文字”，“保存图片”</div>
      <div>3、点右上角“分享送福利”</div>
      <div>4、选择发送的群或好友，即可完成分享。</div>
      <Divider height={'0.21rem'} />
      <div className={styles.imgContainer}>
        <img className={styles.img} src={require('./assets/1.png')} alt="" />
      </div>
      <Divider height={'0.28rem'} />
    </div>
  );
}

export default GongLueC;
