import React from 'react';
import { Modal } from 'antd-mobile';
import Button from '@/components/Button';
import styles from './index.module.scss';

function LingYuanGouModal({
  visible,
  handleJump,
  handleVisible,
}) {
  const btnStyle = {
    backgroundColor: '#FF2C2C',
    width: '2rem',
    height: '0.45rem',
    borderRadius: '0.07rem',
    color: '#fff',
    fontSize: '0.19rem',
  };

  return (
    <div>
      <Modal
        visible={visible}
        onClose={() => handleVisible(false)}
        transparent
        wrapClassName={styles.modalWrapper}
      >
        <div className={styles.modalContainer}>
          <span className={styles.modalTitle}>对不起！</span>
          <span className={styles.modalContent}>【新人0元购】是新人专属特权！您已经是老用户，暂时无法参与！</span>
          <Button onClick={handleJump} style={btnStyle}>选购其他超值好物</Button>
        </div>
      </Modal>
    </div>
  );
}

export default LingYuanGouModal;
