import React from 'react';
import PropTypes from 'prop-types';

function Component({ height, color, autoTrans }) {
  return (
    <div style={{ height, backgroundColor: color }}>
      <span style={{ opacity: 0 }}>分割</span>
    </div>
  );
}
Component.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  color: PropTypes.string,
  autoTrans: PropTypes.bool,
};

Component.defaultProps = {
  color: 'transparent',
  autoTrans: true,
};

export default Component;
