/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useLayoutEffect } from 'react';
import { ImagePicker, Toast, InputItem } from 'antd-mobile';
import useReactRouter from 'atv-use-react-router';
import useUpload from 'atv-use-alioss-upload';
import { getUploadToken, getApplication, applyUpgrade } from '@/utils/api';
import { getApplicationCode } from '@/utils/helper';
import { useUpdateEffect } from '@/utils/hooks';
import styles from './index.module.scss';
import SuccessBg from './assets/success.png';

function Index() {
  const [userName, setUserName] = useState('');
  const [wechatName, setWechatName] = useState('');
  const [phonenum, setPhonenum] = useState('');
  const [res, setRes] = useState({});
  const [example, setExample] = useState('');
  const [preview, setPreview] = useState('');
  const [success, setSuccess] = useState('');
  const [files, setFiles] = useState([]);
  const [file, setFile] = useState(null);
  const [data, setDate] = useState({});
  const [imgId, setImgId] = useState('');
  const [srcTest, setSrcTest] = useState([]);
  const { imgUrl, loading } = useUpload(file, data, `AF-H5/${imgId}`);
  const router = useReactRouter();

  const autoFlexBody = {
    fixedBody: () => {
      const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
      document.body.style.cssText += 'position:fixed;width:100%;top:-' + scrollTop + 'px;';
    },
    looseBody: () => {
      const body = document.body;
      body.style.position = '';
      const top = body.style.top;
      document.body.scrollTop = document.documentElement.scrollTop = -parseInt(top);
      body.style.top = '';
    },
  };

  const openExample = () => {
    setExample(require('./assets/4.png'));
    autoFlexBody.fixedBody();
  };

  const onChange = (f, i) => {
    // console.log(f, i, srcTest);
    setFiles(f);
    if (typeof i === 'number') {
      srcTest.splice(i, 1);
      return;
    }
    f.length > 0 && setUpload(f);
  };

  const setUpload = f => {
    // console.log('触发')
    const url = dataURLtoFile(f[f.length - 1].url);
    setImgId(Date.now() + Math.round(Math.random() * 10000) + url.name);
    setFile(url);
  };

  const dataURLtoFile = (dataurl, filename = 'file') => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const suffix = mime.split('/')[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], `${filename}.${suffix}`, {
      type: mime,
    });
  };

  const onImageClick = (index, fs) => {
    const { url } = fs[index];
    setPreview(url);
    autoFlexBody.fixedBody();
  };

  const upLoad = () => {
    // console.log(srcTest, userName, wechatName);
    if (srcTest.length === 0) {
      Toast.show('请至少上传一张截图');
      return;
    }
    if (res.isHide === 4) {
      Toast.show(getApplicationCode(res.isHide));
      return;
    }
    applyUpgrade({
      body: {
        // partnerId,
        userName,
        phonenum,
        wechatName,
        upLevel: 23,
        images: srcTest,
      },
    })
      .then(res => {
        setSuccess(true);
        setRes({});
      })
      .catch(() => {
        Toast.show('已存在升级申请');
      });
  };

  const arr = imgUrl => {
    setSrcTest(historySrc => [...historySrc, imgUrl]);
  };

  const getToken = () => {
    getUploadToken().then(res => setDate(res));
  };

  useUpdateEffect(() => {
    // console.log(loading);
    !loading && arr(imgUrl);
    // eslint-disable-next-line
  }, [loading]);

  useLayoutEffect(() => {
    getApplication().then(res => {
      // alert(JSON.stringify(res))
      res && setRes(res);
      //   if (res && res.status === 4) {
      //     setSuccess(true);
      //   }
    });
  }, []);

  useEffect(() => {
    getToken();
  }, []);

  useEffect(() => {
    const partner = localStorage.getItem('QUBIBI-TOKEN');
    if (!partner) {
      router.history.push('login');
    } else {
      const phonenum = JSON.parse(localStorage.getItem('QUBIBI-TOKEN')).phonenum;
      setPhonenum(phonenum);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.container}>
      <p>认真填写及按要求上传图片，确保信息无误快速通过审核</p>
      <div className={styles.headerContainer}>
        <div className={styles.headerItem}>
          <p>昵&nbsp;&nbsp;&nbsp;称</p>
          <div className={styles.input}>
            <InputItem placeholder="请 输 入 昵 称" onChange={e => setUserName(e)} />
          </div>
        </div>
        <div className={styles.headerItem}>
          <p>微信号</p>
          <div className={styles.input}>
            <InputItem placeholder="请 输 入 微 信 号" onChange={e => setWechatName(e)} />
          </div>
        </div>
        <div className={styles.headerItem}>
          <p>手机号</p>
          <p className={styles.phone}>{phonenum}</p>
        </div>
      </div>
      <div className={styles.counterContainer}>
        <h3>上传审核截图</h3>
        <p>
          新建或已有一个50人微信推广群，群名称为：亚洲电视福利群，展示出群名称、群主和群人数{' '}
          <span onClick={openExample}>查看示例</span>
        </p>
        <ImagePicker
          length="3"
          files={files}
          onChange={(files, type, index) => onChange(files, index)}
          onImageClick={(index, fs) => onImageClick(index, fs)}
          selectable={files.length < 3}
          // accept="image/gif,image/jpeg,image/jpg,image/png"
        />
      </div>
      <button className={styles.btn} onClick={upLoad}>
        提交资料
      </button>
      {/* 预览图 */}
      <div
        className={!example ? styles.exampleHide : styles.exampleShow}
        onClick={() => {
          setExample('');
          autoFlexBody.looseBody();
        }}
      >
        {!!example && <img src={example} alt="示例图" />}
      </div>
      <div className={!preview ? styles.previewHide : styles.previewShow}>
        <img
          alt="预览图"
          src={preview}
          onClick={() => {
            setPreview('');
            autoFlexBody.looseBody();
          }}
        />
      </div>
      <div className={!success ? styles.successContainerHide : styles.successContainerShow}>
        <img alt="审核中" src={SuccessBg} />
        <h2>审核中</h2>
        <p className={styles.text}>预计审核时间1-3个工作日</p>
        <p className={styles.text}>审核结果会以当前页面通知，请耐心等待</p>
      </div>
    </div>
  );
}

export default Index;
