import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import head1 from './assets/head_1.png';
import head2 from './assets/head_2.png';
import head3 from './assets/head_3.png';
import head4 from './assets/head_4.png';
import head5 from './assets/head_5.png';
import head6 from './assets/head_6.png';
import head7 from './assets/head_7.png';
import head8 from './assets/head_8.png';
import Titles from '../Titles';

function EliteList ({ info }) {
  const [card, setCard] = useState({});
  const dataArr = [
    {
      headUrl: head1,
      position: '滴滴司机',
      name: '庄 刚',
    },
    {
      headUrl: head2,
      position: '五金店老板',
      name: '康锦轩',
    },
    {
      headUrl: head3,
      position: '电商从业者',
      name: '莫小莉',
    },
    {
      headUrl: head4,
      position: '餐厅服务员',
      name: '余明飞',
    },
    {
      headUrl: head5,
      position: '收银员',
      name: '翟维嘉',
    },
    {
      headUrl: head6,
      position: '电商从业者',
      name: '奕玉良',
    },
    {
      headUrl: head7,
      position: '超市老板',
      name: '徐淑玲',
    },
    {
      headUrl: head8,
      position: '销售',
      name: '葛远强',
    },
  ];

  const levelArr = [
    {
      level: [11],
      title: '精英店长',
      types: false,
      posiColor: '#222121',
      nameColor: '#484847',
      borderColor: '#FFEEA8',
    },
    {
      level: [21],
      title: '精英店长',
      types: false,
      posiColor: '#7D4B2D',
      nameColor: '#7D4B2D',
      borderColor: '#F5DCB9',
    },
    {
      level: [22, 23, 31, 32],
      title: '精英导师',
      types: true,
      posiColor: '#866C54',
      nameColor: '#C2AA95',
      borderColor: '#F5DCB9',
    },
  ];

  useEffect(() => {
    setCard(levelArr.find(i => i.level.includes(info.level)));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderList = () => {
    const list = dataArr.map((i, k) => {
      return (
        <div key={k} className={styles.elitelist}>
          <div>
            <img
              src={i.headUrl}
              style={{ border: `0.01rem solid ${card.borderColor}` }}
              alt=""
            />
          </div>
          <p
            className={styles.posi}
            style={{ color: card.posiColor }}
          >
            {i.position}
          </p>
          <p
            className={styles.name}
            style={{ color: card.nameColor }}
          >
            {i.name}
          </p>
        </div>
      );
    });
    return <div className={styles.elitebox}>{list}</div>;
  };

  return (
    <>
      <Titles
        title={card.title}
        type={card.types}
        color={info.level === 11 ? '#31302F' : '#7F4F32'}
      />
      {renderList()}
    </>
  );
};

export default EliteList;
