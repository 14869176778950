import React from 'react';
import { TextareaItem } from 'antd-mobile';

class Input extends React.Component {
  componentDidMount() {
    this.value = this.props.defaultValue;
  }

  handleChange = text => {
    this.value = text.trim();
  };

  render() {
    const { ...restProps } = this.props;
    return <TextareaItem onChange={this.handleChange} {...restProps} />;
  }
}

export default Input;
