import React, { useState, useEffect, useRef } from 'react';
import styles from './style.module.scss';
import SectionItem from './components/SectionItem';
import useReactRouter from 'atv-use-react-router';
import { Toast, Modal, Picker, Icon, Switch } from 'antd-mobile';
import { newAddress, updateAddress, deleteAddress } from '@/utils/api';
import { getRegionNameById } from '@/utils/helper';
import { phoneValid } from '@/lib/regex.js';
import PageView from '@/components/PageView';
import Button from '@/components/Button';
import TextInput from '@/components/TextInput';
import TextareaInput from '@/components/TextareaInput';

function Index() {
  const [id, setId] = useState(0);
  const [regionId, setRegionId] = useState('');
  const [isDefault, setIsDefault] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [regions, setRegions] = useState({});

  const nameInputRef = useRef(null);
  const phonenumInputRef = useRef(null);
  const addressInputRef = useRef(null);

  const router = useReactRouter();
  const Alert = Modal.alert;

  const { name, phonenum, address, isInit } = router.match.params;

  // 格式化地区信息格式
  useEffect(() => {
    const regionsStr = localStorage.getItem('REGIONS');
    const regions =
      typeof regionsStr === 'string' &&
      regionsStr
        .replace(/Children/g, 'children')
        .replace(/id/g, 'value')
        .replace(/name/g, 'label');
    const regionsObj = JSON.parse(regions);
    setRegions(regionsObj);
  }, []);

  useEffect(() => {
    const { id, isEdit } = router.match.params;

    // !addressList.length && setIsDefault(1);
    if (isEdit) {
      const { regionId, isDefault } = router.match.params;

      setId(id);
      setRegionId(regionId);
      setIsDefault(isDefault);
      setIsEdit(isEdit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isEmptyString = val => {
    if (typeof val === 'string') {
      return !val.trim().length;
    }
    return Toast.info('请填入正确的信息');
  };

  const getStrLength = str => {
    if (typeof str === 'string') {
      return str.trim().length;
    }
    return Toast.info('请填入正确的信息');
  };

  const handleSave = async () => {
    const nameInput = nameInputRef.current.value;
    const phonenumInput = phonenumInputRef.current.value;
    const addressInput = addressInputRef.current.value;
    const bodyParams = {
      name: nameInput,
      phonenum: phonenumInput,
      address: addressInput,
      regionId,
      isDefault,
    };

    if (!nameInput || isEmptyString(nameInput)) {
      return Toast.info('收货人不能为空');
    }
    if (getStrLength(nameInput) < 2 || getStrLength(nameInput) > 25) {
      return Toast.info('收货人长度为2-25个字符');
    }
    if (!phonenumInput || isEmptyString(phonenumInput)) {
      return Toast.info('手机号不能为空');
    }
    if (!regionId) {
      return Toast.info('请选择正确的地区');
    }
    if (!addressInput || isEmptyString(addressInput)) {
      return Toast.info('详细地址不能为空');
    }
    if (!phoneValid(phonenumInput)) {
      return Toast.info('请填写正确的手机号码');
    }

    // await setSaveBtnLoading(true);
    isEdit ? await onUpdateAddress(bodyParams) : await onNewAddress(bodyParams);
    // setSaveBtnLoading(false);
  };

  const refreshList = () => {
    router.match.params.getAddressListCallback(true);
  };

  const goBack = () => {
    router.history.goBack();
  };

  const onNewAddress = async body => {
    await newAddress({ body })
      .then(() => {
        refreshList();
        goBack();
      })
      .catch(() => {});
  };

  const onUpdateAddress = async body => {
    await updateAddress({ params: { id }, body })
      .then(() => {
        refreshList();
        goBack();
      })
      .catch(() => {});
  };

  const onDeleteAddress = async () => {
    Toast.loading('删除中...');
    await deleteAddress({ params: { id } })
      .then(() => {
        refreshList();
        goBack();
        Toast.hide();
      })
      .catch(() => {});
  };

  const handleSelectRegion = val => {
    setRegionId(val[2]);
  };

  const RegionItem = props => {
    return (
      <SectionItem label="选择地区">
        <div className={styles.regionRow} onClick={props.onClick}>
          {!regionId ? (
            <div className={`${styles.input} ${styles.placeholderText}`}>
              省-市-区
            </div>
          ) : (
            <span className={[styles.input]}>
              {getRegionNameById(regionId, '-')}
            </span>
          )}
          {props.extra}
        </div>
      </SectionItem>
    );
  };

  const renderSectionList = () => {
    return (
      <div style={{ backgroundColor: '#fff' }}>
        {/* 收货人 */}
        <SectionItem label="收货人">
          <TextInput
            ref={nameInputRef}
            className={styles.input}
            defaultValue={name}
            placeholder="请填写收货人"
          />
        </SectionItem>
        {/* 手机号 */}
        <SectionItem label="手机号">
          <TextInput
            ref={phonenumInputRef}
            placeholder="请填写您的手机号"
            defaultValue={phonenum}
            className={styles.input}
            type="phone"
          />
        </SectionItem>
        {/* 选择地区 */}
        <Picker
          data={regions.children}
          title="选择地区"
          extra={<Icon type="right" className={styles.iconArrow} />}
          onOk={handleSelectRegion}
        >
          <RegionItem />
        </Picker>
        {/* 详细地址 */}
        <SectionItem label="详细地址" style={{ borderBottomWidth: 0 }}>
          <TextareaInput
            ref={addressInputRef}
            placeholder="请填写详细地址"
            defaultValue={address}
            className={styles.input}
            rows={4}
          />
        </SectionItem>
      </div>
    );
  };

  const handleSwitchDefault = boo => {
    // if (!addressList.length) {
    //   return Toast.info('当前地址只能为默认地址');
    // }
    setIsDefault(boo ? 1 : 0);
  };

  const renderSetDefaultAddress = () => {
    return (
      <div className={styles.defaultAddressSetting}>
        <span className={styles.defaultAddressSettingLabel}>
          设置为默认地址
        </span>
        <Switch
          className={styles.switch}
          checked={!!isDefault}
          onChange={e => handleSwitchDefault(e)}
        />
      </div>
    );
  };

  const showDeleteAlert = () => {
    Alert.alert('提示', '确认删除当前地址吗？', [
      {
        text: '取消',
        style: 'cancel',
      },
      {
        text: '确认',
        onPress: () => {
          onDeleteAddress();
        },
      },
    ]);
  };

  const renderTopBarRight = () => {
    return (
      isEdit && (
        <div onClick={showDeleteAlert}>
          <span className={styles.rightComponentText}>删除</span>
        </div>
      )
    );
  };

  return (
    <PageView
      centerComponent={isEdit ? '编辑地址' : '新增地址'}
      rightComponent={renderTopBarRight()}
    >
      {renderSectionList()}
      {renderSetDefaultAddress()}
      <div className={styles.saveBtnWrapper}>
        <Button
          style={{
            width: '4.6rem',
            height: '0.59rem',
            borderRadius: '0.29rem',
          }}
          isGradient
          onClick={handleSave}
        >
          <span className={styles.saveBtnText}>
            {isInit ? '保存并使用' : '保存'}
          </span>
        </Button>
      </div>
    </PageView>
  );
}

export default Index;
