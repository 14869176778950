import React, { useEffect, useState } from 'react';
import useReactRouter from 'atv-use-react-router';
import { Steps, Toast, Icon } from 'antd-mobile';
import classNames from 'classnames';
import { KFWX, H5_PREFIX } from '@/utils/constants';
import { jumpTo, isRN, isMP } from '@/utils/helper';
import useTitle from '@/hooks/useTitle';
import useClipboard from 'react-use-clipboard';
import useDebugPos, { BOTTOM_LEFT } from '@/hooks/useDebugPos';
import styles from './index.module.scss';

const Step = Steps.Step;
const itemList = [
  {
    id: 1,
    title: '建群技巧',
    path: 'GongLueA',
  },
  {
    id: 2,
    title: '如何邀请好友',
    path: 'GongLueB',
  },
  {
    id: 3,
    title: '如何分享&朋友圈发品技巧',
    path: 'GongLueC',
  },
  {
    id: 4,
    title: '群日常运营技巧',
    path: 'GongLueD',
  },
  {
    id: 5,
    title: '微信朋友圈运营技巧',
    path: 'GongLueE',
  },
];

function GongLue() {
  const [tabIndex, setTabIndex] = useState(0);
  const [code, setCode] = useState('');
  // 复制方法
  const [, setCopied] = useClipboard(code, {
    successDuration: 1000,
  });
  const router = useReactRouter();

  // 复制微信号
  const copyWx = () => {
    setCopied();
    Toast.success('成功复制微信号');
  };

  const stepContent = [
    {
      title: '成长任务1：获取店长权益',
      description: (
        <div>
          <div>建一个大于50人的微信群， 添加亚洲电视客服微信申请开通店长权限。</div>
          <div>不会建群的小伙伴在【赚钱攻略】中查看【亚洲电视建群技巧】</div>
          <div>
            客服微信号：{KFWX}&nbsp;
            <span className={styles.copyText} onClick={copyWx}>复制微信号</span>
          </div>
        </div>
      ),
    },
    {
      title: '成长任务2：邀请更多小伙伴的加入',
      description: (
        <div>
          <div>更多人的加入会让你的群更加的活跃，成交的概率越大。</div>
          <div>可以在【赚钱攻略】中查看【如何邀请好友】</div>
        </div>
      ),
    },
    {
      title: '成长任务3：分享好产品赚钱',
      description: '可以在【赚钱攻略】中查看【如何分享&朋友圈发品技巧】',
    },
    {
      title: '成长任务4：做好群维护持续赚钱',
      description: '可以在【赚钱攻略】中查看【群日常运营技巧】',
    },
    {
      title: '成长任务5：推荐他人成为店长',
      description: (
        <div>
          <div>只要是您的推广会员，他成为店长后，您将自动获得他的部分推广收益。</div>
          <div>注：不影响您推荐的店长的自身收益。</div>
          <div>可以在【赚钱攻略】中查看【微信朋友圈运营技巧】</div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    setCode(KFWX);
  }, []);

  // 把debug彩蛋移到左下角, 避免点击失败
  useDebugPos(BOTTOM_LEFT);

  useTitle('攻略');

  const renderDivider = () => {
    return (
      <div style={{ height: '0.45rem', width: '100vw' }}></div>
    );
  };

  const handleTabSwitch = (i) => {
    setTabIndex(i);
  };

  const renderTabItem = (index, title) => {
    const isActive = index === tabIndex;
    return (
      <div onClick={() => handleTabSwitch(index)} className={classNames(styles.footerItem, isActive && styles.tabActive)}>
        {title}
        <div className={classNames(isActive && styles.activeUnderline)}></div>
      </div>
    );
  };

  const renderTabs = () => {
    return (
      <div className={styles.tabsContainer}>
        {renderTabItem(0, '新手秘籍')}
        {renderTabItem(1, '赚钱攻略')}
      </div>
    );
  };

  const renderStepIcon = <div></div>;

  const handleJump = (path) => {
    const isBrowser = !isRN() && !isMP();
    if (isBrowser) {
      router.history.push(path);
    } else {
      jumpTo('webview', {
        path: `${H5_PREFIX}/${path}`
      });
    }
  };

  const renderListItem = (item) => {
    const { title, id, path } = item;
    return (
      <div onClick={() => handleJump(path)} key={id}>
        <div style={{ width: '100vw', height: '0.13rem' }}></div>
        <div className={styles.listItem}>
          <div className={styles.listItemTitle}>{title}</div>
          <Icon type="right" size={'0.13rem'} color={'#999999'} />
        </div>
      </div>
    );
  };

  const renderLeftTab = () => {
    const isActive = tabIndex === 0;
    return (
      <div style={{ height: '100vh', backgroundColor: '#fff', display: isActive ? 'block' : 'none' }}>
        {renderDivider()}
        <div className={styles.stepContainer}>
          <Steps size="small" current={3}>
            {stepContent.map(({ title, description }, index) => {
              return <Step icon={renderStepIcon} key={index} title={title} description={description} />;
            })}
          </Steps>
        </div>
      </div>
    );
  };

  const renderRightTab = () => {
    const isActive = tabIndex === 1;
    return (
      <div style={{ display: isActive ? 'block' : 'none' }}>
        {renderDivider()}
        {
          itemList.map((item) => renderListItem(item))
        }
      </div>
    );
  };

  return (
    <div>
      {renderTabs()}
      {renderLeftTab()}
      {renderRightTab()}
    </div>
  );
}

export default GongLue;
