import React, { useEffect, useState } from 'react'
import { Icon } from 'antd-mobile'
import { getHomeFan } from '@/utils/api'
import { jumpTo, isRN } from '@/utils/helper'
import icons1 from './assets/icons_1.png'
import icons2 from './assets/icons_2.png'
import icons3 from './assets/icons_3.png'
import icons4 from './assets/icons_4.png'
import icons5 from './assets/icons_5.png'
import icons6 from './assets/icons_6.png'
import icons7 from './assets/icons_7.png'
import icons8 from './assets/icons_8.png'
import icons10 from './assets/icons_10.png'
import styles from './index.module.scss'

function FansData ({ info = {} }) {
  const [data, setData] = useState({})

  useEffect(() => {
    getHomeFan().then(res => setData(res))
  }, [])

  const renderList = () => {
    const levelArr = [
      {
        level: [21, 22, 23, 31, 32],
        title: '会员',
        data: data.directLevel1 || 0
      },
      {
        level: [31, 32],
        title: '店长',
        data: data.directLevel2 || 0
      },
      {
        level: [31, 32],
        title: '合伙人',
        data: data.directLevel3 || 0
      },
      {
        level: [21, 22, 23],
        title: '直属店长',
        data: data.directLevel2 || 0
      },
      {
        level: [21, 22, 23, 31, 32],
        title: '全部粉丝',
        data: data.direct || 0
      }
    ]
    const list = levelArr.filter(i => i.level.includes(info.level))
    return list.map((i, k) => {
      return (
        <div key={k} className={styles.lists}>
          <p className={styles.texts}>{i.data}</p>
          <p className={styles.texts}>{i.title}</p>
        </div>
      )
    })
  }

  const renderTypeList = () => {
    const list = [
      {
        title: '粉丝订单',
        rightSkip: 'userFansOrderList',
        types: [
          {
            label: '待付款',
            icon: icons1,
            type: 1
          },
          {
            label: '待发货',
            icon: icons2,
            type: 2
          },
          {
            label: '待收货',
            icon: icons3,
            type: 3
          },
          {
            label: '已完成',
            icon: icons4,
            type: 4
          }
        ]
      },
      {
        title: '我的直播',
        types: [
          {
            label: '创建直播',
            icon: icons5,
            skip: 'liveCreate'
          },
          {
            label: '我的预告',
            icon: icons6,
            skip: 'liveRecordList',
            type: 1
          },
          {
            label: '直播记录',
            icon: icons7,
            skip: 'liveRecordList',
            type: 0
          }
        ]
      },
      {
        title: '店铺管理',
        types: [
          {
            label: '我的宝贝',
            icon: icons8,
            skip: 'liveMyShop'
          },
          {
            label: '我的店铺',
            icon: icons10,
            skip: 'liveAnchorInfo'
          }
        ]
      }
    ]
    return list.map((i, k) => {
      const flag = [22, 23, 31, 32].includes(info.level)
      return ((isRN() && flag) || i.rightSkip) && (
        <div key={k} className={styles.fans}>
          <div className={styles.header}>
            <span>{i.title}</span>
            {i.rightSkip && (
              <span
                onClick={() => {
                  jumpTo(i.rightSkip, { initialPage: 0 })
                }}
              >
                全部
                <Icon type="right" size="xxs" />
              </span>
            )}
          </div>
          <div className={styles.contents}>
            {i.types.map((item, key) => {
              return (
                <div
                  key={key}
                  className={styles.typeList}
                  onClick={() => {
                    const { type, skip } = item
                    if (i.rightSkip) {
                      jumpTo(i.rightSkip, { initialPage: type })
                    } else if (skip) {
                      jumpTo(skip, type ? { isNotice: type } : {})
                    }
                  }}
                >
                  <div>
                    <img src={item.icon} alt="" />
                  </div>
                  <p>{item.label}</p>
                </div>
              )
            })}
          </div>
        </div>
      )
    })
  }

  return (
    <>
      <div className={styles.fans}>
        <div className={styles.header}>
          <span>我的粉丝</span>
          <span onClick={() => jumpTo('userFans')}>
            更多
            <Icon type="right" size="xxs" />
          </span>
        </div>
        <div className={styles.content}>
          {renderList()}
        </div>
      </div>
      {renderTypeList()}
    </>
  )
}

export default FansData
