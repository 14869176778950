import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'antd-mobile';
import useReactRouter from 'atv-use-react-router';
import { mergeWith } from 'lodash';
import styles from './index.module.scss';

function Component({
  headerObj,
  centerComponent,
  rightComponent,
  isTransparent,
  isWhite,
  isBack,
  rightClick,
}) {
  const router = useReactRouter();

  const handleBack = () => {
    router.history.goBack();
  };

  const defaultLeftComponent = (
    <Icon type="left" color={isWhite ? '#fff' : '#141115'} />
  );
  const defaultHeaderObj = {
    style: {
      color: isWhite ? '#fff' : '#000',
      background: isTransparent ? 'transparent' : '#fff',
    },
  };
  const headerConfig = mergeWith(defaultHeaderObj, headerObj);
  return (
    <div className={styles.container} {...headerConfig}>
      {isBack && (
        <span onClick={() => handleBack()} className={styles.leftIcon}>
          {defaultLeftComponent}
        </span>
      )}
      <div className={styles.center}>{centerComponent}</div>
      <span onClick={rightClick} className={styles.IconRight}>
        {rightComponent}
      </span>
    </div>
  );
}

Component.propTypes = {
  headerObj: PropTypes.object,
  headerHide: PropTypes.bool,
  isBack: PropTypes.bool,
  isTransparent: PropTypes.bool,
  isWhite: PropTypes.bool,
};
Component.defaultProps = {
  headerObj: {},
  headerHide: false,
  isBack: true,
  centerComponent: '亚洲电视',
  isTransparent: false,
  isWhite: false,
};

export default Component;
