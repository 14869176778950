import React from 'react';
import styles from './index.module.scss';
function User(props) {
  return (
    <div className={styles.fullScreen}>
      <div className={styles.container}>
        <h1>亚洲电视用户注册协议及隐私政策</h1>
        <p>
          &emsp;&emsp;在您注册成为&ldquo;亚洲电视&rdquo;用户的过程中,您需要完成&ldquo;亚洲电视&rdquo;平台的注册流程并通过点击同意的形式在线签署《用户使用协议及隐私权政策》。&ldquo;亚洲电视&rdquo;在此特别提醒,请您务必审阅阅读签署协议的全部条款,
          特别是免除或者限制责任的条款、
          法律适用和争议解决条款,该等条款将以加粗方式标识您应重点阅读,在您充分理解条款内容后&ldquo;同意并继续&rdquo;。
          如果您对本协议的内容有任何疑问,请向亚洲电视客服咨询。
        </p>
        <p>&emsp;&nbsp;</p>
        <p>&emsp;亚洲电视用户协议</p>
        <p>&emsp;一、服务条款的确认和修改</p>
        <p>
          &emsp;
          1、本协议服务条款（以下简称&ldquo;服务条款&rdquo;）是由用户（您）与亚洲电视经典有限公司订立,并适用于亚洲电视提供的所有网络服务（包括但不限于:
          亚洲电视网站,亚洲电视旗下微信号、&ldquo;亚洲电视&rdquo;小程序、亚洲电视App端等）。
        </p>
        <p>
          &emsp; 2、您理解和同意本协议的全部内容是使用亚洲电视任一服务的前提。
          您在注册、登录或使用亚洲电视提供的任何内容和服务即表示您完全接受本协议项下的全部条款。本协议即构成对亚洲电视和用户双方之间均具有法律约束力的正式文件。
        </p>
        <p>
          &emsp;
          3、您在此不可撤销地承诺,若其使用亚洲电视提供的网络服务,将视为您同意并接受本协议全部条款的约束,此后您无权以未阅读本协议或对本协议有任何误解为由,主张本协议无效或要求撤销本协议。
        </p>
        <p>
          &emsp;
          4、本协议的条款可由亚洲电视修改和更新,亚洲电视将在网页上公布修改内容并将按照法律法规规定的合理期限提前七（7）日提前通知您。若您不同意本协议的修改内容,请您在本协议及/或本协议修改内容生效之日起立即停止使用亚洲电视平台所提供的全部服务。您一旦使用或继续使用亚洲电视的服务,即视为您已了解并完全同意此协议的各项内容,包括亚洲电视对使用协议所做的修改,并成为亚洲电视用户。
        </p>
        <p>
          &emsp;
          5、您了解亚洲电视上的各类信息系用户自行发布或其他商家提供发布,且可能存在风险和瑕疵。
          亚洲电视仅作为您获取物品或服务信息、物色交易对象、就物品和/或服务的交易进行协商及开展交易的场所,但亚洲电视无法控制交易所涉及的物品的质量、安全或合法性,商贸信息的真实性或准确性,以及交易各方履行其在贸易协议中各项义务的能力。您应自行谨慎判断确定相关物品及/或信息的真实性、合法性和有效性,并自行承担因此产生的责任与损失。
        </p>
        <p>
          &emsp;
          6、当您使用亚洲电视服务时,您和亚洲电视应遵守亚洲电视公布的与该服务相关的指引和规则。
          前述所有的指引和规则,均构成本协议的一部分,与本协议不可分割且具有同等法律效力。
          您可在&ldquo;亚洲电视规则中心&rdquo;阅览及下载本协议及前述相关指引和规则。若您使用亚洲电视的服务,即视为您承诺和遵守该等已公示的协议、指引、规则及流程。如果您未满18周岁,您只能在父母或其他监护人的参与下使用服务。
        </p>
        <p>&emsp;&nbsp;</p>
        <p>&emsp;二、用户注册</p>
        <p>&emsp;1、申请资格</p>
        <p>
          &emsp;
          您应当是具备完全民事权利能力和完全民事行为能力的自然人、法人或其他组织。
          若您不具备前述主体资格,则您及您的监护人应承担因此而导致的一切后果,且亚洲电视有权注销（永久冻结）您的亚洲电视账户以及您蜂蜜账户内的&ldquo;花粉&rdquo;及&ldquo;蜂蜜&rdquo;,并向您及您的监护人索偿。
        </p>
        <p>&emsp;2、账户</p>
        <p>
          &emsp;
          2.1您需依亚洲电视注册提示填写正确的注册邮箱、手机号码、密码和用户名及其他信息,并确保今后更新的登录邮箱、手机号码、用户名等资料的有效性和合法性。在完成用户注册程序或以其他亚洲电视允许的方式（授权第三方账户联合登入）实际使用亚洲电视服务时,亚洲电视会向您提供唯一编号的亚洲电视ID账户（以下亦称账户）。您设置的用户名（昵称）不得侵犯或涉嫌侵犯他人合法权益。
          您享有该账户的使用权, 但该账户的所有权归亚洲电视经典有限公司所有。
        </p>
        <p>
          &emsp;
          2.2如果您符合如下账户回收与账户注销的情形的,则视为您同意亚洲电视有权根据亚洲电视的规范对用户进行告知,并有权冻结或回收该账户。前述账户被回收后,该用户帐号对应的发布内容、积分（包括但不限于&ldquo;花粉&rdquo;和&ldquo;蜂蜜&rdquo;）将由亚洲电视进行注销或回收处理,对此给您造成的损失,亚洲电视不承担责任:
        </p>
        <p>
          &emsp;
          （1）连续3个月未登录的,将被视为休眠账户并冻结用户帐号对应的发行内容修改权限、余额、积分的支配权限。连续6个月未登录的亚洲电视有权注销该帐户。
        </p>
        <p>
          &emsp;
          （2）若亚洲电视系统技术检测您的亚洲电视账户存在安全风险和违规操作,为保护该亚洲电视账户的安全,亚洲电视平台会对该亚洲电视账户先进行冻结,在该亚洲电视账户冻结30天内未验证密保解冻的;
        </p>
        <p>&emsp;（3）您主动注销放弃该亚洲电视账户的;</p>
        <p>&emsp;（4）您存在侵犯他人合法权益的情形的;</p>
        <p>
          &emsp;
          （5）如果您被举报或亚洲电视有理由怀疑您存在不当注册或使用多个亚洲电视账户的,亚洲电视有权暂停或终止您的账户,并拒绝您于现在和未来使用亚洲电视平台服务的全部或任何部分。
        </p>
        <p>
          &emsp;
          （6）若您提供任何违法、不道德或亚洲电视认为不适合在亚洲电视上展示的资料,或者亚洲电视有理由怀疑您的资料于程序或恶意操作,亚洲电视有权修改或删除相关信息、暂停或终止您的帐号,并拒绝您于现在和未来使用亚洲电视平台服务的全部或任何部分。
        </p>
        <p>
          &emsp;（7）其他依据相关法律规定或本协议约定,亚洲电视账户应被回收的情形。
        </p>
        <p>&emsp;&nbsp;</p>
        <p>
          &emsp;
          2.3在您的账户不存在任何违反法律法规规定或平台规则的前提下,若您申请注销亚洲电视账户,还需同时满足如下条件:
        </p>
        <p>
          &emsp;
          （1）账户内有交易订单时,最后一笔订单完成时间需超过90日或账户内无交易订单时,账户注册时间需要超过30日;
        </p>
        <p>&emsp;（2）账户钱包余额需为零;</p>
        <p>&emsp;（3）账户无任何纠纷,包括但不限于投诉举报或被投诉举报;</p>
        <p>
          &emsp;
          若您满足上述注销条件,需要注销账户时,可致电平台客服进行账户注销。您确认,一旦您的账户被回收或注销,您将无法再使用亚洲电视服务,也将无法自行找回您注销亚洲电视账户前与您的亚洲电视账户有关的任何内容或信息。
        </p>
        <p>&emsp;&nbsp;</p>
        <p>
          &emsp;
          2.4除非有法律规定或司法裁定,且征得亚洲电视的同意,否则,账户（用户名）和密码不得以任何方式转让、赠与或继承第三人。
        </p>
        <p>&emsp;&nbsp;</p>
        <p>&emsp;3、账户安全</p>
        <p>
          &emsp;
          3.1您应妥善保管好您的账号密码,以及您使用亚洲电视所允许的第三方授权联合登录,所有使用账户密码或第三方联合登录后所产生的行为均视为由您本人操作并负责。如果由于您未妥善保管自己的账号和密码而对您、亚洲电视或第三方造成任何损失或损害,亚洲电视无法也不承担责任,您应通过司法、行政等救济途径向侵权行为人追偿。
        </p>
        <p>
          &emsp;
          3.2如果发现任何人不当使用您的账户或有任何其他可能危及您的账户安全的情形时,您应当立即以有效方式通知亚洲电视,要求亚洲电视暂停相关服务。您理解亚洲电视对您的请求采取行动需要合理时间,且该等行动可能无法避免或完全阻止因此所产生的损失及不利后果,除亚洲电视存在法定过错外,亚洲电视对在采取行动前已经产生的后果（包括但不限于您的任何损失）不承担任何责任。
        </p>
        <p>
          &emsp;
          3.3您承诺不以任何违法违规或违反亚洲电视平台协议的方式使用您的账户。并且,您承诺将不会利用您的账户进行如下行为,否则,亚洲电视有权完全中止或终止向您提供服务、限制您使用亚洲电视所提供服务的部分或全部功能,且无须就前述终止或限制服务导致您遭受的任何损失承担赔偿责任:
        </p>
        <p>
          &emsp;
          （1）提交、发布违法或虚假信息,盗用他人头像或资料,冒充、利用他人名义或身份的;
        </p>
        <p>&emsp;（2）利用技术手段建立批量或虚假账户的;</p>
        <p>
          &emsp;（3）出租、出借、售卖、赠与或以其他方式转让您的亚洲电视账户的;
        </p>
        <p>&emsp;（4）利用亚洲电视账户从事任何违法犯罪活动的。</p>
        <p>&emsp;（5）利用亚洲电视账户进行任何损害亚洲电视合法权益的行为的。</p>
        <p>&emsp;&nbsp;</p>
        <p>
          &emsp;
          3.4亚洲电视在此郑重声明,亚洲电视有权根据亚洲电视交易规则,或联合司法机关、银行、支付机构等共同打击无真实交易背景的虚假交易、银行卡转账套现或洗钱等被禁止的交易行为,您需依法依约定如实交易,否则亚洲电视有权将相关账户内涉及前述交易行为或违反亚洲电视交易规则的款项予以无限期的冻结,直至获得明确的来自司法机构/监管机构的指示,或依据亚洲电视交易规则可以解限为止。
        </p>
        <p>
          &emsp;
          3.5您通过亚洲电视平台与广告商或其他第三方进行任何形式的往来,或参与促销活动,包含相关商品或服务的付款及交付,以及达成的其它任何相关条款、条件、保证或声明,完全为您与广告商或其他第三方之间的行为。
          您知悉并了解,线下见面交易存在较大的人身财产安全及交易纠纷风险,如因线下见面交易产生纠纷及风险,由您自行承担。
          对于您因前述任何交易可能遭受的任何损失及损害,亚洲电视不承担法律责任。
        </p>
        <p>
          &emsp;
          3.6为方便您使用亚洲电视服务及亚洲电视关联公司或其他组织的服务（以下称其他服务）,您同意并授权亚洲电视将您在注册、使用亚洲电视服务过程中提供、形成的信息传递给向您提供其他服务的亚洲电视关联公司或其他组织,或从提供其他服务的亚洲电视关联公司或其他组织获取您在注册、使用其他服务期间提供、形成的信息。
        </p>
        <p>&emsp;&nbsp;</p>
        <p>&emsp;4、用户</p>
        <p>
          &emsp;
          4.1在您按照注册页面提示填写信息或使用微信等第三方联合登陆注册,阅读并同意本协议并完成全部注册程序后或以其他亚洲电视允许的方式实际使用亚洲电视服务时,您即成为亚洲电视用户（亦称用户）。
        </p>
        <p>
          &emsp; 4.2在注册或第三方账号联合登陆时,
          您应当按照法律法规要求,或注册页面的提示准确提供,并及时更新您的资料,以使之真实、及时,完整和准确。如有合理理由怀疑您提供的资料错误、不实、过时或不完整的,亚洲电视有权向您发出询问及/或要求改正的通知,并有权直接做出删除相应资料的处理,直至中止、终止对您提供部分或全部亚洲电视服务。
          亚洲电视对此不承担任何责任,您将承担因此产生的任何直接或间接支出。
        </p>
        <p>
          &emsp;
          4.3您应当准确填写并及时更新您提供的电子邮件地址、联系电话、联系地址、邮政编码等联系方式,以便亚洲电视或其他用户与您进行有效联系,因通过这些联系方式无法与您取得联系,导致您在使用亚洲电视服务过程中产生任何损失或增加费用的,应由您完全独自承担。
        </p>
        <p>
          &emsp;
          4.4您在使用亚洲电视服务过程中,所产生的应纳税赋,以及一切硬件、软件、服务及其它方面的费用,均由您自行申报承担。
        </p>
        <p>&emsp;&nbsp;</p>
        <p>&emsp;三、用户行为规范</p>
        <p>&emsp;在使用亚洲电视服务过程中,您承诺遵守以下约定:</p>
        <p>
          &emsp;
          1、用户单独承担发布内容的责任,并承诺在使用亚洲电视的服务时必须符合中国有关法规。
        </p>
        <p>
          &emsp;
          2、您同意遵守任何地区的所有关于互联网行为的法律法规,包括但不限于中国及任何海外地区。
          您同意不会利用本平台进行任何违法或不正当的活动,包括但不限于下列行为,否则亚洲电视将视为您严重违反本协议亚洲电视将追究您的相关责任,包括但不限于取消相关积分（包括&ldquo;花粉&rdquo;和&ldquo;蜂蜜&rdquo;）、收回相关邀请奖励、取消邀请资格、列入亚洲电视黑名单账户、冻结账户或者注销账户等。
          如造成亚洲电视损失或者承担相应法律责任的,亚洲电视有权要求您赔偿并最终承担相应的责任且有权向监管部门举报或报案:
        </p>
        <p>
          &emsp;
          2.1用户不得利用亚洲电视的服务制作、复制、发布、传播含有下列&ldquo;九不准&rdquo;内容之一的信息:
        </p>
        <p>&emsp;（1）有违宪法确定的基本原则的;</p>
        <p>&emsp;（2）危害国家统一、主权和领土完整的;</p>
        <p>&emsp;（3）泄露国家秘密、危害国家安全或者损害国家荣誉和利益的;</p>
        <p>
          &emsp;
          （4）煽动民族仇恨、民族歧视,破坏民族团结,或者侵害民族风俗、习惯的;
        </p>
        <p>&emsp;（5）宣扬邪教、迷信的;</p>
        <p>&emsp;（6）扰乱社会秩序,破坏社会稳定的;</p>
        <p>
          &emsp;（7）诱导未成年人违法犯罪和渲染暴力、色情、赌博、恐怖活动的;
        </p>
        <p>&emsp;（8）侮辱或者诽谤他人,侵害公民个人隐私等他人合法权益的;</p>
        <p>&emsp;（9）有关法律、行政法规和国家规定禁止的其他内容。</p>
        <p>&emsp;2.2用户不得利用亚洲电视的服务制作、复制、发布、传播</p>
        <p>&emsp;含有违反&ldquo;七条底线&rdquo;要求的信息:</p>
        <p>&emsp;（1）违反法律法规底线;</p>
        <p>&emsp;（2）违反社会主义制度底线</p>
        <p>&emsp;（3）违反国家利益底线;</p>
        <p>&emsp;（4）违反公民合法权益底线</p>
        <p>&emsp;（5）违反社会公共秩序底线;</p>
        <p>&emsp;（6）违反道德风尚底线;</p>
        <p>&emsp;（7）违反信息真实性底线。</p>
        <p>
          &emsp;
          2.3用户不得利用亚洲电视的服务制作、复制、发布、传播含有虚假、诈骗、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其它道德上令人反感的内容;含有中国法律、法规、规章、条例、自律性文件以及任何具有法律效力之规范所限制或禁止的其它内容的;
        </p>
        <p>
          &emsp; 2.4亚洲电视严禁各种针对亚洲电视活动的作弊行为。
          当亚洲电视系统检测到用户有异常行为,或者接到关于用户恶意套取奖励的举报,对于查实的作弊行为,我们将收回该账号全部的邀请奖励、取消邀请资格,给予一定处罚,并列入亚洲电视黑名单账户;作弊情节严重的,亚洲电视有权冻结其帐号下所有对应的权利,并有权追究其法律责任。用户异常行为包括但不局限于如下行为:
        </p>
        <p>&emsp;（1）盗刷他人银行账户;</p>
        <p>&emsp;（2）优惠券套现;</p>
        <p>&emsp;（3）虚假交易;</p>
        <p>&emsp;（4）恶意刷单;</p>
        <p>&emsp;（5）刷奖励补贴,套取奖励;</p>
        <p>
          &emsp;（6）以注册送钱或注册送积分（包括&ldquo;花粉&rdquo;和&ldquo;蜂蜜&rdquo;）等利益欺诈诱导他人注册;
        </p>
        <p>
          &emsp;（7）利用技术手段批量建立虚假账户,包括但不限于使用相同的电脑、相同的IP地址在同一天内注册多个账号,以骗取邀请奖励的行为;
        </p>
        <p>
          &emsp;（8）采取任何将导致不合理的庞大数据负载加诸亚洲电视网络设备的行动;
        </p>
        <p>
          &emsp;（9）其他导致亚洲电视风控部门认定的异常操作和异常奖励发放行为。
        </p>
        <p>
          &emsp;
          2.5用户不得利用亚洲电视服务知悉并发布、发送或以其他方式传送保密资料（包括依据任何法律、合约或法定关系所知悉的保密信息,例如由于雇佣关系和依据保密合约所得知或揭露的内部资料、专属及机密资料等）;
        </p>
        <p>
          &emsp;
          2.6用户不得利用亚洲电视服务发布、发送或以其它方式传送侵害他人著作权、专利权、商标权、商业秘密、或其它专属权利的内容等;
        </p>
        <p>
          &emsp;
          2.7用户不得利用亚洲电视服务发布、发送或以其它方式传送任何广告信函、促销资料、垃圾邮件、滥发信件、连锁信件、直销,或其它任何形式的劝诱资料等
        </p>
        <p>
          &emsp;
          2.8用户不得利用亚洲电视服务发布、发送或以其它方式传送干扰、破坏或限制任何计算机软件、硬件或通讯设备功能的计算机病毒,包括但不限于木马程序（Trojanhorses）蠕虫（worms）,计时炸弹（TimeBombs）删除蝇（Cancelbots）或其它计算机代码、档案和程序的任何资料等。
          不得干扰或破坏本平台及与本平台相关连的服务器或网络,违反关于本平台连线网络的规定、程序、政策及规范等;
        </p>
        <p>
          &emsp; 2.9用户不得利用亚洲电视服务跟踪、人肉搜索或以其它方式骚扰他人。
          不得强制、诱导其他用户关注、点击链接页面或分享信息。
          不得未经合法授权而截获、篡改、收集、储存或删除他人信息、站内资料或其它数据。
        </p>
        <p>&emsp;&nbsp;</p>
        <p>&emsp;3、基于网络服务的特性及重要性,用户同意:</p>
        <p>&emsp;（1）提供详尽、准确的个人资料。</p>
        <p>&emsp;（2）不断更新注册资料,符合及时、详尽、准确的要求</p>
        <p>
          &emsp;（3）自行配备上网的所需设备,包括个人电脑、
          调制解调器或其他必备上网装置。
        </p>
        <p>
          &emsp;（4）自行负担个人上网所支付的与此服务有关的电话费用、网络费用。
        </p>
        <p>
          &emsp;
          4、用户不应将其帐号、密码转让或出借予他人使用。如用户发现其帐号遭他人非法使用,应立即通知亚洲电视。因黑客行为或用户的保管疏忽导致帐号、密码遭他人非法使用,亚洲电视不承担任何责任。
        </p>
        <p>
          &emsp;
          5、用户同意亚洲电视有权在提供网络服务过程中以各种方式投放各种商业性广告或其他任何类型的商业信息（包括但不限于在亚洲电视产品的任何页面上投放广告）,并且用户同意接受亚洲电视通过短信、电子邮件或其他方式向用户发送产品促销或其他相关商业信息。
        </p>
        <p>
          &emsp;
          6、用户不得对亚洲电视平台上的任何数据作商业性利用,包括但不限于在未经亚洲电视事先书面同意的情况下,
          以复制、传播等任何方式使用亚洲电视站上展示的资料。
        </p>
        <p>
          &emsp;
          7、根据有关法律法规,亚洲电视在此郑重提请您注意,任何经由本平台而发布、上传的文字、资讯、资料、音乐、照片、图形、视讯、信息或其它资料（以下简称&ldquo;内容&rdquo;）,无论系公开还是私下传送,均由内容提供者承担责任。鉴于用户信息数量庞大,亚洲电视作为信息技术服务平台,仅在法律范围内对用户所发布信息履行法定审核义务,无法保证内容的正确性、完整性或品质优劣,但亚洲电视有权依法或依照本协议对您发布的相关内容进行删除或屏蔽。用户在使用亚洲电视网络服务过程中或者通过亚洲电视服务所传送的任何内容并不反映亚洲电视经典有限公司的观点或政策,亚洲电视经典有限公司对此不承担任何责任。
        </p>
        <p>
          &emsp;
          8、因用户异常导致奖励或积分（包括&ldquo;花粉&rdquo;和&ldquo;蜂蜜&rdquo;）计算错误,用户须在该订单确认收货后或相关奖励发放后20天内主动联系亚洲电视客服处理,逾期不予处理。
        </p>
        <p>
          &emsp;
          9亚洲电视严禁用户通过以下行为获得利益,一经发现,亚洲电视有权追回用户账号及其关联账号（关联账户由亚洲电视根据自身系统数据判定,判定依据包括但不限于用户登记信息、交易信息、第三方反馈信息、设备终端信息、IP地址等,下同）中已经给予的相关积分（包括&ldquo;花粉&rdquo;和&ldquo;蜂蜜&rdquo;）和已经兑换的奖励等,并视情节严重可中止用户账号直至注销用户账号,同时该用户必须承担由此给亚洲电视带来的所有损失:
        </p>
        <p>&emsp;（1）购买产品后恶意取消订单;</p>
        <p>&emsp;（2）劫持流量;</p>
        <p>&emsp;（3）自买自卖;</p>
        <p>&emsp;（4）劫持其他用户的正常访问链接使其变成推广链接;</p>
        <p>&emsp;（5）骗取其他用户点击其设置的非亚洲电视设置的链接;</p>
        <p>&emsp;（6）违反购物所在网站用户协议及其规则;</p>
        <p>&emsp;（7）其他违反法律法规或者违反诚实信用、公平原则的行为。</p>
        <p>
          &emsp;
          10、亚洲电视有权对用户使用亚洲电视服务的情况进行审查和监督（包括但不限于对用户存储在亚洲电视的内容进行审核）,如用户在使用亚洲电视网络服务时违反任何上述规定,亚洲电视有权要求用户改正或直接采取一切必要的措施取消用户账户应该享有的会员福利（余额、红包、积分、活动奖励等亚洲电视提供给用户的福利）
          （包括但不限于更改或删除用户张贴的内容等、冻结奖励或终止用户使用亚洲电视网络服务的权利）以减轻用户不当行为造成的影响。
        </p>
        <p>&emsp;&nbsp;</p>
        <p>&emsp;四、亚洲电视服务:</p>
        <p>
          &emsp;
          1、亚洲电视注册用户（以下简称用户）可以通过点击亚洲电视所提供的商家、广告、搜索结果等产生网上购物行为,享受购物后获取奖励的机会,或者通过注册成为一些商家的会员而得到一定程度上的奖励。
        </p>
        <p>
          &emsp;
          2、用户注册或第三方联合登陆亚洲电视的用户之后,即刻享有亚洲电视提供的服务。通过亚洲电视及其关联公司提供的亚洲电视服务和其它服务,用户可在亚洲电视上发布查询商品和服务信息、达成交易意向并进行交易、参加亚洲电视组织的活动以及使用其它信息服务及技术服务。
        </p>
        <p>
          &emsp;
          3、亚洲电视用户（以下简称用户）可以通过参加亚洲电视及其合作组织所举行的各种活动获得积分（包括&ldquo;花粉&rdquo;和&ldquo;蜂蜜&rdquo;）。积分（包括&ldquo;花粉&rdquo;和&ldquo;蜂蜜&rdquo;）是用户通过参与点击亚洲电视及其合作组织举办的各种活动及其设置的相应链接、页面才能获得的一种权利,其中&ldquo;蜂蜜&rdquo;可以用来兑换亚洲电视提供的各类奖品和服务。亚洲电视的积分（包括&ldquo;花粉&rdquo;和&ldquo;蜂蜜&rdquo;）仅适用于亚洲电视及其合作组织举办的各种活动,并不构成用户任何形式的资产
        </p>
        <p>
          &emsp;
          4、用户应当获得的积分（包括&ldquo;花粉&rdquo;和&ldquo;蜂蜜&rdquo;）由亚洲电视根据相应活动规则计入用户在亚洲电视之账户中。积分（包括&ldquo;花粉&rdquo;和&ldquo;蜂蜜&rdquo;）获得的规则由各项具体活动详细规定,请您密切注意网站各具体活动的页面。
        </p>
        <p>
          &emsp;
          5、用户除在亚洲电视指定的频道或者平台上进行相关奖励的交易涉及到积分（包括&ldquo;花粉&rdquo;和&ldquo;蜂蜜&rdquo;）的流转外,积分（包括&ldquo;花粉&rdquo;和&ldquo;蜂蜜&rdquo;）不能以买卖等其他任何形式转让予他人。
        </p>
        <p>
          &emsp;
          6、用户积分（包括&ldquo;花粉&rdquo;和&ldquo;蜂蜜&rdquo;）在符合亚洲电视及其合作组织举办的具体活动之规则的情况下可以申请亚洲电视进行奖励发放,正常情况下,亚洲电视收到申请后30个工作日内完成奖励发放工作（遇到节假日情况顺延）。亚洲电视提供银行卡转账等方式进行发放。在使用亚洲电视服务过程中,所有亚洲电视平台上商家奖励返利比例,均以商家最终结算比例为准。
        </p>
        <p>
          &emsp;
          7、亚洲电视&ldquo;蜂蜜&rdquo;还可以兑换各类奖品,具体详情以&ldquo;蜂蜜兑换商城&rdquo;內公示的商品为准。正常情况下,亚洲电视将在30个工作日內兑换并发放相应奖励。（遇到节假日情况顺延）
        </p>
        <p>
          &emsp;
          8、您在亚洲电视的交易过程中与其他用户发生交易纠纷时,一旦您或其它用户任一方或双方共同提交亚洲电视要求调处,则亚洲电视有权根据单方判断做岀调处决定,您了解并同意接受亚洲电视的判断和调处决定。该决定将对您的纠纷具有约束力。
        </p>
        <p>
          &emsp;
          9、您了解并同意,亚洲电视有权应政府部门（包括司法及行政部门）的正当合法的要求,向其提供您在亚洲电视填写的注册信息和交易纪录等必要信息。如您涉嫌侵犯他人知识产权或者其他合法权益,则亚洲电视亦有权在初步判断涉嫌侵权等违法行为存在的情况下,向权利人提供您必要的身份信息。除非法律法规或相关政府部门另有要求,亚洲电视将在前述信息披露情况发生后及时向您发出书面通知。
        </p>
        <p>&emsp;&nbsp;</p>
        <p>&emsp;五、违约处理及责任赔偿</p>
        <p>
          &emsp;
          1、您理解并同意,若您违反本协议、或您侵害他人合法权益而衍生或导致的任何第三方提出的索赔（包括合理的律师费）,您将赔偿亚洲电视及其子公司、关联企业、高级职员、代理人、品牌共有人或其它合作伙伴及员工,并使其免受损害,并承担由此引发的全部法律责任。亚洲电视有权对您的行为是否违反亚洲电视的规则做出认定,并根据认定结果适用规则予以处理或终止向您提供服务,且无须征得您的同意或提前通知予您。
        </p>
        <p>
          &emsp;
          2、对于您在亚洲电视上发布的涉嫌违法或涉嫌侵犯他人合法权利或违反本协议和/成规则的信息,我们有权不经通知随时对相关内容进行审查（包括但不限于审查用户全部信息和资料、聊天记录等）、删除、屏蔽,并视行为情节对违规账户处以包括但不限于警告、限制或禁止使用部分或全部功能、账户封禁直至回收或注销的处罚,并视情况公告处理结果。3、对于您在亚洲电视上实施的行为,包括您未在亚洲电视上实施但已经对亚洲电视及其用户产生影响的行为,亚洲电视有权单方认定您行为的性质及是否构成对本协议和/或规则的违反,并据此作出相应处罚。您应自行保存与您行为有关的全部证据,并应对无法提供充要证据而承担的不利后果
        </p>
        <p>
          &emsp;
          4、对于您涉嫌违反承诺或者有关法律及本协议之规定的行为,使亚洲电视遭受任何损失,或受到任何第三方的索赔,或受到任何行政管理部门的处罚。您均应当以自己的名义独立承担所有的法律责任,并应确保亚洲电视免于因此产生损失或增加费用。如亚洲电视因此承担相应责任或者赔偿相关损失,则您承诺亚洲电视可以向您追偿,相关责任或损失由您最终承担,相关损失包括合理的律师费用、相关机构的查询费用等。您理解并同意,我们有权从未向您支付的任何款项中扣除因您违反本协议而导致的对亚洲电视负有的赔偿款项。
        </p>
        <p>&emsp;&nbsp;</p>
        <p>&emsp;六、知识产权</p>
        <p>
          &emsp;
          1、亚洲电视上展示的资料（包括但不限于文字、图表、标、图像、数字下载和数据编辑）均为亚洲电视或其内容提供者的财产或者权利;亚洲电视上所有内容的汇编是属于亚洲电视的著作权;亚洲电视上所有软件都是亚洲电视或其关联公司或其软件供应商的财产或者权利,上述知识产权均受法律保护。未经亚洲电视同意,任何人单位或者个人不得以任何方式引诱、要求您提供账号和密码,以及/成者通过机器人、蜘蛛、爬虫程序等任何技术手段或设备获取、复制、传播、展示、镜像、转载亚洲电视服务及其內容。如因您的行为侵犯上述权利,亚洲电视有权根据规则对您进行处理并追究您的法律责任。
        </p>
        <p>
          &emsp;
          2、您应保证您在亚洲电视平台发布的所有内容已取得合法授权或您持有合法所有权。您同意亚洲电视对于您通过亚洲电视网络服务（包括但不限于论坛、BBS、评论、个人微博）上传到亚洲电视上可公开获取区域的任何非个人信息内容,在全世界范围內具有免费的、永久性的、不可撤销的、非独家的和完全再许可的权利和许可,以使用、复制、修改、改编、出版、翻译、据以创作衍生作品、传播、表演和展示此等內容（整体或部分）,和/成将此等内容编入当前已知的或以后开发的其他任何形式的作品、媒体或技术中。亚洲电视依据该许可将有权以展示、推广、进行信息挖掘及分析及其他不为我国法律所禁止的方式使用前述内容。
        </p>
        <p>
          &emsp;
          3、如果您对他人的知识产权或其他合法权益造成了侵害,亚洲电视将依照国家法律法规、或在适当的情形下,依照其服务条款或相关规定,删除特定内容、中止或终止您对账户的使用。
        </p>
        <p>
          &emsp;
          4、若您认为您的著作权遭遇侵害或您的知识产权被侵犯,根据《信息网络传播权保护条例》、《电子商务法》等相关法律法规的规定,您需及时与亚洲电视联系并提供详实的举证材料,亚洲电视将按照现行法律法规的规定的知识产权侵权投诉程序予以处理。同时,您同意补偿亚洲电视因您所提交的通知而可能产生的第三方对于亚洲电视的索赔要求,包括但不限于亚洲电视因此支出的全部合理费用
        </p>
        <p>&emsp;&nbsp;</p>
        <p>&emsp;七、责任范围和责任限制</p>
        <p>
          &emsp;
          1、亚洲电视负责按"现状"和"可得到"的状态向您提供服务。但对服务不作任何明示或暗示的保证,包括但不限于亚洲电视服务的适用性、没有错误或疏漏、持续性、准确性、可靠性、适用于某一特定用途。同时,亚洲电视也不对服务所涉及的技术及信息的有效性、准确性、正确性、安全性可靠性、质量、稳定、完整和及时性作出任何承诺和保证
        </p>
        <p>
          &emsp;
          2、您了解亚洲电视上的信息系第三方或者用户自行发布且可能存在风险和瑕疵,亚洲电视仅作为交易平台。亚洲电视仅作为您获取物品或服务信息、物色交易对象、就物品和服务的交易进行协商及开展交易的场所,但亚洲电视无法控制交易所涉及的物品的质量、安全或合法性,商贸信息的真实性或准确性,以及交易各方履行其在贸易协议中各项义务的能力。您应自行谨慎判断确定相关物品及/或信息的真实性、合法性和有效性,并自行承担因此产生的责任与损失。亚洲电视作为导购平台服务提供者,为商家提供展示和引流服务。消费者在亚洲电视开立用户帐户并使用该帐户进入商家的电子商务或理财等平台,在商家的平台完成所有交易行为,包括文描编辑、促销宣传、问题解答、订单确认、商品交付、结算、发票开具、售后等服务均由商家自行负责完成。
        </p>
        <p>
          &emsp;
          3、亚洲电视无法对您的相关注册、登记资料、商品（服务）信息、交易行为以及与交易有关的其它事项进行事先实质性审查,因此用户应确保相应资料的准确性、真实性。若因您的的注册、登记资料的问题导致的相关后果应全部由用户承担,亚洲电视对此不承担相关责任。若根据法律法规要求亚洲电视先行承担相关责任,那么您承诺亚洲电视有权向您追偿,由您最终承担上述责任
        </p>
        <p>
          &emsp;
          4、亚洲电视及其关联公司有权受理您与其他用户因交易产生的争议,并有权单方判断与该争议相关的事实及应适用的规则,进而作出处理决定。该处理决定对您有约束力。如您未在限期内执行处理决定的,则亚洲电视及其关联公司有权利（但无义务）直接使用您亚洲电视账户内的款项。应及时弥补亚洲电视及其关联公司的损失,否则亚洲电视及其关联公司有权直接抵减您在其它合同项下的权益,并有权继续追偿
        </p>
        <p>
          &emsp;
          5、您理解并同意,亚洲电视及其关联公司并非司法机构,仅能以普通人的身份对证据进行鉴别,亚洲电视及其关联公司对争议的调处完全是基于您的委托,亚洲电视及其关联公司无法保证争议处理结果符合您的期望,也不对争议调处结论承担任何责任。如您因此遭受损失,您同意自行向受益人索偿
        </p>
        <p>
          &emsp;
          6、您了解并同意,基于以下任一原因而造成的,包括但不限利润、信誉、应用、数据损失或其它损失,亚洲电视不承担任何直接、间接、附带、特别、衍生性或惩罚性赔偿责任
        </p>
        <p>
          &emsp;（1）通过亚洲电视平台购买或取得的任何商品、数据、信息、服务,收到的讯息使您受到的经济损失;
        </p>
        <p>&emsp;（2）缔结交易而产生的成本使您受到的经济损失;</p>
        <p>
          &emsp;（3）基于您的传输或数据遭到未获授权的存取或变造而遭受的经济损失,包括但不限于第三方未经批准的使用您的账户或更改您的数据;
        </p>
        <p>
          &emsp;（4）任何第三方在亚洲电视平台所作的声明或行为致使您遭受的经济损失;
        </p>
        <p>
          &emsp;（5）对于任何第三方以任何方式发布、投递的欺诈性信息致使您遭受的经济损失;
        </p>
        <p>&emsp;（6）使用或未能使用亚洲电视服务;</p>
        <p>&emsp;（7）您对亚洲电视服务的误解;</p>
        <p>&emsp;（8）任何非因亚洲电视的原因而引起的与亚洲电视服务有关的其它损失</p>
        <p>&emsp;&nbsp;</p>
        <p>&emsp;八、免责声明</p>
        <p>
          &emsp;
          1、亚洲电视将通过各种手段尽力核实商品、商家等相关信息,但无法保证设置的外部链接的准确性和完整性,也无法保证合作商家（包括并不限于电商类、生活类、理财类等）的安全性,同时对于该等外部链接指向的不由亚洲电视实际控制的任何网页上的内容,均不承担任何责任。
        </p>
        <p>
          &emsp;
          2、亚洲电视基于法律按规定履行基础保障义务,但对由于政府行为譬如政策或法规的变更、自然灾害或战争、通讯线路中断、黑客攻击、计算机病毒侵入或发作、电信部门技术调整、因政府管制而造成网站的暂时性关闭等任何影晌网络正常运营的事件,或法律规定的不可抗力事件,以及他人蓄意破坏、正常的系统维护、系统升级,或者因网络拥塞而导致本服务网站不能访问而造成的本服务/网站所提供的信息及数据的延误、停滞或错误,以及使用者由此受到的损失不承担责任。
        </p>
        <p>
          &emsp;
          3、用户理解并确认,在使用本服务过程中存在来自任何他人的包括误导性的、欺骗性的、威胁性的、诽谤性的、令人反感的或非法的信息,或侵犯他人权利的匿名或冒名的信息,以及伴随该等信息的行为,因此导致的用户或第方的任何损失,亚洲电视不承担任何责任
        </p>
        <p>
          &emsp;
          4、亚洲电视依据法律法规、本协议约定获得处理违法违规或违约内容的权利,该权利不构成亚洲电视的义务或承诺亚洲电视不能保证及时发现违法违规或违约行为或进行相应处理。
        </p>
        <p>
          &emsp;
          5、在任何情况下,亚洲电视均不对任何间接性、后果性、惩罚性、偶然性、特殊性或刑罚性的损害,包括因用户使用亚洲电视服务而遭受的利润损失,承担责任（即使亚洲电视已被告知该等损失的可能性亦然
        </p>
        <p>
          &emsp;
          6、亚洲电视对在平台上发布的各种商品、商家、理财等相关信息,均由合作商家提供。对于该等发布的相关信息造成的相关利益损失（包括不限于积分（包括&ldquo;花粉&rdquo;和&ldquo;蜂蜜&rdquo;）、本金、利息、费用等）,均不承担任何责任。
        </p>
        <p>
          &emsp;
          7、亚洲电视不对您所发布或提供的数据信息的保存、修改、删除或储存失败负责。亚洲电视可以根据实际情况自行决定您在本服务中非个人信息数据的最长储存期限,并在服务器上为其分配数据最大存储空间等。您可根据自己的需要自行备份本服务中的相关数据。您在此明确同意并接受,如您停止使用本服务或本服务终止或您的亚洲电视账户被收回的,亚洲电视没有义务向您返还任何数据。
        </p>
        <p>
          &emsp;
          8、我们仅就本服务条款及使用条件所述服务向您承担责任,且所承担的赔偿责任应以具体服务所收取的服务费的1倍为限。
        </p>
        <p>&emsp;&nbsp;</p>
        <p>&emsp;九、修改及终止服务</p>
        <p>
          &emsp;
          1、亚洲电视始终在不断更改和改进其所提供的服务。亚洲电视可能会增加或删除某项服务功能,也可能暂停或彻底停止某项服务。您同意,亚洲电视经法定期限事先通知您后有权自行决定以合理理由修改、中止或终止向您提供部分或全部亚洲电视平台服务。对于上述服务的修改、暂停或终止,亚洲电视无须为此向您或任何第三方承担责任。但是,如果我们变更或停止提供某项服务,则在可能的情况下,我们会依法向用户发出合理的提前通知,并让用户获知和同意前述变更,并有机会备份和转移其留存在本服务中的信息。您也可以随时停止使用亚洲电视的服务。
        </p>
        <p>
          &emsp;
          2、您理解并同意,亚洲电视有权因下述理由,终止本协议,并永久冻结（注销）您的账户使用权,且亚洲电视无需就此向您承担违约责任。您承认并同意,除法律有明确规定外,亚洲电视可立即关閉或删除您的账号,及或禁止继续使用前述账号中所有信息及文件件或本服务。此外,您同意亚洲电视为此对您或第三方不承担责任。
        </p>
        <p>
          &emsp;
          （1）亚洲电视终止向您提供服务后,您涉嫌再一次直接或间接或以他人名义注册为亚洲电视用户的;
        </p>
        <p>
          &emsp;
          （2）您提供的电子邮箱不存在或无法接收电子邮件,且没有其他方式可以与您进行联系,或亚洲电视以其它联系方式通知您更改电子邮件信息,而您在亚洲电视通知后三个工作日内仍未更改为有效的电子邮箱的;
        </p>
        <p>&emsp;（3）您注册信息中的主要內容不真实或不准确或不及时或不完整</p>
        <p>
          &emsp;
          （4）本协议（含规则）变更时,您明示并通知亚洲电视不愿接受新的服务协议的;
        </p>
        <p>&emsp;（5）其它亚洲电视认为应当终止服务的情况。</p>
        <p>
          &emsp;
          3、您有权向亚洲电视要求注销您的账户,经亚洲电视审核同意的,亚洲电视注销（永久冻结）您的账户,届时,您与亚洲电视基于本协议的合同关系即终止。您的账户被注销（永久冻结）后,亚洲电视没有义务为您保留或向您披露您账户中的任何信息,也没有义务向您或第三方转发任何您未曾阅读或发送过的信息。
        </p>
        <p>&emsp;4、您同意,您与亚洲电视的合同关系终止后,亚洲电视仍享有下列权利</p>
        <p>
          &emsp;（1）继续保存您的注册信息及您使用亚洲电视服务期间的所有交易信息。
        </p>
        <p>
          &emsp;
          （2）您在使用亚洲电视服务期间存在违法行为或违反本协议和或规则的行为的,亚洲电视仍可依据本协议向您主张权利。
        </p>
        <p>
          &emsp;
          5、亚洲电视中止或终止向您提供服务后,对于您在服务中止或终止之前的交易行为依下列原则处理,您应独力处理并完全承担进行以下处理所产生的任何争议、损失或增加的任何费用,并应确保亚洲电视免于因此产生任何损失或承担任何费用
        </p>
        <p>
          &emsp;
          （1）您在服务中止或终止之前已经上传至亚洲电视的物品尚未交易的,亚洲电视有权在中止或终止服务的同时删除此项物品的相关信息;
        </p>
        <p>
          &emsp;
          （2）您在服务中止或终止之前已经与其他用户达成买卖合同,但合同尚未实际履行的,亚洲电视有权删除该买卖合同及其交易物品的相关信息
        </p>
        <p>
          &emsp;
          （3）您在服务中止或终止之前已经与其他用户达成买卖合同且已部分履行的,亚洲电视可以不删除该项交易,但亚洲电视有权在中止或终止服务的同时将相关情形通知您的交易对方。
        </p>
        <p>&emsp;&nbsp;</p>
        <p>&emsp;十、法律适用、管辖与其他</p>
        <p>
          &emsp;
          1、本协议及您与亚洲电视之间的关系,均受到中华人民共和国法律制约,本协议的签订地及履行地均为深圳市南山区
        </p>
        <p>
          &emsp;
          2、您与亚洲电视就本协议或其它相关事项产生的争议,应首先友好协商解决,协商不成时应将争议提交至深圳市南山区人民法院管辖
        </p>
        <p>
          &emsp;
          3、亚洲电视未行使或执行本使用协议任何权利或规定,不构成对前述权利或权利之放弃
        </p>
        <p>
          &emsp;
          4、若本使用协议的任何规定因与中华人民共和国法律抵触而无效,您依然同意,应依照法律,努力使该规定所反块的当事人意向具备效力,且本协议其它规定仍应具有完整的效力及效果
        </p>
        <p>
          &emsp;
          5、本协议任一条款被视为废止、无效或不可执行,该条应视为可分的且并不影响本协议其条款的有效性及可执
        </p>
        <p>&emsp;6、本协议标题仅供方便而设,不具任何法律或契约效果</p>
        <p>
          &emsp;
          7、我们将通过您在亚洲电视预留的邮箱、手机号码等方式以电子邮件、短信、在本服务/网站上发布通知等方式或其它服务与您进行联系。您同意我们用这些方式提供给您的所有协议、通知、披露和其他信息是符合相关的法律法规要求的。如果我们以该等形式向您发出信息或者在本服务网站上发出相关通知,将视为您已收到所有协议、声明、披露和其它信息。
        </p>
        <p>&emsp;&nbsp;</p>
        <p>&emsp;亚洲电视隐私政策</p>
        <p>&emsp;&nbsp;</p>
        <p>&emsp;欢迎使用亚洲电视！</p>
        <p>
          &emsp;
          感谢您信任并使用亚洲电视(以下可称&ldquo;我们&rdquo;)的服务。本隐私政策为根据相关法律法规的要求制定,我们特别提醒您(以下可称&ldquo;用户&rdquo;)注意:您在使用亚洲电视提供的各项服务之前,应仔细阅读本协议的各项条款,特别是加粗的涉及您核心利益的条款、免除或者限制责任的条款、法律适用和争议解决条款。在您浏览访问亚洲电视平台网站、应用程序等或者注册登录使用亚洲电视的任何产品和服务时，我们将按法律法规要求,采取相应安全保护措施,尽力保护您的个人信息安全可控。如您使用亚洲电视服务,即视为您已了解并同意本政策各项内容。
        </p>
        <p>&emsp; &nbsp;</p>
        <p>&emsp; 特别提示</p>
        <p>
          &emsp;
          亚洲电视隐私政策仅适用于亚洲电视提供的产品和服务,本政策不适用于其他第三方通过亚洲电视平台向您提供的产品和服务,或者亚洲电视上的第三方链接所指向的产品和服务也不适用于亚洲电视中已另行独立设置法律声明及隐私权政策的产品或服务(例如亚洲电视上的卖家依托平台向您提供服务时,您向卖家提供的个人信息不适用本政策),在此情况下,建议您认真阅读和了解第三方的隐私政策。
        </p>
        <p>
          &emsp;
          本政策的全部条款属于亚洲电视网络服务协议的重要组成部份之一。一旦您浏览访问亚洲电视网站或者选择同意注册用户使用我们的服务,即表示您已全面阅读、知悉本声明的内容,并同意做岀相应的授权。本政策在您使用网站和服务的整个过程中有效,如果您对本声明有任何疑问、意见或建议,您可联系我们咨询反馈。如果您不同意本声明,您应立即停止使用平台所提供的产品和服务
        </p>
        <ul>
          <li>定义及法律声明</li>
        </ul>
        <p>&emsp;1.定义</p>
        <p>
          &emsp;
          1.1个人信息:指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。
        </p>
        <p>
          &emsp;
          1.2个人敏感信息:包括身份证件号码、个人生物识别信息、银行账号、财产信息、行踪轨迹、交易信息、14岁以下(含)儿童的个人信息等。
        </p>
        <p>
          &emsp;
          1.3个人信息删除:指在实现日常业务功能所涉及的系统中去除个人信息的行为,使其保持不可被检索、访问的状态。
        </p>
        <p>&emsp;2.法律声明</p>
        <p>
          &emsp;
          2.1用户应保证其提供的或授权亚洲电视搜集的个人信息的真实性、准确、完整、合法有效,用户个人信息如有变动的,应及时更新个人信息。亚洲电视有权通过征信机构、银行卡绑定等方式,对您提交的身份信息的真实性进行验证。如果用户提供或授权亚洲电视收集的个人信息不合法、不真实、不准确、不详尽的,用户需承担因此引起的相应责任及后果,且亚洲电视有权终止向您提供服务。
        </p>
        <p>
          &emsp;
          2.2除非另有声明,亚洲电视所有产品、技术、程序、软件、数据及相关域名、标示、名称及包含其他知识产权的信息均归亚洲电视服务提供者及或其关联公司所有,未经前述所有者许可,任何人不得以包括通过机器人、蜘蛛等程序或设备监视、复制、传播、展示、下载等方式擅自使用或转让、授权他人使用。
        </p>
        <p>
          &emsp;
          2.3用户应对其发布信息,包括但不限于用户的分享、点评的内容(包括文字、图片、视频等),以及用户发布的个人物品转让信息等信息承担相应的法律责任。亚洲电视对前述内容的转载仅出于传递更多信息的目的,对其观点或其内容的真实性不做任何赞同和证实。
        </p>
        <ul>
          <li>我们如何收集和使用您的个人信息</li>
        </ul>
        <p>&emsp;1.个人信息收集和使用规则</p>
        <p>
          &emsp;
          1.1我们会根据本隐私政策的约定对用户个人信息进行收集,并严格按照约定为实现我们的产品与成服务功能对所收集的个人信息进行使用。
        </p>
        <p>
          &emsp;
          1.2为实现特定功能和目的而需要收集其他个人信息时,我们会通过您主动做出勾选的形式事先征求您的同意。当我们要将您已经授权收集的个人信息用于本声明未经说明的其它用途时,我们亦会另行获得您的授权。
        </p>
        <p>
          &emsp;
          1.3您了解并同意,在收集您的个人信息后,我们可通过技术手段对数据进行去标识化处理,去标识化处理的信息将无法识别主体,在此情况下我们有权使用已经去标识化的信息;并在不透露您个人信息的前提下,我们有权对用户数据库进行分析并予以商业化的利用。
        </p>
        <p>
          &emsp;
          1.4我们会对我们的产品与或服务使用情况进行统计,并可能会与公众或第三方共享这些统计信息,以展示我们的产品与服务的整体使用趋势。但这些统计信息不包含您的任何身份识别信息。
        </p>
        <p>
          &emsp;
          1.5您有权更改或删除个人信息,通过系统设置拒绝我们收集某些信息的方式,来行使您对个人信息的控制权,但已经进行去识别化处理的除外。
        </p>
        <p>&emsp;2.个人信息收集和使用的情形</p>
        <p>&emsp;2.1访问和浏览亚洲电视平台</p>
        <p>
          &emsp; 在您访问和浏览亚洲电视平台时,我们会通过
          cookie或其他技术方式收集关于您使用产品、服务以及使用方式的信息并将这些信息进行关联,这些信息包括
        </p>
        <p>
          &emsp;
          (1)设备信息:我们会根据您在软件安装及使用中授予的具体权限,接收并记录您所使用的设备相关信息(例如设备型号、操作系统版本、设备设置、唯一设备标识符等软硬件特征信息)、设备所在位置相关信息(例如IP地址、GPS/北斗位置信息以及能够提供相关信息的Wi-Fi接
        </p>
        <p>&emsp;入点、蓝牙和基站等传感器信息</p>
        <p>
          &emsp;
          (2)日志信息:当您使用我们的网站或客户端提供的产品或服务时,我们会自动收集您对我们服务的详细使用情况,作为有关网络日志保存。例如您的搜索查询内容、IP地址、浏览器的类型、电信运营商、使用的语言、访问日期和时间、您访问的网页记录、通话状态信息等。
        </p>
        <p>
          &emsp;
          2.1.2请注意,单独的设备信息和浏览信息并不具有识别性,我们使用这些信息分析群体性用户的站内活动情况以及整个用户群的社会信息。除非取得您的授权或法律法规另有规定外,我们不会将该类信息与其他信息结合使用而使其具有识别化。
        </p>
        <p>&emsp;2.2用户注册</p>
        <p>
          &emsp;
          2.2.1您在注册账户时,您要向我们提供手机号码等基本信息以创建用户名和密码。如果您使用第三方账户授权登录时,则授权我们共享您的第三方账户信息。
        </p>
        <p>
          &emsp;
          2.2.2您在用户注册中可以提供更多额外信息补全个人资料,如您的真实姓名、性别、出生年月日、居住地、您本人的真实头像、您的兴趣爱好等。这些信息将有助于我们给您提供更个性化的会员服务,但不予提供并不会影响到您使用亚洲电视的基本服务;
          您应确保开通本服务所提供的手机号码为本人所有且有效,并授权我们可以通过第三方渠道对您所提供手机号码的真实性、有效性进行核实。
        </p>
        <p>
          &emsp;
          2.2.3目前亚洲电视仅面向中华人民共和国(为政策之目的,不包括香港、澳门和台湾)公民提供服务,若您是外国公民或港澳台居民的,您应立即停止注册并主动咨询亚洲电视客服,确认是否对您开通相关服务。2.2.4您有权利注销注册,在此情况下,除非法律另有要求,亚洲电视将删除你的个人信息,但已经用于用户画像的匿名化处理的信息或根据法律法规不得删除的情形除外。
        </p>
        <p>&emsp;2.3商品/服务或内容展示所需信息</p>
        <p>
          &emsp;
          2.3.1为了让您快速地找到您所需要购买(分享)的商品,我们可能会收集您使用我们的产品与/或服务的设备信息(包括设备名称、设备型号、设备识别码、操作系统和应用程序版本、语言设置、分辨率、服务提供商网络D(PLMN))、设备所在位置相关信息(例如IP地址、GPS位置以及能够提供相关信息的W-F接入点、蓝牙和基站等传感器信息等)、浏览行为信息(搜索查询内容浏览器的类型、电信运营商、使用的语言、访问日期和时间及访问的网页记录等)来为您提供商品信息展示的最优方式。我们也会为了不断改进和优化上述的功能来使用您
        </p>
        <p>&emsp;的上述个人信息。</p>
        <p>
          &emsp;
          2.3.2您也可以通过搜索来精准地找到您所需要的商品或服务。我们会保留您的搜索内容以方便您重复输入或为您展示与您搜索内容相关联的商品。
          请您注意,您的搜索关键词信息无法单独识别您的身份,不属于您的个人信息,我们有权以任何的目的对其进行使用;只有当您的搜索关键词信息与您的其他信息相互结合使用并可以识别您的身份时,则在结合使用期间,我们会将您的搜索关键词信息作为您的个人信息,与您的搜索历史记录一同按照本隐私政策对其进行处理与保护。
        </p>
        <p>&emsp;2.4订单</p>
        <p>
          &emsp;
          2.4.1当您准备对您购买的商品进行结算时,系统会自动跳转至淘宝界面,并在淘宝APP中生成您购买该商品的订单,或由亚洲电视根据特定商品直接生成订单。您需要在订单中至少填写您真实的收货人姓名、收货地址以及手机号码,同时该订单中会载明订单号、您所购买的商品或服务信息、您应支付的货款金额及支付方式;您可以在订单上补充告知交易对方其他信息以方便交易的实现。
        </p>
        <p>
          &emsp;
          2.4.2此外,在亚洲电视达成的某些特定商品或服务的交易时,您还可能需要根据国家法律法规或服务提供方的要求提供更多信息,比如实名认证信息、行程信息、学历信息等等。
        </p>
        <p>
          &emsp;
          2.4.3上述所有信息构成您的&ldquo;订单信息&rdquo;,我们将使用您的订单信息来进行您的身份核验、确定交易、支付结算完成配送、为您查询订单以及提供客服咨询与售后服务我们还会使用您的订单信息来判断您的交易是否存在异常以保护您的交易安全。
        </p>
        <p>&emsp;2.5支付</p>
        <p>
          &emsp;
          2.5.1在您下单后,您可以选择相关的第三方支付机构所提供的支付服务完成商品/服务交易的收付款。支付功能本身并不收集您的个人信息,但我们需要将您的订单号与交易金额信息与这些支付机构共享以实现其确认您的支付指令并完成支付。
        </p>
        <p>
          &emsp;
          2.5.2在支付的过程中,如果您使用我们或者我们支持的第三方的金融服务,为了交易的安全性及可用性,我们需要您提供实名认证信息并授权我们查询和使用您的个人信用信
        </p>
        <p>&emsp;2.6交付产品或服务功能</p>
        <p>
          &emsp;
          2.6.1在当您下单并在线完成支付后,需要由亚洲电视提供配送服务的,将由亚洲电视合作的第三方配送公司(包括EMS、顺丰、中通等,以下称&ldquo;配送公司&rdquo;)为您完成订单的交付。您知晓并同意亚洲电视合作的第三方配送公司会在上述环节内使用您的订单信息以保证您的订购的商品能够安全送达。
        </p>
        <p>
          &emsp;
          2.6.2对于您指定的其他收货人的个人信息,您应确保您已取得其授权同意后再向我们提交其个人信息。您了解我们或亚洲电视平台入驻的商家需委托第三方向收货人交付商品或服务时,您同意将上述信息授权共享给提供运输服务的第三方。若您不提供相关信息,您将无法享受该等交付服务。
        </p>
        <p>&emsp;2.7售后服务</p>
        <p>
          &emsp;
          2.7.1我们的客服和售后功能出于提供售后服务之目的将会收集和使用您的账号信息和订单信息。并将相关售后服务中与您的沟通及处理情形进行记录。
        </p>
        <p>
          &emsp;
          2.7.2为保证您的账号安全,我们的在线客服会使用您的账号信息与您核验您的身份。当您需要我们提供与您订单信息相关的客服与售后服务时,我们将会查询您的订单信息。您有可能会在与我们的客服人员沟通时,提供给出上述信息外的其他信息,如当您要求我们变更配送地址、联系人或联系电话。
        </p>
        <p>&emsp;2.8安全保障</p>
        <p>
          &emsp;
          2.8.1为提高您使用我们的产品与/成服务时系统的安全性,更准确地预防钓鱼网站欺诈和保护账户安全,我们可能会通过了解您的浏览信息、订单信息、您常用的软件息、设备信息等手段来判断您的账号风险,并可能会记录些我们认为有风险的链接(&ldquo;URL);我们也会收集您
        </p>
        <p>
          &emsp;
          的设备信息对于问题进行分析、统计流量并排查可能存在的风险、在您选择向我们发送异常信息时予以排查。
        </p>
        <p>
          &emsp;
          2.8.2在某些金融服务过程中,我们将根据法律法规的要求以及安全防范的需要,将采集的信息自行或与服务提供商共享用于用户信用分析、身份核验、信息真实性校验比对等,以避免资金损失或产生不良信用风险。
        </p>
        <p>&emsp;3.您可选择是否授权我们收集和使用您的个人信息的情形</p>
        <p>
          &emsp;
          3.1为提升您在亚洲电视平台社交电商体验,我们的以下附加功能中可能会收集和使用您的个人信息。如果您不提供这些个人信息,您依然可以使用亚洲电视,但您可能无法使用部分个性化的服务及附加功能。这些附加功能包括：
        </p>
        <p>
          &emsp;(1)基于位置信息的个性化推荐功能:我们会收集您的位置信息(我们仅收集您当时所处的地理位置,但不会将您各时段的位置信息进行结合以判断您的行踪轨迹)来判断您所处的地点,以期自动为您推荐您所在区域可以购买的商品或服务。
        </p>
        <p>
          &emsp;(2)基于摄像头(相机)的附加功能:您可以使用这个附加功能完成视频拍摄、拍照、扫码登录的功能。
        </p>
        <p>
          &emsp;(3)基于图片上传的附加功能:您可以在亚洲电视上传您的照片来实现拍照和晒单及评价功能,我们会使用您所上传的照片来识别您需要购买的商品或使用包含您所上传图片的评价。
        </p>
        <p>
          &emsp;(4)基于通讯录信息的附加功能我们将收集您的通讯录信息以方便您在购物时不再手动输入您通讯录中联系人的信息(比如您可以直接为通讯录中的电话号码充值);为了增加您购物时的社交乐趣,在获得您的同意下我们也可以判断您的好友是否也同为亚洲电视用户,并在亚洲电视为你们的交流建立联系。
        </p>
        <p>
          &emsp;3.2上述附加功能可能需要您授权同意我们开启您的地理位置(位置信息)、相机(摄像头)、相册(图片库)、麦克风以及通讯录的访问权限,以实现这些功能所涉及的信息的收集和使用。您在使用亚洲电视的服务时由您主动提供、上传或发布的内容和信息(如在买家评价中或与其他
        </p>
        <p>
          &emsp;
          好友进行社交时发布的含有您的肖像或其他个人信息的图片等)可能会泄漏您的个人敏感信息,请您谨慎对待并慎重考虑是否在使用亚洲电视服务时共享甚至公开分享相关信息。
        </p>
        <p>
          &emsp;
          3.3请您注意,您开启这些权限即代表您授权我们可以收集和使用这些个人信息来实现上述的功能,您关闭权限即代表您取消了这些授权,则我们将不再继续收集和使用您的这些个人信息,也无法为您提供上述与这些授权所对应的功能。您关闭权限的决定不会影响此前基于您的授权所进行的个人信息的处理。
        </p>
        <p>&emsp;4.我们从第三方获得您个人信息的情形</p>
        <p>
          &emsp;
          4.1我们可能从第三方获取您授权共享的账户信息(头像、昵称),并在您同意本隐私政策后将您的第三方账户与您的亚洲电视账户绑定,使您可以通过第三方账户直接登录并使用我们的产品与服务。我们会将依据与第三方的约定、对个人信息来源的合法性进行确认后,在符合相
        </p>
        <p>&emsp;关法律和法规规定的前提下,使用您的这些个人信息。</p>
        <p>
          &emsp;5您充分知晓,以下情形中,我们收集、使用个人信息无需征得您的授权同意
        </p>
        <p>&emsp;(1)与国家安全、国防安全有关的</p>
        <p>&emsp;(2)与公共安全、公共卫生、重大公共利益有关的</p>
        <p>&emsp;(3)与犯罪侦查、起诉、审判和判决执行等有关的</p>
        <p>
          &emsp;(4)出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的
        </p>
        <p>&emsp;(5)所收集的个人信息是个人信息主体自行向社会公众公开的;</p>
        <p>
          &emsp;
          (6)从合法公开披露的信息中收集的您的个人信息的,如合法的新闻报道、政府信息公开等渠道;
        </p>
        <p>&emsp;(7)根据您的要求签订合同所必需的;</p>
        <p>
          &emsp;(8)用于维护所提供的产品与/或服务的安全稳定运行所必需的,例如发现、处置产品与/成服务的故障;
        </p>
        <p>&emsp;(9)为合法的新闻报道所必需的;</p>
        <p>
          &emsp;(10)学术研究机构基于公共利益开展统计或学术研究所必要,且对外提供学术研究或描述的结果时,对结果中包含的个人信息进行去标识化处理的;
        </p>
        <p>&emsp;(11)法律法规规定的其他情形。</p>
        <p>
          &emsp;
          6.根据法律规定,共享、转让经去标识化处理的个人信息,且确保数据接收方无法复原并重新识别个人信息主体的,不属于个人信息的对外共享、转让及公开披露行为对此类数据的保存及处理将无需另行向您通知并征得您的同意。
        </p>
        <p>&emsp;三、我们如何使用 Cookie和同类技术</p>
        <p>
          &emsp;
          1.为使您获得更轻松的访问体验,您使用亚洲电视平台产品或服务时,我们可能会通过采用各种技术收集和存储您访问亚洲电视平台服务的相关数据,在您访问或再次访问亚洲电视平台服务时我们能识别您的身份,并通过分析数据为您提供更好更多的服务。包括使用小型数据文件识别您的身份,这么做是为了解您的使用习惯,帮您省去重复输入账户信息的步骤,或者帮助判断您的账户安全。这些数据文件可能是
          Cookie、 Flash
          Cookie,或您的浏览器或关联应用程序提供的其他本地存储(统称&ldquo;Cookie&rdquo;)
        </p>
        <p>
          &emsp;
          2.网页上常会包含一些电子图像,称为&ldquo;单像素GF文件&rdquo;或&ldquo;网络
          beacon&rdquo;,它可以帮助网站计算浏览网页的用户或访问某些
          cookie。我们会通过网络
          beacon收集您浏览网页活动信息,例如您访问的页面地址、您先前访问的援引页面的位址、您停留在页面的时间、您的浏览环境以及显示设定等。
        </p>
        <p>
          &emsp; 3.请您理解,我们的某些服务只能通过使用
          Cookie.才可得到实现。如果您的浏览器或浏览器附加服务允许,您可以修改对
          Cookie的接受程度或者拒绝亚洲电视平台的Cookie,但拒绝亚洲电视平台的
          Cookie在某些情况下您可能无法使用依赖于 cookies的亚洲电视平台服务的部分功
        </p>
        <p>&emsp; 能。</p>
        <p>&emsp;四.如何共享、转让、公开披露您的个人信息</p>
        <p>&emsp;1.共享</p>
        <p>
          &emsp;
          1.1我们不会与亚洲电视平台服务提供者以外的公司、组织和个人共享您的个人信息,但以下情况除外：
        </p>
        <p>&emsp;(1)事先获得您明确的同意或授权;</p>
        <p>
          &emsp;(2)根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况下进行提供；
        </p>
        <p>
          &emsp;(3)在法律法规允许的范围内,为维护亚洲电视、亚洲电视的关联方或合作伙伴、您或其他用户或社会公众利益、财产或安全免遭损害而有必要提供；
        </p>
        <p>
          &emsp;(4)只有共享您的信息,才能实现我们的产品与或服务的核心功能或提供您需要的服务;
        </p>
        <p>&emsp;(5)应您需求为您处理您与他人的纠纷或争议;</p>
        <p>
          &emsp;(6)符合与您签署的相关协议(包括在线签署的电子协议以及相应的平台规则)或其他的法律文件约定所提供;
        </p>
        <p>&emsp;(7)基于学术研究而使用;</p>
        <p>&emsp;8)基于符合法律法规的社会公共利益而使用。</p>
        <p>
          &emsp; 1.2与授权合作伙伴共享:
          仅为实现本隐私政策中声明的目的,我们的某些服务将由我们和授权合作伙伴共同提供。我们可能会与合作伙伴共享您的某些个人信息,以提供更好的客户服务和用户体验。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息,并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于与产品或服务无关的其他用途。
        </p>
        <p>
          &emsp;
          1.3亚洲电视平台服务可能含有到其他网站的链接。除法律另有规定外,亚洲电视对其他网站的隐私保护措施不负任何责任。我们可能在任何需要的时候增加商业伙伴或共用品牌的网站,但是提供给他们的将仅是综合信息,我们将不会公开您的身份。
        </p>
        <p>&emsp;2.转让</p>
        <p>
          &emsp;
          2.1我们不会将您的个人信息转让给任何公司、组织和人,但以下情况除外：
        </p>
        <p>
          &emsp;
          (1)在获取明确同意的情况下转让:获得您的明确同意后,我们会向其他方转让您的个人信息。
        </p>
        <p>
          &emsp;
          (2)根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况进行提供；
        </p>
        <p>
          &emsp;
          (3)符合与您签署的相关协议(包括在线签署的电子协议以及相应的平台规则)或其他的法律文件约定所提供；
        </p>
        <p>
          &emsp;
          (4)在涉及合并、收购、资产转让或类似的交易时,如涉及到个人信息转让,我们会要求新的持有您个人信息的公司、组织继续受本隐私政策的约束,否则我们将要求该公司、组织重新向您征求授权同意。
        </p>
        <p>&emsp;3公开披露</p>
        <p>&emsp;3.1我们仅会在以下情况下,公开披露您的个人信息：</p>
        <p>
          &emsp;
          (1)获得您明确同意或基于您的主动选择,我们可能会公开披露您的个人信息;
        </p>
        <p>
          &emsp;
          (2)如果我们确定您岀现违反法律法规或严重违反亚洲电视平台相关协议规则的情况,或为保护亚洲电视平台用户或公众的人身财产安全免遭侵害,我们可能依据法律法规或亚洲电视平台相关协议规则征得您同意的情况下披露关于您的个人信息,包括相关违规行为以及亚洲电视平台已对您采取的措施。
        </p>
        <p>
          &emsp;
          (3)根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您个人信息的情况下,我们可能会依据所要求的个人信息类型和披露方式公开披露您的个人信息。在符合法律法规的前提下,当我们收到上述披露信息的请求时,我们会要求必须出具与之相应的法律文件,如传票或调查函。我们坚信,对于要求我们提供的信息,应该在法律允许的范围内尽可能保持透明。我们对所有的请求都进行了慎重的审查,以确保其具备合法依据,且仅限于执法部门因特定调查目的且有合法权利获取的数据。
        </p>
        <p>&emsp;五.如何保护您的个人信息</p>
        <p>
          &emsp;
          1.我们会采用符合业界标准的安全防护措施,包括建立合理的制度规范、安全技术来防止您的个人信息遭到未经授权的访问使用、修改,避免数据的损坏或丢失。在某些服务中,我们将利用加密技术(例如SSL)来保护您的信息,采取加密技术对您的信息进行加密保存,并通过隔离技术进行隔离。
        </p>
        <p>
          &emsp;
          2.我们会采取合理可行的措施,尽力避免收集无关的个人信息,并在限于达成本政策所述目的所需的期限内保留您的个人信息,除非需要延长保留期限或在法律的允许期限内。
        </p>
        <p>
          &emsp;
          3.在不幸发生个人信息安全事件后,我们将按照法律法规的要求【并最迟不迟于30个自然日内】向您告知:安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。事件相关情况我们将以邮件、信函、电话、推送通知等方式告知您,难以逐一告知个人信息主体时,我们会采取合理、有效的方式发布公告。同时,我们还将按照监管部门要求,上报个人信息安全事件的处置情况。
        </p>
        <p>
          &emsp;
          4.互联网环境并非百分之百安全,尽管我们有这些安全措施,但请注意在互联网上不存在完善的安全措施&rdquo;,我们将尽力确保您的信息的安全性。
        </p>
        <p>
          &emsp;
          5.若您发现您的个人信息泄露时,请您立即通过本声明约定的联系方式与我们联系,以便我们及时采取相应措施。
        </p>
        <p>&emsp;六.我们如何管理您的个人信息</p>
        <p>
          &emsp;
          1.您可登陆App端对您的个人信息进行查询、修改和更新,您也可以对您部分个人信息进行删除,但是请注意部分信息的删除可能导致您相关功能的使用,我们将不对因您删除个人信息而无法享有的权利承担责任。
        </p>
        <p>
          &emsp;
          2.您可以申请注销您的个人账户,或在符合法律法规和用户协议约定的条件下,您的账户可能被注销或删除,当账号注销或删除后,您在该账号下的服务、资料和数据可能被删除或处理
        </p>
        <p>
          &emsp;
          3.如果您对我们的个人信息保护有任何疑问,可通过本政策最下方约定的联系方式联系我们。如您发现自己的个人信息泄密,尤其是您的账户及密码发生泄露,请您立即通过本政策最下方约定的联系方式联络我们,以便我们采取相应措施。
        </p>
        <ol>
          <li>未成年人的个人信息保护</li>
        </ol>
        <p>
          &emsp;
          1.亚洲电视用户须为具有法定的相应权利能力和行为能力的自然人,能够独立承担法律责任,如用户不具备前述与用户行为相适应的民事行为能力,则用户及用户的监护人应依照法律规定承担因此而导致的一切后果。
        </p>
        <p>
          &emsp;
          2.亚洲电视将建立和维持合理的程序,以保护未成年人个人资料的保密性及安全性。本网站郑重声明:任何18岁以下的未成年人参加网上活动应事先得到家长或其法定监护人(以下统称为监护人")的可经查证的同意。监护人应承担保护未成年人在网络环境下的隐私权的首要责任。
        </p>
        <p>
          &emsp;
          3.亚洲电视收集未成年人的个人资料,仅为回复未成人特定要求的目的,一经回复完毕即从记录中删除,而不会保留这些资料做进一步的利用。
        </p>
        <p>
          &emsp;
          4.经监护人同意,我们可以收集未成年人的个人信息,但监护人有权拒绝亚洲电视进一步收集其子女或被监护人的人信息,有权审阅或要求删除该未成年人的个人信息。
        </p>
        <p>
          &emsp;
          5我们保证不会要求未成年人提供额外的个人资料,以作为允许其使用相关服务的条件。我们只会在法律法规允许、监护人明确同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。
        </p>
        <p>&emsp;八.您的个人信息如何在全球范围转移</p>
        <p>
          &emsp;
          1.原则上,我们在中华人民共和国境内运营中收集和产生的个人信息,存储在中华人民共和国境内。就中华人民共和国境外亚洲电视平台用户使用产品及服务过程中产生的个人信息,您同意回传存储在中国境內的服务器上,否则您无法使用现有的产品及服务。在此类情况下,我们会确保您的个人信息得到在中华人民共和国境内足够同等的保护。
        </p>
        <p>&emsp;九.本政策如何更新</p>
        <p>&emsp;1.我们的隐私政策可能变更。</p>
        <p>
          &emsp;2.未经您明确同意我们不会限制您按照本隐私政策所应享有的权利。
        </p>
        <p>
          &emsp;
          3.对于重大变更,我们还会提供更为显著的通知(包括我们会通过亚洲电视平台公示的方式进行意见征集、公告通知甚至向您提供弹窗提示。
        </p>
        <p>&emsp;4.本政策所指的重大变更包括但不限于：</p>
        <p>
          &emsp;
          (1)我们的服务模式发生重大变化。如处理用户信息的目的、用户信息的使用方式等；
        </p>
        <p>
          &emsp;
          (2)我们在控制权、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等;
        </p>
        <p>&emsp;(3)用户信息共享、转让或公开披露的主要对象发生变化；</p>
        <p>
          &emsp;
          (4)我们负责处理用户信息安全的责任部门、联络方式及投诉渠道发生变化时；
        </p>
        <p>&emsp;(5)用户信息安全影响评估报告表明存在高风险时。</p>
        <p>&emsp;十.如何联系我们</p>
        <p>
          &emsp;
          1.如果您对亚洲电视平台的隐私保护政策或数据处理有任何疑问、意见或建议,可以通过以下电子邮箱与我们联系般情况下,我们将在收到您相关联系信息后三十天內回复。我们联系方式如下：
        </p>
        <p>&emsp;电子邮箱:aifengshenghuo@163.com</p>
        <p>
          &emsp;
          2.如果您对我们的回复不满意,特别是您认为我们的个人信息处理行为损害了您的合法权益,您还可以通过向亚洲电视住所地有管辖权的人民法院提起诉讼来寻求解决方案。
        </p>
      </div>
    </div>
  );
}
export default User;
