import React, { useState, useCallback } from 'react'

function useRequest (fetchRequest, initState) {
  const [res, setRes] = useState(initState)
  const callMethod = o =>
    fetchRequest(o).then(r => {
      setRes(r)
      return r
    })
  const callRequest = useCallback(callMethod, [])
  return [callRequest, res]
}

export default useRequest
