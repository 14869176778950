import React, { useState, Fragment, useEffect } from 'react'
import { Toast } from 'antd-mobile'
import { getCanpurchasecount, getSysconfig, getProductGather } from '@/utils/api'
import { calcPrice, jumpTo, webviewShare, parseObj } from '@/utils/helper'
import useReducerContext from '@/reducer/userReducer'
import Empty from '@/components/Empty'
import useTitle from '@/hooks/useTitle'
import styles from './index.module.scss'
import banner from './assets/banner.png'
import icon from './assets/icon.png'
import icons from './assets/icons.png'

function LingYuanLing() {
	useTitle('免费领');
	const [datas, setDatas] = useState([])
	const [types, setTypes] = useState('')
	const [login, setLogin] = useState(false)
	const [gatherId, setGatherId] = useState('')
	webviewShare(
		'/lingYuanLing',
		'爆款好物每周免费领，款款可领！',
		require('./assets/share.png')
	)

	const handleGetDatas = (list, id) => {
		const itemIds = list.map(i => i.id)
		getCanpurchasecount({
			body: {
        itemIds,
        gatherId: id
      }
		}).then(caseData => {
			const data = list.map(i => ({...i, buy: Number(caseData[i.id]) || 0}))
			setLogin(false)
			setDatas(data)
		}).catch(err => {
			if (err.status === 401) {
				setLogin(true)
			} else setLogin(false)
		})
	}

	const handleGetData = async () => {
		const gatherData = await getSysconfig()
		const gather = gatherData.gather || {}
		setGatherId(gather.lyl)
		await getProductGather({
			query: {
				gatherId: gather.lyl,
				pageSize: 100,
				pageNo: 1,
			}
		}).then(res => {
			if (res.length) {
				setDatas(res)
			} else {
				setTypes('EMPTY')
			}
			handleGetDatas(res, gather.lyl)
		}).catch(() => {
			setTypes('EMPTY')
		})
	}

	useEffect(() => {
		handleGetData()
	}, [])

	const renderItem = item => {
		const { extraPrice } = item
		let extra = {}
		try {
			extra = JSON.parse(extraPrice) || {}
		} catch (error) {
			extra = {}
		}
		return (
			<div className={styles.itemBox}>
				<div className={styles.headBox}>
					<div className={styles.goodsNum}>
						<img src={icons} />
						<span>{parseObj(item.extra, `saleNum|${item.showNumSales}`)}人</span>
						已领取
					</div>
					<img src={item.thumbnailExtra || item.thumbnail} className={styles.goodsImage} />
					<img src={icon} className={styles.icon} />
				</div>
				<p className={styles.goodsName}>{item.name}</p>
				<p className={styles.goodsPrice}>
					<span>￥<span>{calcPrice(item.price)}</span></span>
					<span className={styles.old}>￥{calcPrice(extra.bazaar || 0)}</span>
				</p>
				<div className={styles.btnBox}>
					<div
						className={styles.btn}
						style={{ opacity: item.buy === 0 ? '0.6' : '1' }}
						onClick={() => {
							if (item.buy > 0 && !login) {
								jumpTo('productDetailSelf', { itemId: item.id, gatherId })
							} else if (login) {
								jumpTo('login')
							}
						}}
					>
						{item.buy === 0 ? '本周已领' : '免费领取'}
					</div>
					<div
						className={styles.btns}
						onClick={() => login ? jumpTo('login') : jumpTo('productDetailShare', { itemId: item.id })}
					>
						分享给好友领
					</div>
				</div>
			</div>
		)
	}
	return (
		<div className={styles.frees}>
			{datas.length > 0 ? (
				<Fragment>
					<img src={banner} className={styles.banner} />
					{datas.map(i => (
						<Fragment key={i.id}>{renderItem(i)}</Fragment>
					))}
				</Fragment>
			) : <Empty type={types} />}
		</div>
	)
}

function Index () {
  return (
    <useReducerContext.Provider>
      <LingYuanLing />
    </useReducerContext.Provider>
  )
}

export default Index
