import React from 'react';
import { TabBar } from 'antd-mobile';
import styles from './index.module.scss';
import useReactRouter from 'atv-use-react-router';
import PropTypes from 'prop-types';
function BottomNavBar(props) {
  const bottomLists = [
    {
      title: '首页',
      key: 'home',
      icon: require('./assets/home.png'),
      activeIcon: require('./assets/homeActive.png'),
      routeName: '/',
    },
    {
      title: '商城',
      key: 'gameInfo',
      icon: require('./assets/rule.png'),
      activeIcon: require('./assets/ruleActive.png'),
      routeName: '/gameInfo',
    },
    {
      title: '赚钱',
      key: 'apply',
      icon: require('./assets/apply.png'),
      activeIcon: require('./assets/apply.png'),
      routeName: '/playerReview',
    },
    {
      title: '下载',
      key: 'download',
      icon: require('./assets/live.png'),
      activeIcon: require('./assets/liveActive.png'),
      routeName: '/download',
    },
    {
      title: '我的',
      key: 'my',
      icon: require('./assets/my.png'),
      activeIcon: require('./assets/myActive.png'),
      routeName: '/my',
    },
  ];
  const router = useReactRouter();

  return (
    <div className={styles.navBar}>
      <TabBar
        unselectedTintColor="#949494"
        tintColor="#DABA7C"
        barTintColor="#242220"
        hidden={props.hidden}
        noRenderContent={true}
        tabBarPosition="bottom"
      >
        {bottomLists.map((value, index) => (
          <TabBar.Item
            title={value.title}
            key={value.key}
            icon={
              value.key === 'apply' ? (
                <div
                  style={
                    {
                      // background: `url(${value.icon}) center center /  21px 21px no-repeat`,
                    }
                  }
                  className={styles.navBarIcon}
                >
                  <div
                    style={{
                      background: `url(${value.icon})`,
                    }}
                    className={styles.applyNavBarIcon}
                  ></div>
                </div>
              ) : (
                <div
                  style={{
                    background: `url(${value.icon}) center center /  21px 21px no-repeat`,
                  }}
                  className={styles.navBarIcon}
                />
              )
            }
            selectedIcon={
              value.key === 'apply' ? (
                <div
                  style={
                    {
                      // background: `url(${value.icon}) center center /  21px 21px no-repeat`,
                    }
                  }
                  className={styles.navBarIcon}
                >
                  <div
                    style={{
                      background: `url(${value.activeIcon})`,
                    }}
                    className={styles.applyNavBarIcon}
                  ></div>
                </div>
              ) : (
                <div
                  style={{
                    background: `url(${value.activeIcon}) center center /  21px 21px no-repeat`,
                  }}
                  className={styles.navBarIcon}
                />
              )
            }
            selected={props.keyName === value.key}
            onPress={() => {
              router.history.push(
                value.routeName,
                value.key === 'gameInfo' ? { tabIndex: 0 } : {}
              );
            }}
          >
            {/* 内容展示区 */}
          </TabBar.Item>
        ))}
      </TabBar>
    </div>
  );
}
BottomNavBar.propTypes = {
  keyName: PropTypes.string.isRequired,
};

export default BottomNavBar;
