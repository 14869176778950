import React from 'react';
import Button from '@/components/Button';
import styles from './index.module.scss';

function ConfirmButton({ title, onClick = () => {} }) {
  const btnStyle = { color: 'red', backgroundColor: '#FDDB00', borderRadius: '0.07rem', width: '4.7rem', height: '0.59rem' };

  return (
    <div onClick={onClick} className={styles.buttonContainer}>
      <Button style={btnStyle}>
        {title}
      </Button>
      <div className={styles.buttonBottom}></div>
    </div>
  );
}

export default ConfirmButton;
