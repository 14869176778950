/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import styles from './index.module.scss';

import One from './assets/1.png';
import Two from './assets/2.png';
import Three from './assets/3.png';
import Four from './assets/4.png';
import Five from './assets/5.png';
import Sex from './assets/6.png';

function GameInfo() {
  return (
    <div className={styles.container}>
      <div className={styles.container}>
        <img className={styles.img} src={One} />
        <img className={styles.img} src={Two} />
        <img className={styles.img} src={Three} />
        <img className={styles.img} src={Four} />
        <img className={styles.img} src={Five} />
        <img className={styles.img} src={Sex} />
      </div>
      {/* <BottomNavBar keyName="playerReview" isApplyData={data} /> */}
    </div>
  );
}

export default GameInfo;
