import React, { useState } from 'react';
import { Tabs } from 'antd-mobile';
import styles from './index.module.scss';
import Contents from './components/Contents';
import useDebugPos, { BOTTOM_LEFT } from '@/hooks/useDebugPos';

function Exhibition({ match }) {
  const { params = {} } = match;
  const [tabs, setTabs] = useState(Number(params.id) || 0);
  useDebugPos(BOTTOM_LEFT);
  const tabList = [
    { title: '大数据推荐' },
    { title: '全网热销' },
    { title: '全网低价' },
    { title: '降价提醒' },
  ];

  return (
    <div className={styles.exhibitionBody}>
      <Tabs
        tabs={tabList}
        initialPage={tabs}
        tabBarTextStyle={{
          color: '#666',
          height: '0.5rem',
          userSelect: 'none',
          fontSize: '0.19rem',
        }}
        tabBarActiveTextColor="#1A1920"
        tabBarUnderlineStyle={{
          width: '0.68rem',
          height: '0.04rem',
          background: '#1A1920',
          left: `calc(${tabs * 25}% + 0.27rem)`,
          border: 'none',
        }}
        onChange={(tab, index) => setTabs(index)}
      >
        {tabs === 0 ? <Contents index={tabs} /> : ''}
        {tabs === 1 ? <Contents index={tabs} /> : ''}
        {tabs === 2 ? <Contents index={tabs} /> : ''}
        {tabs === 3 ? <Contents index={tabs} /> : ''}
      </Tabs>
    </div>
  );
}

export default Exhibition;
