// 接口路径前缀
export const ROOT_URL = '/api'
// H5固定前缀
export const H5_PREFIX = 'https://af.hkatv.vip'
export const H5_URL = 'https://srv-qbb.hkatv.vip'

export const API_PREFIX = process.env.NODE_ENV === 'production' ? H5_URL + ROOT_URL : ROOT_URL
export const IM_URL = ROOT_URL
export const IM_PATH = '/im'
export const OSS_PATH_NAME = 'xm'
export const AUTH_KEY = 'QUBIBI-TOKEN'
export const GAMEAREA_INFO_KEY = 'GAMEAREA'
export const UPLOAD_IMAGE_PLACEHOLDER = 'https://cdn.hkatv.vip/xm/upload-image-placeholder.jpg'
export const DEFAULT_GAMEAREA_INFO = {
  id: 1,
  attachmentUrl: 'https://cdn.hkatv.vip/xm/default-share-image.jpeg',
  name: '中国区总决赛',
  playerShareDesc: '快来支持我吧！',
  title: '亚洲小姐中国区'
}
// 客服微信号
export const KFWX = 'qubibi2019'

export const shareTitle = '一起来亚洲电视购物，笔笔返现，分享还能赚钱'
export const sharePath = '/pages/launch/index'
// 默认缩略图
export const shareDefaultImg = `${H5_PREFIX}${require('@/assets/fenxiang.png')}`
// 微信分享默认参数
export const WXShareObj = {
  title: shareTitle,
  path: sharePath,
  imageUrl: shareDefaultImg
}
// app分享默认参数
export const RNShareObj = {
  title: shareTitle,
  path: sharePath,
  thumbImageUrl: shareDefaultImg,
  webpageUrl: `${H5_PREFIX}/download`
}
