/* eslint-disable comma-dangle */
/* eslint-disable semi */
/* eslint-disable space-before-function-paren */
/* eslint-disable indent */
/* eslint-disable no-tabs */
import React, { useState, useRef } from 'react';
import styles from './index.module.scss';
import icons1 from './assets/icons_1.png';
import icons2 from './assets/icons_2.png';
import icons3 from './assets/icons_3.png';
import icons4 from './assets/icons_4.png';
import icons5 from './assets/icons_5.png';
import icons6 from './assets/icons_6.png';
import icons7 from './assets/icons_7.png';
import icons8 from './assets/icons_8.png';
import icons9 from './assets/icons_9.png';
import icons10 from './assets/icons_10.png';
import icons11 from './assets/icons_11.png';
import icons12 from './assets/icons_12.png';
import headback from './assets/head_backs.png';
import headshow from './assets/head_shows.png';

function Equity() {
	const opens = useRef();
	const openes = useRef();
	const [dis, setDis] = useState(false);
	const [show, setShow] = useState(false);
	const mainArr = [
		{ icon: icons1, name: '购物省钱', desc: '自购超低价' },
		{ icon: icons2, name: '分享赚钱', desc: '最高60%奖励' },
		{ icon: icons3, name: '海量优惠券', desc: '免费领取' },
		{ icon: icons8, name: '邀请奖励', desc: '无上限推广奖' },
	];
	const content = [
		{
			title: '省钱',
			list: [
				{ icon: icons1, name: '购物省钱', desc: '全场立减' },
				{ icon: icons2, name: '专享低价', desc: '店长专属' },
				{ icon: icons3, name: '海量优惠价', desc: '海量好货' },
				{ icon: icons4, name: '大牌优惠', desc: '海量好货' },
			],
		},
		{
			title: '赚钱',
			list: [
				{ icon: icons5, name: '专属分享赚钱', desc: '最高60%推广奖' },
				{ icon: icons6, name: '花粉合成', desc: '收益无限' },
				{ icon: icons7, name: '拉新奖励', desc: '立即到账' },
			],
		},
		{
			title: '专享',
			list: [
				{ icon: icons8, name: '专享活动', desc: '独享优惠' },
			],
		},
		{
			title: '服务',
			list: [
				{ icon: icons9, name: '0成本创业', desc: '轻松开店不囤货' },
				{ icon: icons10, name: '培训物料', desc: '推广更轻松' },
				{ icon: icons11, name: '爆款群好货', desc: '每日更新' },
				{ icon: icons12, name: '专属高级客服', desc: '专业贴心服务' },
			],
		},
	];
	const renderList = (list) => {
		return list.map((i, k) => {
			return (
				<div key={k} className={styles.listTD}>
					<div>
						<img src={i.icon} alt="" />
					</div>
					<p>{i.name}</p>
					<p>{i.desc}</p>
				</div>
			);
		});
	};
	const renderShow = () => {
		return (
			<div
				ref={opens}
				className={`${show ? styles.openshow : styles.openhide} ${styles.listBody}`}
			>
				{!show && (
					<div
						className={styles.listTR}
						style={show ? {} : { justifyContent: 'center' }}
					>
						{renderList(mainArr)}
					</div>
				)}
				<p
					className={styles.show}
					onClick={() => {
						if (dis) return;
						setDis(true);
						setTimeout(() => {
							opens.current.style.height = !show ? '5.2rem' : '1.6rem';
							opens.current.className = styles.listBody;
							openes.current.style.height = !show ? '0.88rem' : '1.4rem';
							openes.current.className = styles.headshow;
							setDis(false);
						}, 285);
						setShow(!show);
					}}
					style={show ? { marginBottom: '0.26rem' } : { marginTop: '0.22rem' }}
				>
					{show ? '收起' : '查看'}全部店长权益
				</p>
				{show && content.map((i, k) => {
					return (
						<div key={k} className={styles.listTR}>
							<div className={styles.title}>{i.title}</div>
							{renderList(i.list)}
						</div>
					);
				})}
			</div>
		);
	};
	return (
		<div
			className={styles.equityBody}
			style={{ backgroundImage: `url(${headback})` }}
		>
			<div
				className={`${show ? styles.openshows : styles.openhides} ${styles.headshow}`}
				style={{ backgroundImage: `url(${headshow})` }}
				ref={openes}
			>
				店长超级权益
				<p>购物预计可省16666/年  分享最高可赚40%的奖金</p>
			</div>
			{renderShow()}
		</div>
	);
}

export default Equity;
