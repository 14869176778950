import React, { memo } from 'react';
import styles from './index.module.scss';

function DetailImage({ productDetail }) {
  const imgList = productDetail.persentation ? productDetail.persentation.split(',') : [];
  return (
    <div className={styles.detailContainer}>
      <div className={styles.detailHeader}>
        <img
          className={styles.detailIcon}
          src={require('./assets/detail_icon.png')}
          alt=""
        />
        <div className={styles.detailHeaderTitle}>宝贝详情</div>
        <img
          className={`${styles.detailIcon} ${styles.detailIconRight}`}
          src={require('./assets/detail_icon.png')}
          alt=""
        />
      </div>
      <div>
        {imgList.map((img, i) => {
          return <img className={styles.detailImg} src={img} alt="" key={i} />;
        })}
      </div>
    </div>
  );
}

export default memo(DetailImage);
