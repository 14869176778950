import React from 'react';
import Divider from '@/components/Divider';
import styles from './index.module.scss';
import useTitle from '@/hooks/useTitle';

function GongLueE() {
  useTitle('微信朋友圈运营技巧');
  return (
    <div className={styles.container}>
      <Divider height={'0.13rem'} />
      <div className={styles.title}>第一步：朋友圈做铺垫</div>
      <Divider height={'0.17rem'} />
      <div className={styles.subTitle}>举例文案：</div>
      <div>1.在家带娃还能赚小钱，我要开始我的人生新起航了，为孩子提供更好的物质生活，也为孩子树立积极向上的学习榜样。加亚洲电视的海报</div>
      <Divider height={'0.21rem'} />
      <div>2.我要开启一份兼职！</div>
      <div>0投资！0风险！不压货！</div>
      <div>一部手机，碎片时间创造财富增值！</div>
      <div>8小时内谋生存，8小时外谋发展！</div>
      <div>亚洲电视，让我遇见更自信的自己！</div>
      <div>亚洲电视，让我增创副业收入！！</div>
      <Divider height={'0.44rem'} />
      <div className={styles.title}>第二步：熟悉购买流程，勤快反馈</div>
      <Divider height={'0.14rem'} />
      <div>大家不急着卖货，建议先了解平台的下单规则，自己先下单测试下购物的过程。收到货后，以分享的心态拍摄实物图，在朋友圈做事实反馈。可以用实物图，可以用视频，加上自己的切身体会，对产品的感知，让群友得到真实的感受，明白亚洲电视平台真的又省钱又好。</div>
      <Divider height={'0.44rem'} />
      <div className={styles.title}>第三步：朋友圈产品分享方式：</div>
      <Divider height={'0.15rem'} />
      <div>1.可以利用九宫格，把一个系列的产品一条信息分享，比如早餐的面包，牛奶，小零食</div>
      <Divider height={'0.21rem'} />
      <div>2.可以一条朋友圈只分享单一一个产品，可以产品图加实物图</div>
      <Divider height={'0.21rem'} />
      <div>3.如果微信群有搞什么活动时，可以海报加文案</div>
      <Divider height={'0.21rem'} />
      <div>4.建群初期，可以发新人首单0元购海报或链接，吸引大家注意。</div>
      <Divider height={'0.43rem'} />
      <div className={styles.title}>第四步：朋友圈招募会员或店长</div>
      <Divider height={'0.16rem'} />
      <div>一周大概2-3次，可以分享自己的实际收入截图，可以分享自己一边游玩一边做兼职，可以分享自己加入后的收获心得，成长心得。</div>
      <Divider height={'0.44rem'} />
      <div className={styles.title}>第五步：朋友圈发送技巧</div>
      <Divider height={'0.15rem'} />
      <div>1.发品主文案控制在6行，否则会出现折叠，降低发品效果,也可以选择搜狗输入法</div>
      <Divider height={'0.21rem'} />
      <div>2.朋友圈商品文案和图片是自己转链之后的文案及商品海报</div>
      <Divider height={'0.21rem'} />
      <div>3.编辑好朋友圈，在最底部可以设置提醒给谁看，主要为此产品的主要目标用户和意向用户</div>
      <Divider height={'0.21rem'} />
      <div>4.利用评论。发送完毕，自己可以在底部评论:</div>
      <Divider height={'0.28rem'} />
      <div><span className={styles.subTitle}>例如：</span></div>
      <Divider height={'0.21rem'} />
      <div>A:购买方法:点开图片长按识别图中小程序，领取优惠券之后填写个人信息和收货地址，最后微信支付下单，可在亚洲电视购物小程序查看物流信息。</div>
      <Divider height={'0.21rem'} />
      <div>B：邀请别人进群，如：想进群省群淘好货的打1，我拉你</div>
      <Divider height={'0.21rem'} />
      <div>C：自己对话如：好，我今晚8点帮你拍4套四件套；这个卖了几十份，质量收到很好。</div>
      <Divider height={'0.44rem'} />
      <div className={styles.title}>第六步：朋友圈的整理</div>
      <Divider height={'0.15rem'} />
      <div>定期整理朋友圈，两天删除一遍之前发的内容。保持新鲜，同时朋友圈每天插入一两条生活化内容。让人觉得有血有肉。</div>
      <Divider height={'0.15rem'} />
    </div>
  );
}

export default GongLueE;
