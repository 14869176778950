import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import ListView from '@/components/ListView'
import useTitle from '@/hooks/useTitle'
import { H5_PREFIX } from '@/utils/constants'
import {
  getSysconfig,
  getMyInfo,
  getDeductionInfo,
  getProductGather,
  getGathers
} from '@/utils/api'
import { jumpTo, isRN, shareRN } from '@/utils/helper'
import { getAuth } from '@/utils/app'
import useReducerContext from '@/reducer/userReducer'
import ProductItem from './components/ProductItem'
import ShareModal from './components/ShareModal'
import NewUserModal from './components/NewUserModal'
import ConfirmButton from './components/ConfirmButton'
import styles from './index.module.scss'

function LingYuanGou () {
  const { sysconfigs, updateState, userData } = useReducerContext()
  const { gather } = sysconfigs || {}
  const { lyg: gatherLyg } = gather || {}

  const [tabIndex, setTabIndex] = useState(0)
  const { inviteCode } = userData || {}
  const [userModalVisible, setUserModalVisible] = useState(false)
  const [shareModalVisible, setShareModalVisible] = useState(false)
  const [isLogin, setIsLogin] = useState(false)
  const [isOldUser, setIsOldUser] = useState(true)
  const [deduction, setDeduction] = useState({
    consumableDeduction: 0,
    lockedDeduction: 0,
    totalDeduction: 0
  })
  const [title, setTitle] = useState('新人0元购')
  const [productList, setProductList] = useState([])
  useTitle(title)
  const auth = getAuth()

  useEffect(() => {
    Promise.all([getSysconfig(), getGathers()]).then(([sysConfigs, gathersInfo]) => {
      updateState('sysconfigs', sysConfigs)
      updateState('gathers', gathersInfo)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (auth) {
      Promise.all([getMyInfo(), getDeductionInfo()])
        .then(([userData, deductionInfo]) => {
          setIsLogin(true)
          updateState('userData', userData)
          setDeduction(deductionInfo)
          // 标记为新用户,可以跳零元购
          if (userData.isOldUser === 0) {
            setIsOldUser(false)
          }
        })
        .catch(e => {
          // 拉用户信息失败也跳零元购
          setIsOldUser(false)
        })
    } else {
      // 未登录时跳到零元购
      setIsOldUser(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const title = '快来薅羊毛，新人首单0元！'
    const path = `/pages/launch/index?pg=webview&invite=${inviteCode}&src=${H5_PREFIX}/lingYuanGou`
    const thumbnail = `${H5_PREFIX}${require('./assets/LingYuanHeader.png')}`
    const WXParams = {
      title,
      path,
      imageUrl: thumbnail
    }
    window.wx.miniProgram.postMessage({
      data: WXParams
    })
    if (isRN()) {
      const webpageUrl = `${H5_PREFIX}/download?code=${inviteCode}`
      const params = {
        title,
        path,
        thumbImageUrl: thumbnail,
        webpageUrl
      }
      shareRN(params)
    }
  }, [inviteCode])

  useEffect(() => {
    gatherLyg &&
      getProductGather({
        query: {
          gatherId: gatherLyg,
          pageSize: 100,
          pageNo: 1
        }
      })
        .then(res => setProductList(res))
        .catch(e => {})
    // eslint-disable-next-line
  }, [gatherLyg]);

  const handleUserModalClose = () => {
    jumpTo('home')
  }

  // 抢
  const handleGrab = itemId => {
    if (isLogin) {
      if (isOldUser) {
        setUserModalVisible(true)
      } else {
        jumpTo('productDetailSelf', { itemId, gatherId: gatherLyg })
      }
    } else {
      jumpTo('login')
    }
  }

  const handleShareVisible = visible => {
    setShareModalVisible(visible)
  }

  const handleUserVisible = visible => {
    setUserModalVisible(visible)
  }

  const handleTabSwitch = index => {
    if (index === 0) {
      setTitle('新人0元购')
    } else {
      setTitle('新人礼-首单0元购')
    }
    setTabIndex(index)
  }

  const renderFooterItem = (index, title) => {
    return (
      <div
        onClick={() => handleTabSwitch(index)}
        className={classNames(styles.footerItem, tabIndex === index && styles.tabActive)}
      >
        {title}
      </div>
    )
  }

  const renderProductItem = item => {
    return <ProductItem handleGrab={handleGrab} item={item} gatherId={gatherLyg} />
  }

  const renderLeftTab = () => {
    return (
      <div style={{ display: tabIndex === 0 ? 'block' : 'none' }}>
        <img
          src={require('./assets/LingYuanHeader.png')}
          style={{ width: '4.99rem', height: '4.15rem' }}
          alt=""
        />
        <ListView
          className={styles.listWrapper}
          renderSeparator={null}
          data={productList}
          renderRow={renderProductItem}
        />
        <div onClick={() => {}} className={styles.buttonContainer}>
          <ConfirmButton onClick={() => handleShareVisible(true)} title="分享好友0元购" />
        </div>
        <div style={{ height: '0.41rem' }} />
      </div>
    )
  }

  const { consumableDeduction, totalDeduction } = deduction

  const renderRightTab = () => {
    return (
      <div style={{ display: tabIndex === 1 ? 'block' : 'none' }}>
        <div className={styles.awardDivider} />
        <div className={styles.awardContainer}>
          <div className={styles.awardTopContainer}>我的补贴</div>
          <div className={styles.awardBottomContainer}>
            <div className={styles.awardInfoContainer}>
              <div className={styles.awardInfoPrice}>¥{totalDeduction / 100}</div>
              <div className={styles.awardInfoTitle}>累计入账</div>
            </div>
            <div className={styles.bottomDivider} />
            <div className={styles.awardInfoContainer}>
              <div className={styles.awardInfoPrice}>¥{consumableDeduction / 100}</div>
              <div className={styles.awardInfoTitle}>余额</div>
            </div>
          </div>
        </div>
        <div className={styles.useBtn}>
          <ConfirmButton onClick={() => jumpTo('home')} title="立即使用" />
        </div>
        <div className={styles.ruleText}>
          <span>活动规则</span>
          <span className={styles.rule}>
            1、本次活动仅限本专题内的商品，商城其他商品不参与此活动；
          </span>
          <span className={styles.rule}>
            2、本次活动仅限亚洲电视平台首次下单用户，每人限购1次，同一账号视为同一用户；
          </span>
          <span className={styles.rule}>
            3、新用户本次活动中所获得的购物金将在完成支付后返回到购物金账户，状态为“入账中”，用户确认收货后，购物金入账成功，可以开始使用，用户可在我的--购物金页面查看余额；
          </span>
          <span className={styles.rule}>
            4、若经举报或平台技术检测发现用户存在作弊行为，则用户在本次活动中的资格将自动取消，已经返还的购物金亦将自动失效。若用户的作弊行为给其他用户、合作伙伴或平台造成损失，用户需承担相应的法律责任。
          </span>
          <span className={styles.rule}>
            5、亚洲电视可以根据实际情况对本活动规则进行变动或调整，相关变动或调整将公布在此页面，公布后依法生效。本活动解释权最终归亚洲电视平台所有。
          </span>
        </div>
      </div>
    )
  }

  const renderFooter = () => {
    return (
      <div className={styles.footerContainer}>
        {renderFooterItem(0, '新人0元购')}
        {renderFooterItem(1, '我的补贴')}
      </div>
    )
  }

  return (
    <div className={styles.container}>
      {renderLeftTab()}
      {renderRightTab()}
      {renderFooter()}
      <NewUserModal
        visible={userModalVisible}
        handleVisible={handleUserVisible}
        handleJump={handleUserModalClose}
      />
      <ShareModal visible={shareModalVisible} handleVisible={handleShareVisible} />
    </div>
  )
}

function Index () {
  return (
    <useReducerContext.Provider>
      <LingYuanGou />
    </useReducerContext.Provider>
  )
}

export default Index
