import React, { useEffect, useState, Fragment } from 'react';
import Titles from '../Titles';
import styles from './index.module.scss';
import star211 from './assets/star_21_1.png';
import star212 from './assets/star_21_2.png';
import star213 from './assets/star_21_3.png';
import star221 from './assets/star_22_1.png';
import star222 from './assets/star_22_2.png';
import star223 from './assets/star_22_3.png';
import star111 from './assets/star_11_1.png';
import star112 from './assets/star_11_2.png';
import star113 from './assets/star_11_3.png';

function StarList({ info }) {
  const [card, setCard] = useState({});
  const levelArr = [
    {
      level: [21],
      title: '店长故事',
      types: false,
      nameColor: '#333333',
      posiColor: '#2E2F30',
      contColor: '#7D4B2D',
      headBack: '#F5DCB9',
      data: [
        {
          name: '梁 文',
          posi: '自媒体达人',
          reason: '无需囤货一件代发，真正的低风险低投入',
          result: '亚洲电视平台既有趣又好玩，让我拥有了一个有想法且有冲劲的社群团队，一起为梦想奋斗吧！',
          headImg: star211,
        },
        {
          name: '王小悠',
          posi: '服装店店主',
          reason: '一次建群，永久躺赚的好机会，而且单品爆款性价比高，佣金可观！',
          result: '在亚洲电视创建了2019十佳实习社群，成为更有影响力的人！',
          headImg: star212,
        },
        {
          name: '黄喜喜',
          posi: '奶茶店员工',
          reason: '工作之余，想做一份副业，因此来到亚洲电视',
          result: '亚洲电视网购很划算省钱，分享好友还能赚钱，商品性价比也很高，我的团队日益壮大，收益也很可观。',
          headImg: star213,
        },
      ],
    },
    {
      level: [22, 23, 31, 32],
      title: '明星导师',
      types: true,
      nameColor: '#8E765F',
      posiColor: '#8E765F',
      contColor: '#BF9064',
      headBack: '#F5DCB9',
      data: [
        {
          name: '肖 肖',
          posi: '外企白领',
          reason: '想找副业，亚洲电视平台专业培训简洁易懂，逆袭翻盘。',
          result: '实现月入过万，技能得到更大提升。',
          messages: '亚洲电视就是简单听话照做，相信相信的力量，希望更多像我这样的人，跟着亚洲电视可以改变命运！！！',
          headImg: star221,
        },
        {
          name: '蔡小花',
          posi: '会计',
          reason: '大城市小白领，工作生活亮点一线，不高的月薪，想尝试突破自己，打开社交圈。',
          result: '结实到志同道合的团队小伙伴，兼职收入倍增，人脉瞬间开阔。',
          messages: '亚洲电视让我遇见更好的自己！！！',
          headImg: star222,
        },
        {
          name: '程 晨',
          posi: '自主创业者',
          reason: '高级人才，有人脉，有资源，有想法，微商遇挫后发展停滞，在亚洲电视平台重新打磨自己，打造自己的团队！',
          result: '三个月打造25000+人的团队',
          messages: '我相信，我只要坚定跟着平台去成长，一定可以再创辉煌，加油，有梦想的伙伴，一起来，更精彩！！！',
          headImg: star223,
        },
      ],
    },
    {
      level: [11],
      title: '店长故事',
      types: false,
      nameColor: '#333333',
      posiColor: '#666666',
      contColor: '#755B12',
      headBack: '#FFF1CB',
      data: [
        {
          name: '孙 艳',
          posi: '自媒体达人',
          reason: '工作之余，新的副业让我生活更充实更有趣',
          result: '成为亚洲电视店长后扩大了自己的圈子，结交很多同行好友，也有了第一个自己的团队、开心、满足。',
          headImg: star111,
        },
        {
          name: '丁 志',
          posi: '白领',
          reason: '价格便宜，质量也不错',
          result: '成为亚洲电视店长后，分享给了很多好友，同时也一起加入，如今作为自己的主要副业。',
          headImg: star112,
        },
        {
          name: '王佳佳',
          posi: '大二学生',
          reason: '生活费总是不够花，在亚洲电视开启副业之旅',
          result: '成为亚洲电视店长后，平台为我提供很专业的培训，学业之余，副业的收入渐渐提高了我的生活水平。',
          headImg: star113,
        },
      ],
    },
  ];

  useEffect(() => {
    setCard(levelArr.find(i => i.level.includes(info.level)));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderLists = () => {
    return card.data.map((i, k) => {
      return (
        <Fragment key={k}>
          <div key={k} className={styles.starBox} >
            <div
              className={styles.header}
              style={{ background: card.headBack }}
            >
              <img
                src={i.headImg}
                className={styles.headImg}
                style={{ float: k % 2 === 1 ? 'right' : 'left' }}
                alt=""
              />
              <span
                style={{
                  fontWeight: 'bold',
                  color: card.nameColor,
                  float: k % 2 === 1 ? 'right' : 'left',
                  margin: k % 2 === 1 ? '0 -0.1rem 0 0.13rem' : '0 0.13rem 0 -0.1rem',
                }}
              >
                {i.name}
              </span>
              <span
                className={styles.posi}
                style={{
                  color: card.posiColor,
                  float: k % 2 === 1 ? 'right' : 'left',
                }}
              >
                {i.posi}
              </span>
            </div>
            <div className={styles.content}>
              <p className={styles.title} style={{ color: card.contColor }}>加入原因</p>
              <p className={styles.cont} style={{ color: card.contColor }}>{i.reason}</p>
              <p className={styles.title} style={{ color: card.contColor }}>推广成果</p>
              <p className={styles.cont} style={{ color: card.contColor }}>{i.result}</p>
              {i.messages && (
                <>
                  <p className={styles.title} style={{ color: card.contColor }}>寄语</p>
                  <p className={styles.cont} style={{ color: card.contColor }}>{i.messages}</p>
                </>
              )}
            </div>
          </div>
          {k < card.data.length - 1 && <div style={{ height: '0.22rem' }} />}
        </Fragment>
      );
    });
  };

  return (
    <>
      <Titles
        title={card.title}
        type={card.types}
        desc={
          <span style={{ color: info.level === 11 ? '#302F2F' : '#A0795B' }}>
            加入亚洲电视和
            <span style={{ color: info.level === 11 ? '#302F2F' : '#7D4B2D', fontWeight: 'bold' }}>“百万会员”</span>
            一起赚钱
          </span>
        }
        color={info.level === 11 ? '#31302F' : '#7F4F32'}
      />
      {card.data && renderLists()}
    </>
  );
}

export default StarList;
