import React, { memo, useEffect, useCallback, useState } from 'react';
import useReactRouter from 'atv-use-react-router';
import { getSelfProductDetail } from '@/utils/api';
import { Icon } from 'antd-mobile';
import ModalSku from '@/components/ModalSku';
import ModalShare from '@/components/ModalShare';
import BannerSwiper from './components/BannerSwiper';
import ProductInfo from './components/ProductInfo';
import DetailImage from './components/DetailImage';
import Footer from './components/Footer';
import { initSku } from '@/utils/skuHelper';
import styles from './index.module.scss';

function ProductDetailSelf() {
  const router = useReactRouter();
  const itemId = router.match.params.itemId;
  const [skuVisible, setSkuVisible] = useState(false);
  const [shareVisible, setShareVisible] = useState(false);
  const [attrs, setAttrs] = useState([]);
  const [data, setData] = useState({});
  const [SKUResult, setSKUResult] = useState({});
  const [skuNull, setSkuNull] = useState(false);
  const [attrText, setAttrText] = useState('');
  const [attrPair, setAttrPair] = useState('');
  const [productDetail, setProductDetail] = useState({
    productImages: null,
    price: '',
    vipPrice: '',
    numSales: '',
    returnProfit: '',
    name: '',
    Attrs: [],
    Skus: [],
    distribution: 'distribution.normal',
    persentation: null,
  });
  const handleSkuVisible = visible => {
    setSkuVisible(visible);
  };

  const handleShareVisible = visible => {
    setShareVisible(visible);
  };

  const checkSkuNull = Attrs => {
    return Attrs.every(attr => Boolean(attr.value));
  };

  const getProductDetail = useCallback(async () => {
    try {
      const res = await getSelfProductDetail({
        params: {
          id: itemId,
        },
      });
      const newProductDetail = Object.assign({}, res, {
        productImages: res.medias
          ? JSON.parse(res.medias).map(({ u }) => u)
          : [],
      });
      setProductDetail(newProductDetail);
      const { Attrs, Skus } = res;
      const isSkuNull = !checkSkuNull(Attrs);
      if (isSkuNull) {
        setSkuNull(true);
      } else {
        const { newSKUResult, newData, newAttrs, attrText } = initSku(
          Attrs,
          Skus
        );
        setSKUResult(newSKUResult);
        setData(newData);
        setAttrs(newAttrs);
        setAttrText(attrText);
      }
    } catch (e) {}
  }, [itemId]);
  useEffect(() => {
    getProductDetail();
  }, [getProductDetail]);

  const handleTagChange = params => {
    const { attrText, itemInfo, attrPair } = params;
    setAttrText(attrText);
    setAttrs(itemInfo);
    setAttrPair(attrPair);
  };

  const renderSelect = () => {
    return (
      !skuNull &&
      !isDefaultSku && (
        <div
          onClick={() => handleSkuVisible(true)}
          className={styles.selectWrapper}
        >
          <div className={styles.skuSelectContainer}>
            <div className={styles.selectText}>选择</div>
            <div className={styles.selectRightContainer}>
              <span>{attrText}</span>
              <div className={styles.rightIcon}>
                <Icon color="#999999" type="right" size="md" />
              </div>
            </div>
          </div>
          <div className={styles.skuSelectContainer}>
            <div className={styles.selectText}>邮费</div>
            <div
              className={styles.selectRightContainer}
              style={{ border: 'none' }}
            >
              包邮
            </div>
          </div>
        </div>
      )
    );
  };

  const { Attrs, Skus } = productDetail;

  const selectedSku = data[attrPair];
  // 是否为默认sku
  const isDefaultSku =
    (Array.isArray(Attrs) && Attrs.length > 0 && Attrs[0]['id'] === 1) ||
    (Array.isArray(Skus) && Skus.length === 1);
  // eslint-disable-next-line
  const currentSku = isDefaultSku ? Skus[0] : selectedSku || productDetail;
  // sku缩略图
  return (
    <div className={styles.container}>
      <img
        src={require('./assets/back.png')}
        className={styles.backIcon}
        alt=""
      />
      <BannerSwiper productDetail={productDetail} />
      <ProductInfo productDetail={productDetail} />
      {renderSelect()}
      <DetailImage productDetail={productDetail} />
      <Footer
        productDetail={productDetail}
        handleSkuVisible={handleSkuVisible}
        handleShareVisible={handleShareVisible}
      />
      <ModalSku
        isDefaultSku={isDefaultSku}
        attrs={attrs}
        attrPair={attrPair}
        attrText={attrText}
        currentSku={currentSku}
        skuMap={data}
        SKUResult={SKUResult}
        productDetail={productDetail}
        visible={skuVisible}
        handleVisible={handleSkuVisible}
        handleTagChange={handleTagChange}
      />
      <ModalShare
        visible={shareVisible}
        handleVisible={handleShareVisible}
      />
    </div>
  );
}

export default memo(ProductDetailSelf);
