/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import styles from './index.module.scss';
function My(props) {
  return (
    <div className={styles.fullScreen}>
      <div className={styles.container}>
        <h1>
          <strong>
            <strong>线上规则说明</strong>
          </strong>
        </h1>
        <h2>
            一、会员权益（蜂王页说明）
        </h2>
        <p>
          <strong>
            <strong>条件：</strong>
          </strong>
        </p>
        <p>
          <strong>
            <strong>大黄蜂：注册即可</strong>
          </strong>
        </p>
        <p>
          <strong>
            <strong>蜂王：购买399会员礼包</strong>
          </strong>
        </p>
        <img className={styles.img} src={require('./assets/bg1.png')} />
        <p>
          <strong>
            <strong>至尊蜂王：</strong>
          </strong>
        </p>
        <p>
          <strong>
            <strong>权益：</strong>
          </strong>
        </p>
        <img className={styles.img} src={require('./assets/bg2.png')} />
        <p>
          <strong>
            <strong>&nbsp;</strong>
          </strong>
        </p>
        <h2>二、APP说明
        </h2>
        <h2>
          <strong>
            <strong>1、我的--订单</strong>
          </strong>
        </h2>
        <p>
          <strong>
            <strong>位置：</strong>
          </strong>
        </p>
        <img className={styles.img} src={require('./assets/1.png')} />
        <p>
          <strong>
            <strong>&nbsp;</strong>
          </strong>
        </p>
        <p>
          <strong>
            <strong>&nbsp;</strong>
          </strong>
        </p>
        <p>
          <strong>
            <strong>订单常见问题</strong>
          </strong>
          <strong>
            <strong>：</strong>
          </strong>
        </p>
        <p>
          <strong>
            <strong>
              订单无奖励/订单奖励不符，请第一时间联系在线客服处理或及时退款成功后重新下单。
            </strong>
          </strong>
        </p>
        <p>1、亚洲电视正确下单流程是什么？</p>
        <p>
          在亚洲电视首页搜索框，复制淘宝口令跳转至淘宝领券进入淘宝商品页面，选择商品，进行下单购买。（付款前请勿跳转至其他商品页或店铺页，会导致无奖励发放）
        </p>
        <p>2、购物返花粉结算规则是什么？</p>
        <p>
          购物完成订单后花粉自动进入冻结花粉账户，每月25日结算上月【确认收货】的订单，花粉可以兑换蜂蜜（蜂王权限）。
        </p>
        <p>例如：</p>
        <p>
          您在7月通过爱蜂生活链接到淘宝购物成功，花粉进入冻结花粉账户，8月份在淘宝点击确认收货，9月25日此部分花粉解冻。
        </p>
        <ul>
          <li className={styles.list}>什么情况下无法获得奖励？</li>
        </ul>
        <ol>
          <li className={styles.list}>已在淘宝下单并付款的订单，再到亚洲电视查询购买，无奖励发放；</li>
          <li className={styles.list}>付款时不要使用红包和购物券支付钉扣，会导致无奖励发放；</li>
        </ol>
        <ul>
          <li className={styles.list}>为什么我获得的奖励与购买时显示的不一致？</li>
        </ul>
        <ol>
          <li className={styles.list}>
            如果您在交易过程中有退款或退货的操作，也将影响您最终获得的奖励；
          </li>
          <li className={styles.list}>
            订单奖励以商品详情页展示的奖励为参考值，最终奖励以实际发放为准。
          </li>
        </ol>
        <ul>
          <li className={styles.list}>退货退款/部分退款/售后维权，还有奖励吗？</li>
        </ul>
        <ol>
          <li className={styles.list}>
            若订单发生退货、退款、售后维权等情形导致订单最终关闭，则无奖励；
          </li>
          <li className={styles.list}>
            若订单发生部分退款，且订单状态不是关闭的，则按照用户实际付款金额进行奖励；
          </li>
        </ol>
        <p>注意：</p>
        <p>1、所有订单数据以淘宝官方返回数据为准。</p>
        <p>
          2、您点击确认收货之后仍在售后服务期限内，可能享有7天或30天无理由退货，立即返佣再产生退货维权，会给卖家和平台造成巨大损失，因此次月结算花粉，还请谅解。
        </p>
        <p>待奖励：通过爱蜂生活链接到淘宝完成下单，但是还未结算花粉的订单。</p>
        <p>产生退货退款等售后维权订单不会返回花粉，会从待奖励订单中剔除。</p>
        <p>已完成：已完成购买且花粉已解冻的订单。</p>
        <p>订单无奖励/订单奖励不符等，请联系在线客服。</p>
        <h2>
          <strong>
            <strong>2、我的--冻结花粉说明</strong>
          </strong>
        </h2>
        <p>
          <strong>
            <strong>位置：</strong>
          </strong>
        </p>
        <img className={styles.img} src={require('./assets/2.png')} />
        <p>
          <strong>
            <strong>&nbsp;</strong>
          </strong>
        </p>
        <p>
          <strong>
            <strong>详细说明：</strong>
          </strong>
        </p>
        <ul>
          <li className={styles.list}>淘宝订单确认收货后的第16天开始解冻，最晚于次月25日解冻完成</li>
        </ul>
        <p>
          购物完成订单后花粉自动进入冻结花粉账户，每月25日结算上月【确认收货】的订单，花粉可以兑换蜂蜜（蜂王权限）。
        </p>
        <p>例如： &nbsp;</p>
        <p>
          您在7月通过爱蜂生活链接到淘宝购物成功，花粉进入冻结花粉账户，8月份在淘宝点击确认收货，9月25日此部分花粉解冻。
        </p>
        <ul>
          <li className={styles.list}>自营订单确认收货之后第8天解冻</li>
        </ul>
        <p>
          <strong>
            <strong>&nbsp;</strong>
          </strong>
        </p>
        <h2>
          <strong>
            <strong>3、钱包&mdash;冻结现金说明</strong>
          </strong>
        </h2>
        <p>
          <strong>
            <strong>位置：</strong>
          </strong>
        </p>
        <img className={styles.img} src={require('./assets/3.png')} />
        <p>
          <strong>
            <strong>&nbsp;</strong>
          </strong>
        </p>
        <p>
          <strong>
            <strong>&nbsp;</strong>
          </strong>
        </p>
        <p>
          <strong>
            <strong>详细说明：</strong>
          </strong>
        </p>
        <p>
          购物完成订单后返现自动进入冻结金额账户，每月25日结算上月【确认收货】的订单，已解冻金额可提现。
        </p>
        <p>例如：</p>
        <p>
          您在7月通过爱蜂生活链接到淘宝购物成功，返现进入冻结金额账户，8月份在淘宝点击确认收货，9月25日此部分现金解冻可提现。
        </p>
        <p>&nbsp;</p>
        <h2>
          <strong>
            <strong>4</strong>
          </strong>
          <strong>
            <strong>、我的/首页--邀请好友</strong>
          </strong>
        </h2>
        <img className={styles.img} src={require('./assets/4.png')} />
        <img className={styles.img} src={require('./assets/5.png')} />
        <img className={styles.img} src={require('./assets/6.png')} />
        <p>
          <strong>
            <strong>&nbsp;</strong>
          </strong>
        </p>
        <p>
          <strong>
            <strong>&nbsp;</strong>
          </strong>
        </p>
        <p>
          <strong>
            <strong>详细说明：</strong>
          </strong>
        </p>
        <p>邀请方式：</p>
        <p>【方式1】复制邀请码发给好友，需在下载APP注册时填写；</p>
        <p>【方式2】转发邀请海报，好友下载APP注册时填写海报上的邀请码即可；</p>
        <p>
          【方式3】复制邀请链接发送给好友，下载APP注册时，使用连接后的邀请码。
        </p>
        <p>*最终解释权归爱蜂科技所有。</p>
        <p>好友下载APP并使用您的邀请码成功注册会员，TA将永久成为您的粉丝。</p>
        <p>关于我们</p>
        <p>
          亚洲电视是一款针对线上消费服务的社交应用类圈层电商app。主要为用户提供产品化与内容化的综合服务，主要产品形态是移动端app，适宜碎片化时代的应用场景，通过社交分享，链接资源等。
        </p>
        <p>&nbsp;</p>
        <p>
          亚洲电视是一款针对线上消费服务的社交应用类圈层电商app，无论新老用户，均可下单购物并获得邀请好友现金红包，需根据规则邀请好友，即可对现金进行提现，当好友足够多时，完全可以实现创业之路。且平台汇集百货、食品、服饰、美妆、家电、母婴等十余种大类近百种小类，所包含的产品覆盖用户生活、工作等各方面场景，完全满足您的需求。
        </p>
        <p>注册验证码</p>
        <p>
          【亚洲电视】您正在进行亚洲电视账户注册，如确认无误请输入手机动态验证码：12345，有效时间2分钟。请不要泄露给他人，以免造成账号被盗风险。
        </p>
        <p>&nbsp;</p>
        <p>登陆验证码+绑定手机+验证手机号</p>
        <p>
          【亚洲电视】验证码12345，您正在进行亚洲电视身份验证，打死不要告诉别人哦！
        </p>
        <p>&nbsp;</p>
        <p>绑定银行卡+解绑银行卡</p>
        <p>
          【亚洲电视】您正在进行亚洲电视银行卡绑定操作，如确认无误请输入手机动态验证码：12345，有效时间2分钟。请不要泄露给他人，以免造成账号被盗风险。
        </p>
        <p>&nbsp;</p>
      </div>
    </div>
  );
}
export default My;
