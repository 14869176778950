import React from 'react';
import useTitle from '@/hooks/useTitle';
import Divider from '@/components/Divider';
import styles from './index.module.scss';

function GongLueB() {
  useTitle('群日常运营技巧');
  return (
    <div className={styles.container}>
      <Divider height={'0.12rem'} />
      <div>
        1、<span className={styles.subTitle}>早安话术&nbsp;&nbsp;</span>建议在每天早上8:00-9:00期间，固定在“微信自营群”发早安话述、早安表情、早安红包（可以1元10个包或15个包）。
      </div>
      <div>
        2、每天早上建议分享一些美食类产品，比如早餐、牛奶等。
      </div>
      <div>
        3、每天10点、15点跟着爆款群，同步文案、图片，素材平台都会提供，只需要照搬，结合群公告，私聊，朋友圈，群内红包标题。
      </div>
      <div>
        4、每天下午可以发一些群友感兴趣的产品，按人群设定，也可以跟着爆款群推荐的产品去同步。
      </div>
      <div>
        5、晚上20点是人群看手机比较密集的时候，可以发日用品，9.9包邮的产品。每天发品保证在20-30款产品。
      </div>

      <div>
        6、<span className={styles.subTitle}>晚安话术。</span>每晚结束运营前，建议在群内发个晚安红包，表情，话述。
      </div>
      <div>
        7、每日可同步今日热榜的产品到朋友圈。
      </div>
    </div>
  );
}

export default GongLueB;
