/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import haiGuan from './assets/haiguan.png';
import styles from './index.module.scss';

function Index() {
  return (
    <div className={styles.container}>
      <img className={styles.img} src={haiGuan} />
      <div className={styles.header}>
        <p className={styles.headerTitle}>海关要求</p>
        <p className={styles.headerDes}>购买跨境商品需要实名认证</p>
      </div>
      <div className={styles.center}>
        <p className={styles.centerText}>为什么需要实名认证？</p>
        <p className={styles.centerTitle}>根据海关要求，购买跨境商品需要对订购人进行实名认证，订购人与支付人应当为同一人。</p>
        <p className={styles.centerTitle}>&ensp;&ensp;&ensp;&ensp;实名认证后，请使用该实名人的支付账户付款。已付款的，请补充填写您付款账户认证的实名，人身份信息。错误的实名信息可能导致无法正常清关，即:无法收到购买的商品。</p>
        <p className={styles.centerTitle}>&ensp;&ensp;&ensp;&ensp;● 您的身份信息将被加密保管，亚洲电视保证信息安全，绝不对外泄漏! </p>
        <p className={styles.centerTitle}>&ensp;&ensp;&ensp;&ensp;● 任何身份认证问题可随时联系亚洲电视客服</p>
      </div>
    </div>
  );
}

export default Index;
