import React, { useEffect, useState } from 'react';
import { Toast, Progress, Modal, Icon } from 'antd-mobile';
import Titles from '@/pages/Shopowner/components/Titles';
import { shopMaster, postReceive, upgrade } from '@/utils/api';
import styles from './index.module.scss';
// import icon1 from './assets/icon_1.png';
import icon2 from './assets/icon_2.png';
import icon3 from './assets/icon_3.png';
import icon4 from './assets/icon_4.png';
import icon5 from './assets/icon_5.png';
import icons1 from './assets/icons_1.png';
import modalTop from './assets/modal_top.png';
import modalHead from './assets/modal_head.png';
import modalBack from './assets/modal_back.png';
// import icons2 from './assets/icons_2.png';
import icons3 from './assets/icons_3.png';
import icons4 from './assets/icons_4.png';
import level1 from './assets/level_1.png';
import level2 from './assets/level_2.png';
import back from './assets/back.png';
import { jumpTo } from '@/utils/helper';

const alert = Modal.alert;

function Tasks({ datas = {}, onChange }) {
  const { upgradeCondition = {}, pollen = 0 } = datas;
  const [taskShow, setTaskShow] = useState([]);
  const [visible, setVisible] = useState(false);
  const [pollens, setPollens] = useState(0);
  const [taskCount, setTaskCount] = useState(0);

  useEffect(() => {
    handleShopMaster();
  }, []);

  const handleShopMaster = () => {
    shopMaster().then(res => {
      const { selfBuy, inviteFans, fansUpShopMaster, teamOrderSumMoney } = res.practiceShopMasterTask;
      const rec1 = selfBuy.completeAwardPollen * 1;
      const rec2 = inviteFans.completeAwardPollen * 1;
      const rec3 = fansUpShopMaster.completeAwardPollen * 1;
      const rec4 = teamOrderSumMoney.completeAwardPollen * 1;
      const compNum = rec1 + rec2 + rec3 + rec4;
      const list = [
        {
          key: 'selfBuy',
          icon: icon2,
          title: `自购完成${selfBuy.num}单`,
          descTitle: `每完成${selfBuy.unitNumCondition}个订单奖励`,
          descNum: selfBuy.pollen,
          status: selfBuy.state,
          comp: selfBuy.completeUnitNum,
          count: selfBuy.unitNum,
          num: selfBuy.canReceiveUnitNum,
          prop: selfBuy.unitNumCondition,
        },
        {
          key: 'inviteFans',
          icon: icon3,
          title: `邀请${inviteFans.num}位好友注册且任购一单`,
          descTitle: `每成功邀请${inviteFans.unitNumCondition}位有效粉丝奖励`,
          descNum: inviteFans.pollen,
          status: inviteFans.state,
          comp: inviteFans.completeUnitNum,
          count: inviteFans.unitNum,
          num: inviteFans.canReceiveUnitNum,
          prop: inviteFans.unitNumCondition,
        },
        {
          key: 'teamOrderSumMoney',
          icon: icon4,
          title: `团队累计产生${Math.floor(teamOrderSumMoney.num / 100)}元交易额`,
          descTitle: `每产生${Math.floor(teamOrderSumMoney.unitNumCondition / 100)}元交易额即奖励`,
          descNum: teamOrderSumMoney.pollen,
          status: teamOrderSumMoney.state,
          comp: teamOrderSumMoney.completeUnitNum,
          count: teamOrderSumMoney.unitNum,
          num: teamOrderSumMoney.canReceiveUnitNum,
          prop: (teamOrderSumMoney.unitNumCondition / 100).toFixed(0) * 1,
        },
        {
          key: 'fansUpShopMaster',
          icon: icon5,
          title: `帮助${fansUpShopMaster.num}位粉丝晋升实习店长`,
          descTitle: '成为正式店长，赚更多花粉哦',
          descNum: fansUpShopMaster.pollen,
          status: fansUpShopMaster.state,
          comp: fansUpShopMaster.completeUnitNum,
          count: fansUpShopMaster.unitNum,
          num: fansUpShopMaster.canReceiveUnitNum,
          prop: fansUpShopMaster.unitNumCondition,
        },
      ];
      setTaskCount(compNum);
      setTaskShow(list);
    });
  };

  const handleComplete = (taskKey) => {
    postReceive({
      body: { taskKey },
    }).then(res => {
      setPollens(res.pollen);
      setVisible(true);
    }).catch(err => Toast.fail(err.message));
  };

  const renderBtn = (index, num, key) => {
    switch (index) {
      case 1:
        return (
          <div>
            <div className={styles.badges}>{num || 0}</div>
            <div
              className={styles.receive}
              onClick={() => handleComplete(key)}
            >
              领取
            </div>
          </div>
        );
      case 2:
        return (
          <div className={styles.complete}>已完成</div>
        );
      case 0:
        return (
          <div className={styles.incomplete}>待完成</div>
        );
      default:
        return '';
    }
  };

  const renderProgress = (value) => {
    let percent = Math.ceil(value * 100) || 0;
    percent = percent > 100 ? 100 : percent;
    return (
      <div
        className={styles.progress}
        style={{ width: '1.33rem' }}
      >
        <Progress
          percent={percent}
          position="normal"
          style={{
            backgroundColor: 'rgba(204, 204, 204, 0.5)',
            borderRadius: '0.02rem',
            height: '0.03rem',
          }}
        />
      </div>
    );
  };

  const renderList = (index) => {
    const cont = taskShow[index];
    return (
      <div className={styles.tasklists}>
        <img className={styles.headimg} src={cont.icon} alt="" />
        <div className={styles.contents}>
          <p className={styles.title}>{cont.title}</p>
          <p className={styles.desc}>
            {cont.descTitle}
            <img className={styles.icones} src={icons1} alt="" />
            <span>{cont.descNum}</span>
          </p>
          <div className={styles.footer}>
            {renderProgress(cont.comp / cont.count)}
            <span><span style={{ color: '#FFCC33' }}>{cont.comp * cont.prop}</span>/{cont.count * cont.prop}</span>
          </div>
        </div>
        <div className={styles.btnBox}>
          {renderBtn(cont.status, cont.num, cont.key)}
        </div>
      </div>
    );
  };

  const handleUpgrade = () => {
    upgrade().then(res => {
      onChange();
    }).catch(err => Toast.fail(err.message));
  };

  const renderModal = () => {
    alert(
      (
        <>
          <p style={{ fontSize: '0.21rem', color: '#1A1920' }}>
            使用<span style={{ color: '#FFAF0E' }}> {upgradeCondition.shopMaster.pollen} </span>花粉
          </p>
          <p style={{ fontSize: '0.21rem', color: '#1A1920' }}>兑换正式店长权益</p>
        </>
      ),
      '',
      [{
        text: (
          <span
            style={{ fontSize: '0.19rem', color: '#999' }}
          >
            再想想
          </span>
        ),
      },
      {
        text: (
          <span
            style={{ fontSize: '0.19rem', color: '#FFAF0E' }}
          >
            确认兑换
          </span>
        ),
        onPress: () => handleUpgrade(),
      }]
    );
  };

  return taskShow.length ? (
    <>
      <Titles
        title='做任务，加速赚花粉'
        desc={(<span style={{ color: 'rgba(125, 75, 45, 0.78)' }}>以下任务花粉均为额外奖励花粉</span>)}
      />
      <div className={styles.tasks}>
        <div
          className={styles.header}
          style={{ backgroundImage: `url(${back})` }}
        >
          <div className={styles.headerBox}>
            <p className={styles.introduce}>
              12万花粉即可立即兑换正式店长权益！完成任务最高可赚6万花粉！
              <span onClick={() => jumpTo('sharePoster')}>
                去邀请
                <Icon
                  type="right"
                  size="xxs"
                  color="#222"
                  style={{ marginLeft: '-0.03rem' }}
                />
              </span>
            </p>
            <div className={styles.upgrade}>
              <div className={styles.typeBox}>
                <img
                  src={level1}
                  alt=""
                  style={{
                    height: '0.19rem',
                    marginTop: '0.05rem',
                  }}
                />
                <p>{pollen}</p>
              </div>
              <div className={styles.speedBox}>
                <div className={styles.speedBack}>
                  <div
                    className={styles.speedCheck}
                    style={{ width: `${pollen > upgradeCondition.shopMaster.pollen ? 2.52 : pollen / upgradeCondition.shopMaster.pollen * 2.52}rem` }}
                  >
                    <div className={styles.speedTip}>
                      {pollen < upgradeCondition.shopMaster.pollen ? (
                        <div>累计花粉：{pollen}</div>
                      ) : (
                        <div onClick={() => renderModal()}>
                          去升级<Icon type="right" size="sm" color="#fcd05b" style={{ marginLeft: '-0.03rem' }} />
                        </div>
                      )}
                      <img className={styles.icon} src={icons3} alt="" />
                      <img className={styles.icons} src={icons4} alt="" />
                    </div>
                  </div>
                </div>
                <p>还差{pollen > upgradeCondition.shopMaster.pollen ? 0 : upgradeCondition.shopMaster.pollen - datas.pollen}升级</p>
              </div>
              <div className={styles.typeBox}>
                <img src={level2} alt="" />
                <p>{datas.upgradeCondition.shopMaster.pollen}</p>
              </div>
            </div>
          </div>
          <div className={styles.headerFoot}>
            <div>
              <span>任务花粉</span>{taskCount}
            </div>
            <div onClick={() => jumpTo('userPollen')}>
              <span>粉丝购物奖励花粉</span>{pollen - taskCount < 0 ? 0 : pollen - taskCount}<Icon type="right" size="xxs" />
            </div>
          </div>
        </div>
        {renderList(0)}
        {renderList(1)}
        {renderList(2)}
        {renderList(3)}
      </div>
      <Modal
        popup
        maskClosable={false}
        visible={visible}
        className={styles.modalStyle}
        wrapClassName={styles.wrapStyle}
      >
        <img className={styles.modalBack} src={modalTop} alt="" />
        <div
          style={{ backgroundImage: `url(${modalBack})` }}
          className={styles.modalBody}
        >
          <div className={styles.header}>
            <img src={modalHead} alt="" />
            <p>奖励领取成功</p>
          </div>
          <div className={styles.content}>
            <p>恭喜你获得</p>
            <p><span style={{ fontSize: '0.45rem' }}>{pollens}</span>花粉</p>
          </div>
        </div>
        <div className={styles.footed}>
          <Icon
            type="cross"
            size="lg"
            color="#ffffff"
            onClick={() => {
              setVisible(false);
              onChange();
            }}
          />
        </div>
      </Modal>
    </>
  ) : '';
}

export default Tasks;
