import React, { Fragment } from 'react';
import Divider from '@/components/Divider';
import useTitle from '@/hooks/useTitle';

function TaskD() {
  useTitle('成长任务4：一起学习赚钱技巧');
  // 生成1-12的数组
  const imgList = Array.from({ length: 12 }).map((_, index) => index + 1);
  return (
    <div>
      {imgList.map(v => {
        const isLast = imgList.length === v;
        return (
          <Fragment key={v}>
            <img
              style={{ width: '100vw', display: 'block' }}
              src={require(`./assets/${v}.jpg`)}
              alt={v}
            />
            {isLast ? <Divider height={'0.27rem'} color="#FFF" /> : <Divider height={'0.08rem'} />}
          </Fragment>
        );
      })}
    </div>
  );
}

export default TaskD;
