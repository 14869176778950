import React, { useState, useEffect, Fragment } from 'react';
import { Carousel, Icon, Toast } from 'antd-mobile';
import { getProductGather } from '@/utils/api';
import { jumpTo } from '@/utils/helper';
import GoodsItem from '../GoodsItem';
import Titles from '../Titles';
import styles from './index.module.scss';
import back1 from './assets/back_1.png';
import back2 from './assets/back_2.png';

// 线上
const gatherId = 36;
// 测试
// const gatherId = 161;
// 开发
// const gatherId = 1;

function Commodity({ info }) {
  const [page, setPage] = useState([]);
  const [data, setData] = useState(null);
  const [card, setCard] = useState({});
  const levelArr = [
    {
      level: [11],
      title: '店长分享商品轻松赚',
      desc: '超级赚钱',
      descColor: '#FFFEFC',
      types: false,
      backUrl: back2,
    },
    {
      level: [21],
      title: '店长高佣专区',
      desc: '超级赚钱',
      descColor: '#630',
      types: false,
      backUrl: back1,
    },
    {
      level: [22, 23, 31, 32],
      title: '店长高佣专区',
      desc: '超级赚钱',
      descColor: '#630',
      types: true,
      backUrl: back1,
    },
  ];

  useEffect(() => {
    setCard(levelArr.find(i => i.level.includes(info.level)));
    getProductGather({
      query: {
        gatherId,
        pageSize: 100,
        pageNo: 1,
      },
    }).then(res => {
      const num = Math.ceil(res.length / 3);
      const list = [];
      for (let i = 0; i < num; i++) {
        list.push(i);
      }
      setPage(list);
      setData(res);
    }).catch(err => Toast.fail(err.message));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderGoods = (index) => {
    const list = data.slice(index * 3, (index + 1) * 3);
    const listDoc = list.map((i, k) => {
      return <GoodsItem item={i} key={k} gatherId={gatherId} level={info.level} />;
    });
    return <div className={styles.goodsBody}>{listDoc}</div>;
  };

  return (
    <>
      <Titles
        title={card.title}
        type={card.types}
        color={info.level === 11 ? '#31302F' : '#7F4F32'}
      />
      <div
        className={styles.carouselBody}
        style={{ backgroundImage: `url(${card.backUrl})` }}
      >
        <div
          className={styles.morebox}
          style={{ color: card.descColor }}
        >
          <span>{card.desc}</span>
          <span onClick={() => jumpTo('productList', { gatherId })}>
            更多
            <Icon type="right" size="xxs" />
          </span>
        </div>
        <div className={styles.carouselbox}>
          {data && (
            <Carousel>
              {page.map(i => {
                return (
                  <Fragment key={i}>
                    {renderGoods(i)}
                  </Fragment>
                );
              })}
            </Carousel>
          )}
        </div>
      </div>
    </>
  );
}

export default Commodity;
