import BigNumber from 'bignumber.js';
import useReducerContext from '@/reducer/userReducer';
import { isEmpty } from 'lodash';

const getUserData = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { userData } = useReducerContext();
  return userData ? userData.level : 11;
};

/**
 * 计算花粉收益
 * @param {object} award - 收益数据
 * @param {object} opts - 可选参数
 * @param {number | boolean} opts.limitLevel - 限定上级身份等级，如果为boolean值表示是否限定展示符合自身身份的上级身份等级，如果为number指定用户等级
 */
export const calcPollen = (award, opts = {}) => {
  let userLevel = getUserData();
  const { limitLevel = false } = opts;
  const fixed = 0;
  const fixedType = 3;
  const defaultValue = (0).toFixed(fixed);
  let temp = 0;

  if (isEmpty(award)) {
    return defaultValue;
  }

  const { returnPollen } = award[0];
  const levelProfits = award[1];
  if (isNaN(returnPollen) || isEmpty(levelProfits) || (!returnPollen && !limitLevel)) {
    return defaultValue;
  }

  temp = returnPollen;

  if (limitLevel) {
    // 实习店长展示店长收益
    userLevel === 21 && (userLevel = 22);
    if (!levelProfits[userLevel] && !levelProfits[limitLevel]) {
      return defaultValue;
    }
    // 指定用户等级
    typeof limitLevel === 'number' && (userLevel = limitLevel);
    temp = levelProfits[userLevel].returnPollen;
  }

  return BigNumber(temp).toFixed(fixed, fixedType);
};

/**
 * 计算返佣收益
 * @param {object} award - 收益数据
 * @param {object} opts - 可选参数
 * @param {number | boolean} opts.limitLevel - 限定上级身份等级，如果为boolean值表示是否限定展示符合自身身份的上级身份等级，如果为number指定用户等级
 */
export const calcMoney = (award, opts = {}) => {
  let userLevel = getUserData();
  const { limitLevel = false } = opts;
  const fixed = 2;
  const fixedType = 3;
  const defaultValue = (0).toFixed(fixed);
  let temp = 0;

  if (isEmpty(award)) {
    return defaultValue;
  }

  const { returnMoney } = award[0];
  const levelProfits = award[1];
  if (isNaN(returnMoney) || isEmpty(levelProfits) || (!returnMoney && !limitLevel)) {
    return defaultValue;
  }

  temp = returnMoney;

  if (limitLevel) {
    // 实习店长展示店长收益
    userLevel === 21 && (userLevel = 22);
    if (!levelProfits[userLevel] && !levelProfits[limitLevel]) {
      return defaultValue;
    }
    // 指定用户等级
    typeof limitLevel === 'number' && (userLevel = limitLevel);
    temp = levelProfits[userLevel].returnMoney;
  }

  return BigNumber(temp)
    .div(100)
    .toFixed(fixed, fixedType);
};

// 计算购物金收益
export const calcDeduction = award => {
  if (isEmpty(award)) {
    return 0;
  }
  const { returnDeduction } = award[0];
  if (isNaN(returnDeduction)) {
    return 0;
  }

  const res = BigNumber(returnDeduction)
    .div(100)
    .dp(2, 3)
    .toNumber();

  return res;
};
