import React, { useEffect, useState } from 'react';
import useReactRouter from 'atv-use-react-router';
import useTitle from '@/hooks/useTitle';
import { getMyInfo, getGongLueDetail } from '@/utils/api';
import StatusView, { StateLoad, StateEmpty } from '@/components/StatusView';
import 'braft-editor/dist/output.css';
import { getAuth } from '@/utils/app';
import { H5_PREFIX, WXShareObj, RNShareObj, shareDefaultImg } from '@/utils/constants';
import { isRN, shareRN } from '@/utils/helper';
import useReducerContext from '@/reducer/userReducer';
import classnames from 'classnames';
import styles from './index.module.scss';

function Article() {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState(null);
  const [thumbnail, setThumbnail] = useState('');
  const [isError, setIsError] = useState(false);
  const { updateState, userData } = useReducerContext();
  const { inviteCode } = userData || {};
  const auth = getAuth();
  useTitle(title);
  const router = useReactRouter();
  const gongLueId = router.match.params.id;
  // 获取文章信息
  const fetchGongLueDetail = () => {
    getGongLueDetail({
      params: {
        id: gongLueId,
      },
    })
      .then(res => {
        const { title, content } = res;
        setTitle(title);
        setContent(content);
        setThumbnail(thumbnail);
      })
      .catch(_ => {
        setContent('文章已下线');
        setIsError(true);
      });
  };

  useEffect(() => {
    const sharePath = `/pages/launch/index?pg=webview&invite=${inviteCode}&src=${H5_PREFIX}/article/${gongLueId}`;
    const shareImgUrl = thumbnail || shareDefaultImg;
    const webpageUrl = `${H5_PREFIX}/download?code=${inviteCode}`;
    const WXParams = isError
      ? WXShareObj
      : Object.assign({}, WXShareObj, {
        title,
        path: sharePath,
        imageUrl: shareImgUrl,
      });
    window.wx.miniProgram.postMessage({
      data: WXParams,
    });
    if (isRN()) {
      const params = isError
        ? RNShareObj
        : Object.assign({}, RNShareObj, {
          title,
          path: sharePath,
          thumbImageUrl: shareImgUrl,
          webpageUrl,
        });
      shareRN(params);
    }
  }, [gongLueId, inviteCode, isError, thumbnail, title]);

  useEffect(() => {
    if (auth) {
      getMyInfo().then(res => {
        updateState('userData', res);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchGongLueDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (content === null) {
    return <StatusView status={StateLoad} />;
  }

  if (content === '') {
    return <StatusView status={StateEmpty} tips="暂无内容" />;
  }

  return (
    <div style={{ minHeight: '100vh', background: '#fff' }}>
      <div
        className={classnames('braft-output-content', styles.contentWrapper)}
        dangerouslySetInnerHTML={{ __html: content }}
      ></div>
    </div>
  );
}

function Index() {
  return (
    <useReducerContext.Provider>
      <Article />
    </useReducerContext.Provider>
  );
}

export default Index;
