/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react';
import { Toast } from 'antd-mobile';
import axios from 'axios';
import useClipboard from 'react-use-clipboard';
import styles from './index.module.scss';

import Btn from './assets/btn.png';
import Bg from './assets/bg.png';

function GameInfo() {
  const [code, setCode] = useState('');
  const [phone, setPhone] = useState('');
  const [imgs, setImg] = useState(false);
  const [androidUrl, setAndroidUrl] = useState('');
  const [iosUrl, setIosUrl] = useState('');
  // 复制方法
  const [isCopied, setCopied] = useClipboard(code, {
    successDuration: 1000,
  });
  // 复制邀请码
  const dealWith = () => {
    setCopied();
    Toast.success('成功复制邀请码');
  };
  // 格式化邀请码
  const formatUrl = variable => {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      if (pair[0] === variable) {
        return pair[1];
      }
    }
    return false;
  };
  // 是否是微信浏览器
  const isWeChat = () => {
    var ua = navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
      setImg(true);
    } else if (ua.match(/mqqbrowser|qzone|qqbrowser/i)) {
      setImg(false);
    }
  };
  // 获取资源
  const getDataSource = () => {
    axios.get('https://qubibi-cdn.baicaiyun.cn/apk/data.json').then(res => {
      setAndroidUrl(res.data.Android.appSource);
      setIosUrl(res.data.IOS.appSource);
    }).catch(() => {
      Toast.fail('网络出错请刷新重试');
    });
  };
  // 下载App
  const downloadApp = () => {
    if (phone.includes('IOS')) {
      // Toast.offline('敬请期待');
      window.location.href = iosUrl && iosUrl;
    } else if (phone.includes('Android')) {
      window.location.href = androidUrl && androidUrl;
    }
  };
  // 判断机型
  const isPhone = () => {
    const u = navigator.userAgent;
    const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;
    const isIos = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    if (isAndroid) {
      setPhone('Android下载');
    } else if (isIos) {
      setPhone('IOS下载');
    }
  };
  useEffect(() => {
    getDataSource();
    isPhone();
    isWeChat();
    setCode(formatUrl('code'));
  }, []);
  return (
    <div className={styles.container}>
      <img className={styles.bg} src={Bg} />
      {code && code !== 'undefined' && (
        <div className={styles.copy}>
          <p className={styles.code}>{`邀请码：${code}`}</p>
          <p onClick={dealWith} className={styles.text}>
            复制
          </p>
        </div>
      )}
      <p className={styles.copCode}>
        请先<span className={styles.copeCode}>复制邀请码</span>
        ，该邀请码用于激活APP使用资格
      </p>
      <div className={styles.count} onClick={downloadApp}>
        <img src={Btn} className={styles.btn} />
        <span className={styles.title}>{phone}</span>
      </div>
      <p className={styles.footer}>
        温馨提示：如点击按钮无法下载，可点击右上角【...】选择在浏览器中打开下载APP
      </p>
      <img
        className={imgs ? styles.img : styles.isImg}
        src={require('./assets/41.png')}
      />
      <p style={{ display: 'none' }}>{isCopied}</p>
      {/* <BottomNavBar keyName="live" isApplyData={data} /> */}
    </div>
  );
}

export default GameInfo;
