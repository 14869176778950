import React, { useState, useEffect } from 'react';
import createF2 from 'f2react';
import styles from './index.module.scss';

function Charts ({ dataSouce = [] }) {
  const widths = document.documentElement.clientWidth - 5
  const [data, setData] = useState([])
  const [status, setStatus] = useState(0)
  const datas = dataSouce.map(i => ({
    date: new Date(i.time * 1000).toLocaleString().split(' ')[0].replace(/\//g, '-'),
    value: (i.goodsPrice / 100).toFixed(2) * 1
  }))
  const list = [
    {
      label: '全部',
      value: 0
    },
    {
      label: '180天',
      value: 180
    },
    {
      label: '90天',
      value: 90
    },
    {
      label: '30天',
      value: 30
    }
  ]
  useEffect(() => {
    if (status === 0) {
      setData(datas)
    } else {
      const lists = datas.slice(0, list[status].value)
      setData(lists)
    }
  }, [status])
  const LineCharts = createF2((chart) => {
    chart.source(data, {
      value: {
        tickCount: 5,
        min: 0
      },
      date: {
        type: 'timeCat',
        range: [0, 1],
        tickCount: 3
      }
    })
    chart.tooltip({
      snap: true,
      showItemMarker: false,
      crosshairsType: 'xy',
      crosshairsStyle: {
        stroke: 'rgba(235, 74, 74, 0.5)',
        lineWidth: 0.5
      },
      background: {
        radius: 3,
        fill: '#EB4A4A',
        padding: [6, 10]
      },
      tooltipMarkerStyle: {
        stroke: 'rgba(235, 74, 74, 0.3)',
        fill: '#EB4A4A',
        lineWidth: 7,
        radius: 3
      },
      onShow: (ev) => {
        const items = ev.items
        items[0].name = null
        items[0].value = `${items[0].title}：${items[0].value}元`
      }
    })
    chart.axis('date', {
      label: function label (text, index, total) {
        const textCfg = {}
        if (index === 0) {
          textCfg.textAlign = 'left'
        } else if (index === total - 1) {
          textCfg.textAlign = 'right'
        }
        return textCfg
      }
    })
    chart.line().position('date*value').color('city').style({
      stroke: '#EB4A4A',
      lineWidth: 1.5
    })
    chart.render()
  })
  return (
    <div>
      <div className={styles.checkBox}>
        {list.map((i, k) => {
          return (
            <span
              key={i.value}
              style={k === status ? {
                color: '#262626',
                borderBottom: '0.03rem solid #262626'
              } : {}}
              onClick={() => setStatus(k)}
            >
              {i.label}
            </span>
          )
        })}
      </div>
      <LineCharts data={data} width={widths} height={220} />
    </div>
  )
}

export default Charts
