import React, { useState, useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { renderRoutes } from 'react-router-config'
import routes from './routes'
import { getHash } from '@/utils/api'
import debug from '@/utils/debug'
import url from 'url'

function App () {
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    debug()
  }, [])

  // 解析query
  function parseQuery () {
    const { query } = url.parse(window.location.href, true)
    const { token } = query || {}
    if (token) {
      getHash({
        query: {
          hash: token
        }
      })
        .then(res => {
          const token = res.data
          localStorage.setItem('QUBIBI-TOKEN', token)
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
        })
    }else{
      setLoading(false)
    }
  }

  useEffect(() => {
    parseQuery()
  }, [])

  if (loading) {
    return null
  }

  return <BrowserRouter>{renderRoutes(routes)}</BrowserRouter>
}

export default App
