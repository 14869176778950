import React from 'react';
import { jumpTo } from '@/utils/helper';
import styles from './index.module.scss';

function Goods ({ datas }) {
  return (
    <div className={styles.goods}>
      <img className={styles.goodsImg} src={datas.image} />
      <div className={styles.goodsInfo}>
        <div className={styles.title}>{datas.goodsName}</div>
        <div className={styles.info}>
          <div>
            <p className={styles.price}>¥ {(datas.curPrice / 100).toFixed(2)}</p>
            <p className={styles.address}>{datas.mail}</p>
          </div>
          <div
            className={styles.btns}
            onClick={() => {
              jumpTo('selfProductCopyUrl', {
                image: datas.image,
                goodsName: datas.goodsName,
                url: encodeURIComponent(datas.url)
              })
            }}
          >
            去购买
          </div>
        </div>
      </div>
    </div>
  )
}

export default Goods;
