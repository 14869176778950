/* eslint-disable semi */
import React, { useState, useEffect } from 'react';
import useReactRouter from 'atv-use-react-router';
import useReducerContext from '@/reducer/userReducer';
import { getMyInfo, getTaskNum } from '@/utils/api';
import { jumpTo } from '@/utils/helper';
import useTitle from '@/hooks/useTitle';
import Commodity from './components/Commodity';
import EliteList from './components/EliteList';
import StarList from './components/StarList';
import CardData from './components/CardData';
// import Business from './components/Business';
import Tasks from './components/Tasks';
// import pos1 from './assets/pos_1.png';
// import pos2 from './assets/pos_2.png';
import poster from './assets/poster.png';
import styles from './index.module.scss';

function Shopowner () {
  useTitle('店长');
  const router = useReactRouter().history;
  const [info, setInfo] = useState({});
  const [data, setData] = useState({});
  const { updateState } = useReducerContext();

  const handleGetMyInfo = async () => {
    setInfo({});
    await getMyInfo().then(res => {
      updateState('userData', res);
      const levelArr = [21, 22, 23, 31, 32];
      // setInfo(() => ({ ...res, level: 22 }));
      if (!levelArr.includes(res.level)) router.push('equitys');
      else setInfo(() => ({ ...res }));
    }).catch(() => router.push('equitys'));
    await getTaskNum().then(res => setData(res));
  };

  useEffect(() => {
    handleGetMyInfo();
    return () => handleGetMyInfo()
  }, []);

  return info.level ? (
    <div
      className={styles.shopowner}
      style={info.level === 21 ? { background: '#FFF8EB' } : {}}
    >
      <CardData
        info={info}
        datas={data}
        onChange={() => handleGetMyInfo()}
      />
      <img
        className={styles.poster}
        onClick={() => jumpTo('sharePoster')}
        src={poster}
        alt=""
      />
      {/* <div className={styles.posBox}>
        <img
          className={styles.pos}
          onClick={() => jumpTo('sharePoster')}
          src={pos1}
          alt=""
        />
        <img
          className={styles.pos}
          onClick={() => jumpTo('webview', { path: 'https://aifeng-wx.aifenglife.com/' })}
          src={pos2}
          alt=""
        />
      </div> */}
      {info.level === 21 && (
        <Tasks
          datas={data}
          onChange={() => handleGetMyInfo()}
        />
      )}
      <Commodity info={info} />
      {/* <Business info={info} /> */}
      <StarList info={info} />
      <EliteList info={info} />
    </div>
  ) : '';
}

function Index () {
  return (
    <useReducerContext.Provider>
      <Shopowner />
    </useReducerContext.Provider>
  );
}

export default Index;
