/* eslint-disable semi */
import React, { useEffect, useState } from 'react';
import { calcPrice, jumpTo } from '@/utils/helper';
import { getIncomeData, upgradeSuperShopMaster, upgradeCommonPartner } from '@/utils/api';
import { Toast, Modal } from 'antd-mobile';
import FansData from '../FansData';
import styles from './index.module.scss';
import back1 from './assets/back_1.png';
import back2 from './assets/back_2.png';
import back3 from './assets/back_3.png';
import back4 from './assets/back_4.png';
import show1 from './assets/show_1.png';
import show2 from './assets/show_2.png';
import show3 from './assets/show_3.png';
import show4 from './assets/show_4.png';
import level1 from './assets/level_1.png';
import level2 from './assets/level_2.png';
import level3 from './assets/level_3.png';
import level4 from './assets/level_4.png';
import pollen1 from './assets/pollen_1.png';
import pollen2 from './assets/pollen_2.png';
import pollen3 from './assets/pollen_3.png';
import pollen4 from './assets/pollen_4.png';
import icon1 from './assets/icon_1.png';
import icon2 from './assets/icon_2.png';
import icon3 from './assets/icon_3.png';
import icon4 from './assets/icon_4.png';
import icon5 from './assets/icon_5.png';
import icon6 from './assets/icon_6.png';
import icon7 from './assets/icon_7.png';
import icon8 from './assets/icon_8.png';
import right1 from './assets/right_1.png';
import right2 from './assets/right_2.png';
import right3 from './assets/right_3.png';
import right4 from './assets/right_4.png';

const alert = Modal.alert

function CardData ({ info = {}, datas = {}, onChange }) {
  const { upgradeCondition = {}, pollen = 0, shopMasterFanCount = 0, superShopMasterCount = 0 } = datas
  const { superShopMaster = {}, commonPartner = {} } = upgradeCondition
  const [incomeData, setIncomeData] = useState({})
  const [card, setCard] = useState({})
  const levelArr = [
    { // 实习店长
      level: [21],
      backUrl: back1,
      showUrl: show1,
      headBorder: 'rgba(255, 200, 102, 1)',
      titleColor: 'linear-gradient(to right, #1A1920, #1A1920)',
      levelIcon: level1,
      pollenIcon: pollen1,
      pollenIcons: right1,
      pollenColor: '#F8DD84',
      datasTitle: '#1A1920',
      datasBtns: '#1A1920',
      datasBorder: ['#333', '#333', '#333'],
      datasBtnBack: 'rgba(255, 255, 255, 0.21)',
      datasIcon: icon1
    },
    { // 店长
      level: [22, 23],
      backUrl: back2,
      showUrl: show2,
      headBorder: 'rgba(62, 8, 1, 0.3)',
      titleColor: 'linear-gradient(to right, #420C04, #680F02)',
      levelIcon: level2,
      pollenIcon: pollen2,
      pollenIcons: right2,
      pollenColor: '#F8DD84',
      datasTitle: '#863200',
      datasBtns: '#863200',
      datasBorder: ['#FCB426', '#FCB01D', '#FCB01D'],
      datasBtnBack: 'rgba(255, 255, 255, 0.4)',
      datasIcon: icon2,
      pollen: {
        name: '店长',
        back: 'rgba(255, 153, 0, 0.32)',
        text1: '#670F02',
        text2: '#670F02',
        text3: '#B2681C',
        speedBack1: '#D39228',
        speedBack2: '#D39228',
        speedCheck: 'linear-gradient(266deg, rgba(82, 13, 3, 1), rgba(164, 65, 51, 1))',
        speedIcon: icon5,
        icon: icon6,
        num: pollen,
        numCount: superShopMaster.pollen || 0,
        nums: shopMasterFanCount || 0,
        numsCount: superShopMaster.shopMasterFanNeedCount || 0,
        status: shopMasterFanCount >= superShopMaster.shopMasterFanNeedCount && pollen >= superShopMaster.pollen
      }
    },
    { // 金牌店长
      level: [31],
      backUrl: back3,
      showUrl: show3,
      headBorder: 'rgba(113, 117, 127, 0.3)',
      titleColor: 'linear-gradient(to right, #6F6F6F, #2A2A2A)',
      levelIcon: level3,
      pollenIcon: pollen3,
      pollenIcons: right3,
      pollenColor: '#ECF0F8',
      datasTitle: '#232323',
      datasBtns: '#343434',
      datasBorder: ['#C9C9C9', '#B3B3B3', '#A3A3A3'],
      datasBtnBack: 'rgba(255, 255, 255, 0.4)',
      datasIcon: icon3,
      pollen: {
        name: '金牌店长',
        back: 'rgba(109, 109, 109, 0.2)',
        text1: '#323234',
        text2: '#373737',
        text3: '#676767',
        speedBack1: '#8D8D8D',
        speedBack2: '#767676',
        speedCheck: 'linear-gradient(266deg, rgba(103, 103, 103, 1), rgba(61, 61, 61, 1))',
        speedIcon: icon8,
        icon: icon7,
        num: pollen,
        numCount: commonPartner.pollen || 0,
        nums: superShopMasterCount || 0,
        numsCount: commonPartner.superShopMasterNeedCount || 0,
        status: superShopMasterCount >= commonPartner.superShopMasterNeedCount && pollen >= commonPartner.pollen
      }
    },
    { // 合伙人
      level: [32],
      backUrl: back4,
      showUrl: show4,
      headBorder: 'rgba(253, 234, 186, 1)',
      titleColor: 'linear-gradient(to right, #FBE7B6, #E5C187)',
      levelIcon: level4,
      pollenIcon: pollen4,
      pollenIcons: right4,
      pollenColor: '#FFEDBE',
      datasTitle: '#FFEDBE',
      datasBtns: '#3E1104',
      datasBorder: ['#000', '#000', '#000'],
      datasBtnBack: 'linear-gradient(90deg, rgba(255, 237, 190, 1), rgba(219, 175, 112, 1))',
      datasIcon: icon4
    }
  ];

  useEffect(() => {
    if (!datas.upgradeCondition || !info.level) return
    setCard(levelArr.find(i => i.level.includes(info.level)))
    getIncomeData().then(res => setIncomeData(res))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datas])

  const renderMaterial = () => {
    const list = [
      {
        datas: calcPrice(incomeData.moneyAllIncome) || '-',
        title: '累计收益',
        btns: '收益明细',
        urls: 'userProfit'
      },
      {
        datas: calcPrice(incomeData.money) || '-',
        title: '账户余额',
        btns: '立即提现',
        urls: 'userWePayWithdraw'
      },
      {
        datas: calcPrice(incomeData.moneyLock) || '-',
        title: '入账中',
        btns: '收益明细',
        urls: 'userProfit'
      }
    ];
    return list.map((i, k) => {
      return (
        <div key={k} className={styles.lists}>
          <p
            className={styles.texts}
            style={{ color: card.datasTitle }}
          >
            ￥{i.datas}
          </p>
          <p
            className={styles.texts}
            style={{ color: card.datasTitle }}
          >
            {i.title}
          </p>
          <p
            className={styles.btns}
            style={{
              color: card.datasBtns,
              borderColor: card.datasBorder && card.datasBorder[k],
              background: card.datasBtnBack
            }}
            onClick={() => jumpTo(i.urls)}
          >
            {i.btns}<img src={card.datasIcon} alt="" />
          </p>
        </div>
      );
    });
  };

  const handleUpgrade = () => {
    if (card.pollen.name === '店长') {
      upgradeSuperShopMaster().then(res => {
        onChange()
      }).catch(err => Toast.fail(err.message));
    } else {
      upgradeCommonPartner().then(res => {
        onChange();
      }).catch(err => Toast.fail(err.message))
    }
  }

  const renderModal = () => {
    alert(
      (
        <>
          <p style={{ fontSize: '0.21rem', color: '#1A1920' }}>
            使用<span style={{ color: '#FFAF0E' }}> {card.pollen.numCount} </span>花粉
          </p>
          <p style={{ fontSize: '0.21rem', color: '#1A1920' }}>兑换{card.pollen.name === '店长' ? '金牌店长' : '合伙人'}权益</p>
        </>
      ),
      '',
      [{
        text: (
          <span
            style={{ fontSize: '0.19rem', color: '#999' }}
          >
            再想想
          </span>
        )
      },
      {
        text: (
          <span
            style={{ fontSize: '0.19rem', color: '#FFAF0E' }}
          >
            确认兑换
          </span>
        ),
        onPress: () => handleUpgrade()
      }]
    )
  }

  const renderPollen = () => {
    const dataes = card.pollen
    return (
      <div
        className={styles.pollenBack}
        style={{ background: dataes.back }}
      >
        <div className={styles.pollenBox}>
          <span
            className={styles.title}
            style={{ color: dataes.text1 }}
          >
            花粉
          </span>
          <div className={styles.speedBox}>
            <div
              className={styles.speedBack}
              style={{ background: dataes.speedBack1 }}
            >
              <div
                className={styles.speedCheck}
                style={{ width: `${dataes.num > dataes.numCount ? 1 : dataes.num / dataes.numCount}rem`, background: dataes.speedCheck }}
              >
                <img
                  style={{ marginLeft: `${dataes.num > dataes.numCount ? 1 : dataes.num / dataes.numCount - 0.06}rem` }}
                  className={styles.speedTip}
                  src={dataes.speedIcon}
                  alt=""
                />
              </div>
            </div>
            <p style={{ color: dataes.text2 }}>
              {dataes.num}<span style={{ color: dataes.text3 }}>/{dataes.numCount}</span>
            </p>
          </div>
        </div>
        <div className={styles.pollenBox}>
          <span
            className={styles.title}
            style={{ color: dataes.text1 }}
          >
            {dataes.name}
          </span>
          <div className={styles.speedBox}>
            <div
              className={styles.speedBack}
              style={{ background: dataes.speedBack2 }}
            >
              <div
                className={styles.speedCheck}
                style={{ width: `${dataes.nums > dataes.numsCount ? 1 : dataes.nums / dataes.numsCount}rem`, background: dataes.speedCheck }}
              >
                <img
                  style={{ marginLeft: `${dataes.nums > dataes.numsCount ? 1 : dataes.nums / dataes.numsCount - 0.06}rem` }}
                  className={styles.speedTip}
                  src={dataes.speedIcon}
                  alt=""
                />
              </div>
            </div>
            <p style={{ color: dataes.text2 }}>
              {dataes.nums}<span style={{ color: dataes.text3 }}>/{dataes.numsCount}</span>
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={styles.cardbody}
      style={{ backgroundImage: `url(${card.backUrl})` }}
    >
      <div
        className={styles.material}
        style={{ backgroundImage: `url(${card.showUrl})` }}
      >
        <div className={styles.header}>
          <img
            src={info.avatarUrl && info.avatarUrl.replace('http://', 'https://')}
            className={styles.headImg}
            style={{ borderColor: card.headBorder }}
            alt=""
          />
          <div className={styles.contentBox}>
            <div className={styles.titleBox}>
              <span
                className={styles.titles}
                style={{ backgroundImage: card.titleColor }}
              >
                {info.name}
              </span>
              <div>
                <img className={styles.icons} src={card.levelIcon} alt="" />
                {card.pollen && (
                  <img className={styles.icones} src={card.pollen.icon} alt="" />
                )}
              </div>
            </div>
            {card.pollen && renderPollen()}
          </div>
          <div
            className={styles.pollens}
            style={{
              backgroundImage: `url(${card.pollenIcon})`,
              color: card.pollenColor
            }}
            onClick={() => card.pollen && card.pollen.status ? renderModal() : jumpTo('sharePoster')}
          >
            <span>{card.pollen && card.pollen.status ? '去升级' : '分享推广'}</span>
            <img src={card.pollenIcons} alt="" />
          </div>
        </div>
        <div className={styles.content}>
          {renderMaterial()}
        </div>
      </div>
      <FansData info={info} />
    </div>
  );
};

export default CardData;
