/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useLayoutEffect } from 'react';
import useUpload from 'atv-use-alioss-upload';
// import useReactRouter from 'atv-use-react-router';
import { Toast, ImagePicker, ActivityIndicator, NoticeBar } from 'antd-mobile';
import useClipboard from 'react-use-clipboard';
import { getUploadToken, getApplication, applyUpgrade, getMyInfo, getQunNum } from '@/utils/api';
import { KFWX } from '@/utils/constants';
import { useUpdateEffect } from '@/utils/hooks';
import { getAuth } from '@/utils/app';
import { getApplicationCode, jumpTo } from '@/utils/helper';
import styles from './index.module.scss';
import SuccessBg from './assets/success.png';

function Index() {
  const [res, setRes] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const [qunNum, setQunNum] = useState('');
  const [files, setFiles] = useState([]);
  const [preview, setPreview] = useState('');
  const [example, setExample] = useState('');
  const [success, setSuccess] = useState('');
  const [data, setDate] = useState({});
  const [file, setFile] = useState(null);
  const [imgId, setImgId] = useState('');
  const [srcTest, setSrcTest] = useState([]);
  const { imgUrl, loading } = useUpload(file, data, `AF-H5/${imgId}`);
  const auth = getAuth();
  // const router = useReactRouter();

  const autoFlexBody = {
    fixedBody: () => {
      const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
      document.body.style.cssText += 'position:fixed;width:100%;top:-' + scrollTop + 'px;';
    },
    looseBody: () => {
      const body = document.body;
      body.style.position = '';
      const top = body.style.top;
      document.body.scrollTop = document.documentElement.scrollTop = -parseInt(top);
      body.style.top = '';
    },
  };
  // 复制方法
  const [isCopied, setCopied] = useClipboard(KFWX, {
    successDuration: 1000,
  });
  // 复制邀请码
  const dealWith = () => {
    setCopied();
    Toast.success('成功复制微信号');
  };

  const onImageClick = (index, fs) => {
    const { url } = fs[index];
    setPreview(url);
    autoFlexBody.fixedBody();
  };

  const openExample = () => {
    setExample(require('./assets/4.png'));
    autoFlexBody.fixedBody();
  };

  const getToken = () => {
    getUploadToken().then(res => setDate(res));
  };

  const dataURLtoFile = (dataurl, filename = 'file') => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const suffix = mime.split('/')[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], `${filename}.${suffix}`, {
      type: mime,
    });
  };

  const setUpload = f => {
    // console.log('触发')
    const url = dataURLtoFile(f[f.length - 1].url);
    setImgId(Date.now() + Math.round(Math.random() * 10000) + url.name);
    setFile(url);
  };

  const onChange = (f, i) => {
    // console.log(f, i, srcTest);
    setFiles(f);
    if (typeof i === 'number') {
      srcTest.splice(i, 1);
      return;
    }
    f.length > 0 && setUpload(f);
  };

  const upLoad = () => {
    // console.log(srcTest);
    if (srcTest.length === 0) {
      Toast.show('请至少上传一张截图');
      return;
    }
    if (res.isHide === 4) {
      Toast.show(getApplicationCode(res.isHide));
      return;
    }
    applyUpgrade({
      body: {
        phonenum: userInfo.phonenum,
        userName: userInfo.name,
        qunNum,
        upLevel: 21,
        images: srcTest,
      },
    })
      .then(res => {
        setSuccess(true);
        setRes({});
      })
      .catch(() => {
        Toast.show('已存在升级申请');
      });
  };

  const arr = imgUrl => {
    setSrcTest(historySrc => [...historySrc, imgUrl]);
  };

  useEffect(() => {
    if (auth) {
      getMyInfo().then(res => {
        // alert(JSON.stringify(res))
        setUserInfo(res);
      });
    } else {
      jumpTo('login');
    }
  }, []);

  useEffect(() => {
    getToken();
    getQunNum()
      .then(res => {
        setQunNum(res.qunNum);
        // console.log(res);
      })
      .catch(() => {});
  }, []);

  useUpdateEffect(() => {
    // console.log(loading);
    !loading && arr(imgUrl);
    // eslint-disable-next-line
  }, [loading]);

  useLayoutEffect(() => {
    getApplication().then(res => {
      // alert(JSON.stringify(res))
      res && setRes(res);
      if (res && res.status === 4) {
        setSuccess(true);
      }
    });
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        {/* tips */}
        {!!res && res.status === 6 && (
          <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', zIndex: 100 }}>
            <NoticeBar mode="closable">审核失败原因：{res.extra}</NoticeBar>
          </div>
        )}
        <img className={styles.headerImg} alt="" src={require('./assets/bg.png')} />
        <p className={styles.headerWxCode}>
          <span>客服微信号：{KFWX}</span>
          <span className={styles.copy} onClick={dealWith}>
            复制微信号
          </span>
        </p>
        <p className={styles.headerWxQunCode}>为您分配群号：{qunNum}</p>
        <div className={styles.headerCount}>
          <div className={styles.countItem}>
            <img alt="1" src={require('./assets/1.png')} />
            <p>
              新建或已有一个50人的微信，
              <span className={styles.qunCode}>群名称修改为： “亚洲电视{qunNum}群”</span>
            </p>
          </div>
          <div className={styles.countItem}>
            <img alt="2" src={require('./assets/2.png')} />
            <p>
              上传两张截图（截图一：显示出群主和群人数；截图二：显示出群名称）
              <span className={styles.detail} onClick={openExample}>
                查看示例
              </span>
            </p>
          </div>
          <div className={styles.countItem}>
            <img alt="3" src={require('./assets/3.png')} />
            <p>上传提交后，工作人员会在24小时内为您审核，审核结果留意当前页</p>
          </div>
        </div>
        <div className={styles.footer}>
          <div className={styles.footerUpload}>
            <h2>图片上传</h2>
            <ImagePicker
              length="3"
              files={files}
              onChange={(files, type, index) => onChange(files, index)}
              onImageClick={(index, fs) => onImageClick(index, fs)}
              selectable={files.length < 3}
              // accept="image/gif,image/jpeg,image/jpg,image/png"
            />
          </div>
          <button className={styles.uploadBtn} onClick={upLoad}>
            提交审核
          </button>
          {/* global toast 暂时没抽离 */}
          <div className={!preview ? styles.previewHide : styles.previewShow}>
            <img
              alt="预览图"
              src={preview}
              onClick={() => {
                setPreview('');
                autoFlexBody.looseBody();
              }}
            />
          </div>
          <div
            className={!example ? styles.exampleHide : styles.exampleShow}
            onClick={() => {
              setExample('');
              autoFlexBody.looseBody();
            }}
          >
            {!!example && <img src={example} alt="示例图" />}
          </div>
          <div className={!success ? styles.successContainerHide : styles.successContainerShow}>
            <img alt="审核中" src={SuccessBg} />
            <h2>审核中</h2>
            <p className={styles.text}>预计审核时间1-3个工作日</p>
            <p className={styles.text}>审核结果会以当前页面通知，请耐心等待</p>
          </div>
          {loading && (
            <div className={styles.loading}>
              <ActivityIndicator size="large" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Index;
