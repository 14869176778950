import React, { useEffect, useState } from 'react';
import useReactRouter from 'atv-use-react-router';
import useTitle from '@/hooks/useTitle';
import { getGongLueDetail } from '@/utils/api';
import 'braft-editor/dist/output.css';

function GongLueDetail() {
  const [gongLueDetail, setGongLueDetail] = useState({
    title: '',
    content: '',
  });
  const { title, content } = gongLueDetail;
  useTitle(title);
  const router = useReactRouter();
  let gongLueId;
  const { search } = router.location;
  const query = search.slice(1);
  const queryArr = query.split('&');
  // 解析出攻略的id
  for (const q of queryArr) {
    if (q) {
      const keyVal = q.split('=');
      const [key, val] = keyVal;
      if (key === 'id') {
        gongLueId = val;
      }
    }
  }
  const fetchGongLueDetail = () => {
    getGongLueDetail({
      params: {
        id: gongLueId,
      },
    })
      .then(res => setGongLueDetail(res))
      .catch(_ => {});
  };

  useEffect(() => {
    fetchGongLueDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div className="braft-output-content" dangerouslySetInnerHTML={{ __html: content }}></div>;
}

export default GongLueDetail;
