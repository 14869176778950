import React, { memo } from 'react';
import styles from './index.module.scss';

function Footer({ productDetail, handleSkuVisible, handleShareVisible }) {
  return (
    <div className={styles.footerContainer}>
      <div onClick={() => handleShareVisible(true)} className={styles.shareBtn}>
        <img
          className={styles.shareIcon}
          src={require('./assets/share.png')}
          alt=""
        />
        <span>分享赚</span>
      </div>
      <div onClick={() => handleSkuVisible(true)} className={styles.grabBtn}>
        ¥{productDetail.price && productDetail.price / 100}立即抢购
      </div>
    </div>
  );
}

export default memo(Footer);
