import React, { useState } from 'react';
import useReactRouter from 'atv-use-react-router';
import { TextareaItem, Toast } from 'antd-mobile';
import { isRN } from '@/utils/helper';
import styles from './index.module.scss';
import icon1 from './assets/icon_1.png';
import icon2 from './assets/icon_2.png';
import icon3 from './assets/icon_3.png';
import icon4 from './assets/icon_4.png';
import icon5 from './assets/icon_5.png';
import back1 from './assets/back_1.png';
import list1 from './assets/list_1.png';
import list2 from './assets/list_2.png';
import icon6 from './assets/icon_6.png';
import icon7 from './assets/icon_7.png';

function Prices () {
  const router = useReactRouter().history
  const [urls, setUrls] = useState('')

  const REG = /^(https?):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]$/

  const handleSubmit = () => {
    if (!urls) {
      Toast.info('请复制商品链接查询')
      return
    }
    if (!REG.test(urls)) {
      Toast.info('请输入正确的链接')
      return
    }
    router.push(`/parity/${encodeURIComponent(urls)}`);
  }

  return (
    <div className={styles.prices}>
      <div className={styles.exhibition}>
        <div className={styles.exBox}>
          <div><img src={icon1} /></div>
          <p>复制商品链接</p>
        </div>
        <img className={styles.icon} src={icon5} />
        <div className={styles.exBox}>
          <div><img src={icon2} /></div>
          <p>返回亚洲电视</p>
        </div>
        <img className={styles.icon} src={icon5} />
        <div className={styles.exBox}>
          <div><img src={icon3} /></div>
          <p>粘贴商品链接</p>
        </div>
        <img className={styles.icon} src={icon5} />
        <div className={styles.exBox}>
          <div><img src={icon4} /></div>
          <p>查询历史价格</p>
        </div>
      </div>
      <div
        style={{
          backgroundImage: `url(${back1})`
        }}
        className={styles.headBack}>
        <p className={styles.tips}>买前查价，避免上当</p>
      </div>
      <div className={styles.textBox}>
        <TextareaItem
          rows={6}
          value={urls}
          placeholder="复制商品链接查价，如：https://item.jd.com/100005207373.html"
          className={styles.conts}
          onChange={value => {
            const val = value.replace(/\s+/g, '')
            setUrls(val)
          }}
          // onFocus={() => {
          //   if (navigator.clipboard && navigator.clipboard.readText()) {
          //     navigator.clipboard.readText()
          //       .then(text => {
          //         const txt = text.replace(/\s+/g, '')
          //         if (/http:\/\/|https:\/\//.test(txt)) {
          //           setUrls(txt)
          //         }
          //       })
          //   }
          // }}
        />
        <div
          className={styles.btns}
          onClick={() => handleSubmit()}
        >
          查询历史价格
        </div>
      </div>
      <div className={styles.example}>
        <p className={styles.title}>历史价格查询使用说明</p>
        <div className={styles.tip}>
          <span>打开</span>
          <img src={icon6} />
          <img src={icon7} />
          <span>开始比价</span>
        </div>
        <p><span className={styles.sign}>1. </span>点击分享，复制商品链接</p>
        <img className={styles.list} src={list1} />
        <p>
          <span className={styles.sign}>2. </span>回到
          <span className={styles.signs}> 亚洲电视{isRN() ? 'APP' : '小程序'}</span>，点击比价进入查询历史低价
        </p>
        <img className={styles.list} src={list2} />
      </div>
    </div>
  )
}

export default Prices;
