import { useLayoutEffect } from 'react';
import { isRN } from '@/utils/helper';

export default function useTitle(title) {
  useLayoutEffect(() => {
    document.title = title;
    if (isRN()) {
      window.RN.setTitle({
        data: title,
      });
    }
  }, [title]);
}
